import { callApi } from './axiosClient';

const newsLetterApi = {
    post: (data) => {
        const url = '/api/newsletter-subcription';
        return callApi(url, 'POST', data);
    },
};

export default newsLetterApi;
