import React, { Suspense, useRef, memo } from 'react';
import { Canvas } from '@react-three/fiber';
import Controls from '../Control/Controls';
import Model from './Model/Model';
import { useParams } from 'react-router';
import { Html } from '@react-three/drei';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ResizeObserver } from '@juggle/resize-observer';

export const Loader = () => {
    return (
        <Html center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 100, color: '#BDAA66' }} spin />} />
        </Html>
    );
};

const ModelPreview = ({ zoom, text, color_select, pos_camera, pos_fbx,  rotation_fbx}) => {
    const { pickImg, model3d, color, leatherRough, isPattern, colorText, listPattern, position } = useSelector(
        (state) => state.model3d
    );

    const { idBag } = useParams();

    return (
        <>
            <div style={{ height: '100%', width: '100%' }} className="canvas-modal-3d">
                <Canvas
                    gl={{ preserveDrawingBuffer: true }}
                    style={{ height: '100%', width: '100%' }}
                    colorManagement
                    shadowMap
                    shadows
                    resize={{ polyfill: ResizeObserver }}
                >
                    <ambientLight intensity={0.3} position={[-30.16, 8.587, 0.279]} frustumCulled />
                    <directionalLight intensity={0.11} position={[5.0, -10.99, -51.865]} />
                    {/* <directionalLight intensity={0.3} position={[-0.013, 8.171, 21.929]} /> */}
                    <directionalLight intensity={0.3} position={[-29.909, 33.749, 28.795]} />
                    <directionalLight intensity={0.3} position={[36.123, 23.883, 7.723]} />
                    <directionalLight intensity={0.3} position={[-0.013, -14.749, 21.929]} />
                    <pointLight position={[0, 17.465, 45]} intensity={0.2} />
                    <pointLight position={[0, 17.465, -45.010]} intensity={0.4} castShadow />
                    <pointLight position={[8.786, 22.85, 25.024]} intensity={0.2} castShadow />
                    <pointLight position={[-25.134, 40.879, -64.419]} intensity={0.2} />
                    <Controls pos_camera={{x:0, y:0, z: 45}} />
                    <Suspense fallback={<Loader />}>
                        {model3d ? (
                            <Model
                                model={pickImg}
                                model3d={model3d}
                                zoom={zoom}
                                text={text}
                                leatherRough={leatherRough}
                                isPattern={isPattern}
                                idBag={idBag}
                                colorText={color_select}
                                position={position}
                                pos_fbx={pos_fbx}
                                rotation_fbx={rotation_fbx}
                            />
                        ) : null}
                    </Suspense>
                </Canvas>
            </div>
        </>
    );
};

export default memo(ModelPreview);
