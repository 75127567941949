import { handleActions } from 'redux-actions';
import checkoutStepAction from '../actions/checkout-step.action';

const initialState = {
    step: 0,
};

const checkoutStepReducer = handleActions(
    {
        [checkoutStepAction.loginStep]: (state) => ({ ...state, step: 0 }),
        [checkoutStepAction.addressStep]: (state) => ({ ...state, step: 1 }),
        [checkoutStepAction.paymentStep]: (state) => ({ ...state, step: 2 }),
    },
    initialState
);

export default checkoutStepReducer;
