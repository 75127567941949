import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button } from 'antd';
import * as Yup from 'yup';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import './CheckoutPayment.scss';
import { currencyFormatter } from '../../helpers';

import visa from '../../assets/img/icon/Visa.svg';
import master from '../../assets/img/icon/Master.svg';
import paypal from '../../assets/img/icon/Paypal.svg';

const CARD_OPTIONS = {};

const DELIVERY_OPTIONS = [
    {
        name: 'Free Shipping',
        description: '5-8 business days',
        value: '1',
        price: 0,
    },
    {
        name: 'Standard Shipping',
        description: '4-5 business days',
        value: '2',
        price: 3,
    },
];

const CheckoutPaymentSchema = Yup.object().shape({
    delivery: Yup.string().required('Delivery is required'),
});

const CheckoutPayment = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const cartItems = useSelector((state) => state.cart.items);
    const cartSubtotal = useSelector((state) => state.cart.subtotal);

    const [errorCardNumberElement, setErrorCardNumberElement] = useState(null);
    const [errorCardExpiryElement, setErrorCardExpiryElement] = useState(null);
    const [errorCardCvcElement, setErrorCardCvcElement] = useState(null);
    const [loadingCheckout, setLoadingCheckout] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (errorCardNumberElement || errorCardExpiryElement || errorCardCvcElement) {
            elements.getElement('card').focus();
            return;
        }
        setLoadingCheckout(true);
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement, CardCvcElement, CardExpiryElement),
            billing_details: {
                phone: get(props, 'orderData.phone'),
                name: get(props, 'orderData.name'),
            },
        });

        if (payload.error) {
            setErrorCardNumberElement(payload.error);
            setLoadingCheckout(false);
            return;
        }
        setSubmitting(true);
        setLoadingCheckout(false);
        props.handleSubmitOrder({ ...values, ...payload });
    };

    return (
        <div className="checkout-payment">
            <div className="container">
                <Formik
                    initialValues={{
                        delivery: DELIVERY_OPTIONS[0].value,
                    }}
                    validationSchema={CheckoutPaymentSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values }) => (
                        <Form>
                            <div className="row">
                                <div className="checkout-payment_left col-12 col-lg-7 pl-0 pr-30px">
                                    <div className="checkout-payment_left-wrapper mb-5 mb-lg-0 pt-26px pb-26px pl-50px pr-50px">
                                        <div className="checkout-payment_delivery mb-60px">
                                            <div className="font-title size-25 color-nero font-title-bold mb-28px">
                                                Delivery Method
                                            </div>
                                            <div role="group" aria-labelledby="my-radio-group">
                                                {DELIVERY_OPTIONS.map((v, k) => (
                                                    <div className="checkout-payment_group mb-20px d-flex" key={k}>
                                                        <Field
                                                            className="checkout-payment_radio cursor-pointer"
                                                            type="radio"
                                                            id={`delivery${v.value}`}
                                                            name="delivery"
                                                            value={v.value}
                                                        />
                                                        <label
                                                            className="line-normal cursor-pointer"
                                                            htmlFor={`delivery${v.value}`}
                                                        >
                                                            <div className="size-18 color-nero mb-3px">{v.name}</div>
                                                            <div className="size-14 color-dark-gray-1">
                                                                {v.description}
                                                            </div>
                                                        </label>
                                                        {v.price > 0 && (
                                                            <div className="line-normal ml-auto size-18 color-black">
                                                                {currencyFormatter(v.price)}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <ErrorMessage
                                                className="color-contessa text-left mt-2"
                                                name="delivery"
                                                component="div"
                                            />
                                        </div>
                                        <div className="checkout-payment_payment-method mb-40px">
                                            <div className="font-title size-25 color-nero font-title-bold mb-28px">
                                                Payment Method
                                            </div>
                                            <div className="checkout-payment_group d-flex flex-wrap">
                                                <div className="mr-12px">
                                                    <Field
                                                        className="checkout-payment_radio d-none"
                                                        id="payment-method1"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        value="visa"
                                                    />
                                                    <label
                                                        className="card d-flex justify-content-center align-items-center cursor-pointer"
                                                        htmlFor="payment-method1"
                                                    >
                                                        <img src={visa} alt="visa" />
                                                    </label>
                                                </div>
                                                <div className="mr-12px">
                                                    <Field
                                                        className="checkout-payment_radio d-none"
                                                        id="payment-method2"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        value="master"
                                                    />
                                                    <label
                                                        className="card d-flex justify-content-center align-items-center cursor-pointer"
                                                        htmlFor="payment-method2"
                                                    >
                                                        <img src={master} alt="visa" />
                                                    </label>
                                                </div>
                                                <div>
                                                    <Field
                                                        className="checkout-payment_radio d-none"
                                                        id="payment-method3"
                                                        type="radio"
                                                        name="paymentMethod"
                                                        value="paypal"
                                                    />
                                                    <label
                                                        className="card d-flex justify-content-center align-items-center cursor-pointer"
                                                        htmlFor="payment-method3"
                                                    >
                                                        <img src={paypal} alt="visa" />
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorMessage
                                                className="color-contessa text-left mt-2"
                                                name="paymentMethod"
                                                component="div"
                                            />
                                        </div>
                                        <div className="checkout-payment_card mb-30px">
                                            <div className="size-16 color-nero mb-12px">CARD NUMBER</div>
                                            <CardNumberElement
                                                placeholder="Card number"
                                                className="checkout-payment_card-input size-16 color-nero pb-18px w-100"
                                                options={CARD_OPTIONS}
                                                onChange={(e) => {
                                                    setErrorCardNumberElement(e.error);
                                                }}
                                            />
                                            {errorCardNumberElement && (
                                                <div className="color-contessa text-left mt-2">
                                                    {errorCardNumberElement.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="checkout-payment_ccv mb-50px">
                                            <div className="row w-100 m-0">
                                                <div className="col-12 col-sm-6 pl-0">
                                                    <div className="size-16 color-nero mb-12px">EXPIRATION DATE</div>
                                                    <CardExpiryElement
                                                        options={CARD_OPTIONS}
                                                        className="checkout-payment_ccv-input size-16 color-nero pb-18px w-100"
                                                        onChange={(e) => {
                                                            setErrorCardExpiryElement(e.error);
                                                        }}
                                                    />
                                                    {errorCardExpiryElement && (
                                                        <div className="color-contessa text-left mt-2">
                                                            {errorCardExpiryElement.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkout-payment_ccv mb-50px">
                                            <div className="row w-100 m-0">
                                                <div className="col-12 col-sm-6 pl-0">
                                                    <div className="size-16 color-nero mb-12px">CVV</div>
                                                    <CardCvcElement
                                                        options={CARD_OPTIONS}
                                                        className="checkout-payment_ccv-input size-16 color-nero pb-18px w-100"
                                                        onChange={(e) => {
                                                            setErrorCardCvcElement(e.error);
                                                        }}
                                                    />
                                                    {errorCardCvcElement && (
                                                        <div className="color-contessa text-left mt-2">
                                                            {errorCardCvcElement.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-payment_right col-12 col-lg-5 px-0">
                                    <div className="checkout-payment_product pt-26px pb-60px pl-30px pr-30px mb-20px">
                                        {Object.keys(cartItems).map((key) => (
                                            <div className="d-flex mb-50px pb-3 border-bottom" key={key}>
                                                <div className="checkout-payment_product-img mr-12px d-flex justify-content-center align-items-center">
                                                    <img
                                                        src={get(cartItems[key], 'associatedModel.product_image[0]')}
                                                        alt="product"
                                                        className="w-100"
                                                    />
                                                </div>
                                                <div>
                                                    <div className="font-title font-title-bold size-16 color-nero">
                                                        {get(cartItems[key], 'name')}
                                                    </div>
                                                    <div className="color-black size-16 font-weight-normal">
                                                        {currencyFormatter(get(cartItems[key], 'price'))}
                                                    </div>
                                                    <div className="color-gimblet size-14">{`(${get(
                                                        cartItems[key],
                                                        'quantity'
                                                    )} x ${currencyFormatter(get(cartItems[key], 'price'))})`}</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="d-flex justify-content-between align-items-center mb-16px">
                                            <div className="color-nero size-16">SUBTOTAL</div>
                                            <div className="color-nero size-18 font-weight-normal">
                                                {currencyFormatter(cartSubtotal)}
                                            </div>
                                        </div>
                                        <div className="checkout-payment_shipping d-flex justify-content-between align-items-center pb-18px mb-24px">
                                            <div className="color-nero size-16">SHIPPING FEE</div>
                                            <div className="color-nero size-16 font-weight-normal">
                                                {currencyFormatter(values.delivery == 2 ? 3 : 0)}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-16px">
                                            <div className="color-nero size-16">TOTAL</div>
                                            <div className="color-nero size-24 font-weight-normal">
                                                {currencyFormatter(cartSubtotal + (values.delivery == 2 ? 3 : 0))}
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        className="checkout-payment_btn text-center w-100 size-14 text color-white text-uppercase"
                                        // disabled={isSubmitting && !stripe}
                                        loading={loadingCheckout}
                                        htmlType="submit"
                                    >
                                        {'CONFIRM & CHECK OUT'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CheckoutPayment;
