const MODAL_CONST = {
    LOG_IN: 'LOG_IN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_2: 'FORGOT_PASSWORD_2',
    SIGN_UP: 'SIGN_UP',
    SAVE_EMAIL: 'SAVE_EMAIL',
    CLOSE: 'CLOSE',
};

export default MODAL_CONST;
