import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { Badge, Drawer, Menu as AntMenu, Dropdown, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import _ from 'lodash';

// import { ModalButton, Search } from '../../components/index';
import { ModalButton } from '../../components/index';

import { getQuantityCartItems } from '../../helpers';

import scrollAction from '../../actions/scroll.action';
import modalAction from '../../actions/modal.action';
import addToCartAction from '../../actions/add-to-cart.action';

// import globeIcon from '../../assets/img/icon/Globe.svg';
import cartIcon from '../../assets/img/icon/Cart.svg';
import logo from '../../assets/img/LogoTOIAtelier.png';
import profileIcon from '../../assets/img/icon/Profile.svg';
import './Header.scss';

import authApi from '../../apis/authApi';
import authAction from '../../actions/auth.action';

const Menu = ({ onClose }) => {
    const firstName = useSelector((state) => state.auth.user.first_name);
    const dispatch = useDispatch();

    const handleLogout = async () => {
        await authApi.logout();
        dispatch(addToCartAction.resetCart());
        dispatch(authAction.logout());
    };
    return (
        <ul className={`nav size-15  ${window.innerWidth > 1200 && 'd-flex align-items-center'}`}>
            {window.innerWidth < 1200 && firstName && (
                <>
                    <p>
                        <img src={profileIcon} style={{ marginRight: '5px' }} />
                        {firstName}
                    </p>
                </>
            )}
            <li>
                <NavLink to="/bags" onClick={() => onClose && onClose()} activeClassName="link-active">
                    Shop
                    <div />
                </NavLink>
            </li>

            <li>
                <NavLink to="/design-your-own" onClick={() => onClose && onClose()} activeClassName="link-active">
                    Design your own
                    <div />
                </NavLink>
            </li>

            <li>
                <NavLink to="/collections" onClick={() => onClose && onClose()} activeClassName="link-active">
                    Our collections
                    <div />
                </NavLink>
            </li>

            <li>
                <NavLink to="/our-story" onClick={() => onClose && onClose()} activeClassName="link-active">
                    Our story
                    <div />
                </NavLink>
            </li>

            <li>
                <NavLink to="/news" onClick={() => onClose && onClose()} activeClassName="link-active">
                    News
                    <div />
                </NavLink>
            </li>

            <li>
                <NavLink to="/contact-us" onClick={() => onClose && onClose()} activeClassName="link-active">
                    Contact us
                    <div />
                </NavLink>
            </li>

            <li>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                    {window.innerWidth < 1200 && firstName && <Button onClick={handleLogout}>Logout</Button>}
                </div>
            </li>
        </ul>
    );
};

const Header = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const isTop = useSelector((state) => state.scroll.isTop);
    const listCart = useSelector((state) => state.cart.listProduct);
    const quantityTotalInCart = useSelector((state) => getQuantityCartItems(state.cart.items));

    const isAuth = useSelector((state) => !!state.auth.token);
    const firstName = useSelector((state) => state.auth.user.first_name);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [isScroll, setIsScroll] = useState(true);
    const [visibleFooter, setVisibleFooter] = useState(false);

    useEffect(() => {
        if (_.includes(pathname, 'customize')) {
            setVisibleFooter(false);
        } else {
            setVisibleFooter(true);
        }
    }, [pathname]);

    if (window.scrollY >= 10) {
        dispatch(scrollAction.setIsTop(false));
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (localStorage.getItem('cart')) {
            dispatch(addToCartAction.init(JSON.parse(localStorage.getItem('cart'))));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(listCart));
    }, [listCart]);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    let timer = null;
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (timer !== null) {
            clearTimeout(timer);
        }
        setIsScroll(true);
        timer = setTimeout(function () {
            setIsScroll(false);
        }, 400);
        if (position >= 10) {
            dispatch(scrollAction.setIsTop(false));
        } else {
            dispatch(scrollAction.setIsTop(true));
        }
    };

    const handleLogout = async () => {
        await authApi.logout();
        dispatch(addToCartAction.resetCart());
        dispatch(authAction.logout());
    };

    const userMenu = (
        <AntMenu className="profile-menu">
            <AntMenu.Item key="1">
                <NavLink to="/profile" activeClassName="link-active">
                    Profile
                </NavLink>
            </AntMenu.Item>
            <AntMenu.Item key="2">
                <NavLink to="/my-orders" activeClassName="link-active">
                    My Orders
                </NavLink>
            </AntMenu.Item>
            <AntMenu.Item key="3">
                <NavLink to="/my-favorites" activeClassName="link-active">
                    My Favorites
                </NavLink>
            </AntMenu.Item>
            <AntMenu.Item key="4">
                <NavLink to="/draft-design" activeClassName="link-active">
                    Draft Design
                </NavLink>
            </AntMenu.Item>
            <AntMenu.Item key="5">
                <Button onClick={handleLogout}>Logout</Button>
            </AntMenu.Item>
        </AntMenu>
    );

    return visibleFooter ? (
        <div
            className={`header-session position-fixed w-100 ${!isTop ? 'not-at-top' : ''} ${
                !isTop && isScroll ? 'is-scroll' : ''
            }`}
        >
            <div className="header-wrapper container-fluid d-flex align-items-center justify-content-between h-100">
                <div className="d-flex align-items-center left-nav">
                    {window.innerWidth < 768 && (
                        <MenuOutlined className="size-24 mobile-menu d-block d-md-none mr-md-5" onClick={showDrawer} />
                    )}
                    {window.innerWidth >= 768 && (
                        <>
                            <div className="d-none d-md-flex">
                                {/* <div className="d-flex align-items-center left-nav-item">
                            <img src={globeIcon} />
                            <div className="ml-1 size-12">English</div>
                        </div> */}
                                {isAuth ? (
                                    <Dropdown overlay={userMenu}>
                                        <div
                                            className={`modal-btn d-flex align-items-center ml-3 ${
                                                !isTop && 'not-at-top'
                                            }`}
                                        >
                                            <img src={profileIcon} />
                                            <div className="ml-1 size-14 text-capitalize">{firstName}</div>
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <ModalButton
                                        className={`d-flex align-items-center ml-3 ${!isTop && 'not-at-top'}`}
                                        initialModalAction={modalAction.logIn()}
                                    />
                                )}
                                <div className="d-flex align-items-center left-nav-item ml-3">
                                    <NavLink to="/cart">
                                        <Badge count={quantityTotalInCart} overflowCount={9}>
                                            <img src={cartIcon} />
                                        </Badge>
                                    </NavLink>
                                </div>
                                {/* <Search className={`d-flex align-items-center left-nav-item ml-3 ${!isTop && 'not-at-top'}`} /> */}
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <p
                        className="m-0"
                        onClick={() => {
                            if (pathname === '/') {
                                window.scroll({ top: 0, left: 0 });
                            } else {
                                history.push('/');
                            }
                        }}
                    >
                        <img src={logo} className="logo cursor-pointer" />
                    </p>
                </div>
                <div className="right-nav d-flex justify-content-end">
                    <div className="desktop-menu">
                        <Menu />
                    </div>
                    {window.innerWidth >= 768 && window.innerWidth < 1200 && (
                        <MenuOutlined
                            className="size-31 mobile-menu d-none d-md-block d-xl-none mr-md-5"
                            onClick={showDrawer}
                        />
                    )}
                    {window.innerWidth < 768 && (
                        <div className="d-flex">
                            {/* <div className="d-flex align-items-center left-nav-item">
                            <img src={globeIcon} />
                            <div className="ml-1 size-12">English</div>
                        </div> */}
                            <div className="d-md-none">
                                {isAuth ? (
                                    <Dropdown overlay={userMenu}>
                                        <div
                                            className={`modal-btn d-flex align-items-center ${!isTop && 'not-at-top'}`}
                                        >
                                            <img src={profileIcon} />
                                            {/* <div className="ml-1 size-14 text-capitalize modal-btn__name-user">
                                                {firstName}
                                            </div> */}
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <ModalButton
                                        className={`d-flex align-items-center ml-3 ${!isTop && 'not-at-top'}`}
                                        initialModalAction={modalAction.logIn()}
                                    />
                                )}
                            </div>
                            <div className="d-flex d-md-none align-items-center left-nav-item pl-1">
                                <NavLink to="/cart">
                                    <Badge count={quantityTotalInCart} overflowCount={9}>
                                        <img src={cartIcon} />
                                    </Badge>
                                </NavLink>
                            </div>
                            {/* <Search className={`d-flex align-items-center left-nav-item ml-3 ${!isTop && 'not-at-top'}`} /> */}
                        </div>
                    )}
                </div>
            </div>
            <Drawer
                title="Menu"
                placement="left"
                closable={true}
                visible={visible}
                onClose={onClose}
                className="side-bar"
            >
                <Menu onClose={onClose} />
            </Drawer>
        </div>
    ) : (
        ''
    );
};
export default Header;
