import { handleActions } from 'redux-actions';
import favoriteAction from '../actions/favorite.action';

const initialState = {
    items: [],
    loadingFavorite: false,
    total: 0,
    page: 1
};

const favoriteReducer = handleActions(
    {
        [favoriteAction.list]: (state, { payload }) => {
            return { ...state, ...payload };
        },
        [favoriteAction.resetFavorite]: (state) => {
            return { ...state, ...initialState };
        },
        [favoriteAction.updateFavorite]: (state, { payload }) => {
            const checkIdProduct = (obj) => Number(obj.product_id) === Number(payload.product_id);
            if (state.items.some(checkIdProduct)) {
                return {
                    ...state,
                    items: state.items.filter((item) => Number(item.product_id) !== Number(payload.product_id)),
                };
            }
            return { ...state, items: [...state.items, payload] };
        },
    },
    initialState
);

export default favoriteReducer;
