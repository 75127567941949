import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, notification } from 'antd';

import authAction from '../../actions/auth.action';
import authApi from '../../apis/authApi';

// import avatar from '../../assets/img/icon/Avatar.svg';
// import updateAvatar from '../../assets/img/icon/Update-Avatar.png';
import './UpdateProfile.scss';

import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const UpdateProfileSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Email is not valid'),
});
const schemaPassword = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Email is not valid'),
    currentPassword: Yup.string()
        .required('Current password is required')
        .min(6, 'Password must have at least 6 character'),
    newPassword: Yup.string().required('New password is required').min(6, 'Password must have at least 6 character'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
const schemaPasswordFirst = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().email('Email is not valid'),
    newPassword: Yup.string().required('New password is required').min(6, 'Password must have at least 6 character'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const nonShowPasswordInitialValues = {
    name: '',
    phone: '',
    email: '',
};
const showPasswordInitialValues = {
    name: '',
    phone: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const { user, isFirstLogin } = useSelector((state) => state.auth);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShowChangePassword = () => {
        setShowChangePassword(true);
    };
    const onFinishForm = (values) => {
        setLoading(true);
        const dataPost = showChangePassword
            ? {
                  phone: values.phone,
                  first_name: values.name,
                  current_password: values.currentPassword,
                  new_password: values.newPassword,
                  new_password_confirmation: values.confirmNewPassword,
              }
            : { phone: values.phone, first_name: values.name };
        authApi
            .updateInfo(dataPost)
            .then((res) => {
                if (!res.data.errorMessage) {
                    notification.open({
                        placement: 'topRight',
                        description: 'Update profile successfully!',
                        className: 'alert-add-to-cart-success text-white size-14',
                        duration: 3,
                        closeIcon: <img src={toastCloseIcon} alt="" />,
                    });
                    dispatch(
                        authAction.getProfile({ ...user, first_name: dataPost.first_name, phone: dataPost.phone })
                    );
                } else {
                    notification.open({
                        placement: 'topRight',
                        description: 'Sorry something went wrong.',
                        className: 'alert-add-to-cart-danger text-white size-14',
                    });
                }
                setLoading(false);
            })
            .catch(() => {
                notification.open({
                    placement: 'topRight',
                    description: 'Sorry something went wrong',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
                setLoading(false);
            });
    };

    return (
        <div className="updateprofile">
            <div className="container">
                <div className="updateprofile-container d-flex flex-column align-items-center">
                    <p className="updateprofile-title text-uppercase color-dim-gray size-16">profile</p>
                    <p className="updateprofile-subtitle text-center font-title-bold color-nero size-48">
                        Your profile information
                    </p>
                    <div className="up-main d-flex flex-column flex-md-row align-items-center align-items-md-start">
                        {/* <div className="up-img-container position-relative mr-0 mr-md-5">
                            <img src={avatar} alt="avatar" className="up-img w-100" />
                            <img src={updateAvatar} alt="update" className="up-update-btn position-absolute" />
                        </div> */}
                        <div className="up-form-container">
                            <Formik
                                enableReinitialize
                                initialValues={
                                    !showChangePassword
                                        ? {
                                              ...nonShowPasswordInitialValues,
                                              name: user.first_name,
                                              email: user.email,
                                              phone: user.phone ? user.phone : '',
                                          }
                                        : {
                                              ...showPasswordInitialValues,
                                              name: user.first_name,
                                              email: user.email,
                                              phone: user.phone ? user.phone : '',
                                          }
                                }
                                validationSchema={
                                    showChangePassword
                                        ? isFirstLogin
                                            ? schemaPasswordFirst
                                            : schemaPassword
                                        : UpdateProfileSchema
                                }
                                onSubmit={onFinishForm}
                            >
                                {() => (
                                    <Form className="up-form d-flex flex-column align-items-start">
                                        <div className="up-field-container w-100">
                                            <label
                                                className="up-label w-100 text-uppercase size-16 color-nero"
                                                htmlFor="name"
                                            >
                                                name
                                            </label>
                                            <Field
                                                className="up-input w-100 size-16 color-nero"
                                                type="text"
                                                name="name"
                                                placeholder="name"
                                                id="name"
                                            />
                                            <ErrorMessage className="color-contessa mt-2" name="name" component="div" />
                                        </div>
                                        <div className="up-field-container w-100">
                                            <label
                                                className="up-label w-100 text-uppercase size-16 color-nero"
                                                htmlFor="phone"
                                            >
                                                phone
                                            </label>
                                            <Field
                                                className="up-input w-100 size-16 color-nero"
                                                type="text"
                                                name="phone"
                                                placeholder="phone number"
                                                id="phone"
                                            />
                                            <ErrorMessage
                                                className="color-contessa mt-2"
                                                name="phone"
                                                component="div"
                                            />
                                        </div>
                                        <div className="up-field-container w-100">
                                            <label
                                                className="up-label w-100 text-uppercase size-16 color-nero"
                                                htmlFor="email"
                                            >
                                                email
                                            </label>
                                            <Field
                                                className="up-input w-100 size-16 color-nero"
                                                type="email"
                                                name="email"
                                                placeholder="email"
                                                disabled
                                                readOnly
                                                id="email"
                                            />
                                            <ErrorMessage
                                                className="color-contessa mt-2"
                                                name="email"
                                                component="div"
                                            />
                                        </div>
                                        {!showChangePassword ? (
                                            <div className="up-field-container w-100 position-relative mb-0">
                                                <label
                                                    className="up-label w-100 text-uppercase size-16 color-nero"
                                                    htmlFor="password"
                                                >
                                                    password
                                                </label>
                                                <input
                                                    className="up-input w-100 size-16 color-nero"
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value="********"
                                                    readOnly={true}
                                                />
                                                <ErrorMessage
                                                    className="color-contessa mt-2"
                                                    name="password"
                                                    component="div"
                                                />
                                                <div
                                                    className="up-change-pw-btn text-capitalize size-14 position-absolute"
                                                    onClick={handleShowChangePassword}
                                                >
                                                    change password
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="up-field-container w-100 d-flex flex-column mb-0">
                                                <label className="up-label w-100 text-uppercase size-16 color-nero">
                                                    change password
                                                </label>
                                                {!isFirstLogin && (
                                                    <>
                                                        <Field
                                                            className="up-input w-100 size-16 color-nero"
                                                            type="password"
                                                            name="currentPassword"
                                                            placeholder="current password"
                                                        />
                                                        <ErrorMessage
                                                            className="color-contessa mt-2"
                                                            name="currentPassword"
                                                            component="div"
                                                        />
                                                    </>
                                                )}
                                                <Field
                                                    className="up-input w-100 size-16 color-nero"
                                                    type="password"
                                                    name="newPassword"
                                                    placeholder="new password"
                                                />
                                                <ErrorMessage
                                                    className="color-contessa mt-2"
                                                    name="newPassword"
                                                    component="div"
                                                />
                                                <Field
                                                    className="up-input w-100 size-16 color-nero"
                                                    type="password"
                                                    name="confirmNewPassword"
                                                    placeholder="confirm new password"
                                                />
                                                <ErrorMessage
                                                    className="color-contessa mt-2"
                                                    name="confirmNewPassword"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="up-btn-container w-100 text-center color-nero">
                                            <Button
                                                loading={loading}
                                                htmlType="submit"
                                                disabled={loading}
                                                className="up-btn text-white text-uppercase size-14"
                                            >
                                                update
                                            </Button>
                                            {showChangePassword && (
                                                <Button
                                                    htmlType="button"
                                                    className="bg-secondary up-btn ml-2 text-white text-uppercase size-14"
                                                    onClick={() => setShowChangePassword(false)}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProfile;
