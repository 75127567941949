import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import model3dAction from '../../../actions/model-3d.action';

const TextureListLeather = ({ textures, setSelectProductIdPattern }) => {
    // const [selectedId, setSelectedId] = useState(null);
    const dispatch = useDispatch();
    const { selectedType } = useSelector((state) => state.model3d);

    // useEffect(() => {
    //     setSelectedId(null);
    // }, [textures]);

    useEffect(() => {
        dispatch(
            model3dAction.selectType({
                leatherType: { id: textures?.[0]?.customize_type, name: textures?.[0]?.customize_parent_name },
                patternType: {
                    id: textures?.[0]?.product_id,
                    name: textures?.[0]?.customize_name,
                    customize_id: textures?.[0]?.customize_id,
                },
            })
        );
        dispatch(model3dAction.pickImg(textures?.[0]?.image));
        dispatch(model3dAction.setColorBorder(textures?.[0]?.product_border_color));
    }, [dispatch, textures]);

    const selectTexture = React.useCallback(
        ({
            img,
            nameLeather,
            namePattern,
            idLeather,
            idPattern,
            customize_smooth,
            textColor,
            listPatternVersion,
            product_border_color,
            customize_id,
        }) => {
            // setSelectedId(index);
            dispatch(model3dAction.setIsSmooth(customize_smooth === 1));
            dispatch(model3dAction.pickImg(img));
            dispatch(model3dAction.setIsPattern(true));
            dispatch(model3dAction.setListPatternVersion(listPatternVersion));
            // dispatch(
            //     model3dAction.pickLeatherType({
            //         namePattern: namePattern,
            //         type: img,
            //         id: idPattern,
            //         name: nameLeather,
            //     })
            // );
            dispatch(
                model3dAction.selectType({
                    leatherType: { id: idLeather, name: nameLeather },
                    patternType: { id: idPattern, name: namePattern, customize_id: customize_id },
                })
            );
            dispatch(model3dAction.setColorBorder(product_border_color));
        },
        []
    );

    return (
        <div className="d-flex flex-wrap texture-list">
            {_.map(textures, (texture, index) => {
                if (texture.is_default_customize) return '';
                return (
                    <div
                        key={index}
                        className="texture-item-wrapper cursor-pointer"
                        onClick={() => {
                            selectTexture({
                                img: texture.image,
                                namePattern: texture.customize_name,
                                nameLeather: texture.customize_parent_name,
                                idLeather: texture.customize_type,
                                idPattern: texture.product_id,
                                customize_smooth: texture.customize_parent_smooth,
                                textColor: texture.text_color,
                                listPatternVersion: texture?.children,
                                product_border_color: texture?.product_border_color,
                                customize_id: texture?.customize_id,
                            });
                            setSelectProductIdPattern(texture.product_id);
                        }}
                    >
                        <div
                            className={`texture-item ${
                                (texture.customize_id === selectedType.patternType.id ||
                                    texture.product_id === selectedType.patternType.id) &&
                                'active'
                            }`}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        texture.display_image ? texture.display_image : texture.image
                                    })`,
                                }}
                            />
                            <div className="desc text-center">{texture.customize_name}</div>
                        </div>
                        {(texture.customize_id === selectedType.patternType.id ||
                            texture.product_id === selectedType.patternType.id) && <p>{texture.customize_name}</p>}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(TextureListLeather);
