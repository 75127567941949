import React, { useEffect, useState, Suspense, useRef } from 'react';
// import AOS from 'aos';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, notification, Modal } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FacebookProvider, ShareButton, Profile } from 'react-facebook';
import { ShareSocial } from 'react-share-social';
import { useDispatch, useSelector } from 'react-redux';

import modalAction from '../../../actions/modal.action';
import ModelPreview from './ModelPreview/ModelPreview';
import model3dAction from '../../../actions/model-3d.action';
import { currencyFormatter } from '../../../helpers';
import './DesignCustomize.scss';
// import IconArrow from '../../../assets/img/icon-arrow.png';
import logo from '../../../assets/img/LogoTOIAtelier.png';
import share from '../../../assets/img/icon/share.svg';
import ShowModal from '../../ModalButton/ShowModal';
import cartApi from '../../../apis/cartApi';
import addToCartAction from '../../../actions/add-to-cart.action';
import toastCloseIcon from '../../../assets/img/icon/toast-close.svg';
import productApi from '../../../apis/productApi';
import BannerStep from '../../BannerStep';
import closeIcon from '../../../assets/img/icon/Close.svg';
import TextureListLeather from './TextureListLeather';
import TextureListPattern from './TextureListPattern';
import TextureListPatternVersion from './TextureListPatternVersion';

import { Html } from '@react-three/drei';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// eslint-disable-next-line no-undef
const appId = process.env.REACT_APP_CLIENT_ID_FB;

export const Loader = () => {
    return (
        <Html center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 100, color: '#BDAA66' }} spin />} />
        </Html>
    );
};

const style = {
    background: 'transparent',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    width: '15px',
};

const DesignCustomize = () => {
    const { idBag } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [zoom, setZoom] = useState(0);
    const [text, setText] = useState('');
    const [infoProduct, setInfoProduct] = useState({ name: '', price: '', oldPrice: '' });
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [imgCartPreview, setImgCartPreview] = useState();
    const [selectProductIdPattern, setSelectProductIdPattern] = useState(0);
    const [visibleModalStep, setVisibleModalStep] = useState(true);
    const [dataCustomize, setDataCustomize] = useState([]);
    const [color_select_id, set_color_select_id] = useState('1');
    const [color_select, set_color_select] = useState('#3A3A3A');
    const [pos_camera, set_pos_camera] = useState({});
    const [pos_fbx, set_pos_fbx] = useState({});
    const [rotation_fbx, set_rotation_fbx] = useState({});

    const isAuth = useSelector((state) => !!state.auth.token);
    const { leatherType, patternType, patternTypeVerion } = useSelector((state) => state.model3d.selectedType);
    const { patternTypeVersion } = useSelector((state) => state.model3d.selectedTypeVersion);
    const { listPattern, listPatternVersion } = useSelector((state) => state.model3d);

    const link = `http://toi.tekup.vn${window.location.pathname}${window.location.search}`;
    const link2 = `https://www.facebook.com/sharer.php?u=${link}?imageurl=${`https://i.guim.co.uk/img/media/fe1e34da640c5c56ed16f76ce6f994fa9343d09d/0_174_3408_2046/master/3408.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=0d3f33fb6aa6e0154b7713a00454c83d`}`;

    const define_color = [
        { value: '#3A3A3A', id: '1' },
        { value: '#D02525', id: '2' },
        { value: '#EFBC06', id: '3' },
        { value: '#49D04E', id: '4' },
        { value: '#4033D5', id: '5' },
    ];

    const handleAddToCart = async () => {
        if (!isAuth) {
            setShowLoginModal(true);
            return false;
        }
        try {
            setLoading(true);
            const {
                data: { statusCode, errorCode },
            } = await cartApi.addCart({
                product_id: selectProductIdPattern,
                quantity: 1,
            });

            if (errorCode === 400) {
                notification.open({
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                    description: 'Sorry something went wrong.',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
                setLoading(false);
                return;
            } else if (statusCode === 200) {
                setVisibleModal(true);
                const img = document.querySelector('.canvas-modal-3d canvas').toDataURL('image/png');
                setImgCartPreview(img);
                cartApi.getCart().then(({ data: { data } }) => {
                    dispatch(addToCartAction.list(data));
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            notification.open({
                duration: 3,
                top: 120,
                closeIcon: <img src={toastCloseIcon} alt="" />,
                description: 'Sorry something went wrong.',
                className: 'alert-add-to-cart-danger text-white size-14',
            });
        }
    };

    const changeZoom = (type) => {
        switch (type) {
            case 'in':
                return setZoom((prev) => prev + 0.1);
            case 'out':
                return setZoom((prev) => prev - 0.1);
        }
    };

    const getCustomize = async () => {
        const {
            data: { data },
        } = await productApi.getCustomize(idBag);

        let serializeData = [];
        Object.keys(data.customizes).forEach((item) => {
            Object.keys(data.customizes[item]).forEach((ele) => {
                serializeData.push(data.customizes[item][ele]);
            });
        });
        dispatch(model3dAction.setPosition(serializeData?.[0]?.[0]?.position_text));

        setDataCustomize(serializeData);

        if (data.cover_3d_customize) {
            dispatch(model3dAction.setModel3D(data.cover_3d_customize));
        }
        setInfoProduct({ name: data.product_name, price: data.actual_price, oldPrice: data.original_price });
    };

    useEffect(() => {
        getCustomize();
        // const timer = setTimeout(() => {
        //     setVisibleModalStep(true);
        // }, 5000);
        // return () => clearTimeout(timer);
    }, []);

    // const RenderSettings = () => {
    //     return dataCustomize.rough || dataCustomize.smooth ? (
    //         <>
    //             <Collapse
    //                 className="border-0 mb-5 pb-5 mb-lg-3 pb-lg-0"
    //                 activeKey={activeKey}
    //                 onChange={(e) => {
    //                     setActiveKey(e);
    //                 }}
    //                 expandIcon={({ isActive }) => (
    //                     <img
    //                         src={IconArrow}
    //                         alt=""
    //                         className="p-0"
    //                         style={{ transform: `rotate(${isActive ? 0 : '-90deg'})` }}
    //                     />
    //                 )}
    //                 expandIconPosition="right"
    //             >
    //                 <Panel
    //                     header={
    //                         <p className="mb-0">
    //                             <span className="font-weight-bold text-dark size-20">Pattern :</span> &nbsp; &nbsp;
    //                             <span className="text-capitalize text-secondary">{patternType.name}</span>
    //                         </p>
    //                     }
    //                     className="border-0"
    //                     // key="2"
    //                     key="1"
    //                 >
    //                     <TextureListPattern
    //                         textures={dataCustomize?.[typeLeather]?.pattern?.texture}
    //                         setSelectProductIdPattern={setSelectProductIdPattern}
    //                     />
    //                 </Panel>
    //             </Collapse>
    //         </>
    //     ) : (
    //         <></>
    //     );
    // };

    const handleSaveCus = async () => {
        if (!isAuth) {
            setShowLoginModal(true);
            return false;
        }
        try {
            setLoadingSave(true);
            const {
                data: { statusCode, errorCode },
            } = await cartApi.saveCustomize({
                product_id: idBag,
                leather_type_id: leatherType?.customize_id,
                pattern_type_id: patternType?.customize_id,
                pattern_version: patternTypeVersion === [] ? '' : patternTypeVersion?.id,
                personalization: text,
                text_color: color_select,
                pattern_type_child_id: patternTypeVersion?.id,
            });

            if (errorCode === 400) {
                notification.open({
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                    description: 'Sorry something went wrong.',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
                setLoadingSave(false);
                return;
            } else if (statusCode === 200) {
                // productApi.favorite(idBag);
                notification.open({
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                    description: 'Save done !',
                    className: 'alert-add-to-cart-success text-white size-14',
                });
                setLoadingSave(false);
            }
        } catch (error) {
            setLoadingSave(false);
            notification.open({
                duration: 3,
                top: 120,
                closeIcon: <img src={toastCloseIcon} alt="" />,
                description: 'Sorry something went wrong.',
                className: 'alert-add-to-cart-danger text-white size-14',
            });
        }
    };

    useEffect(() => {
        if (dataCustomize) {
            set_pos_camera(dataCustomize?.[0]?.[0]?.position_view);
            set_pos_fbx(dataCustomize?.[0]?.[0]?.position_model);
            set_rotation_fbx(dataCustomize?.[0]?.[0]?.position_rotate);
        }
    }, [dataCustomize]);

    return (
        <>
            <Modal
                title={<h3 className="size-31 font-title-bold text-left text-capitalize mb-0">Added to your cart</h3>}
                visible={visibleModal}
                onCancel={() => setVisibleModal(false)}
                footer={null}
                className="modal-add-cart-success"
            >
                <div>
                    <div className="d-flex border-bottom pb-3 mb-3">
                        <div
                            style={{
                                width: 150,
                                height: 150,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${imgCartPreview})`,
                            }}
                        />
                        <div>
                            <h4 className="mb-0 pl-2">{infoProduct.name}</h4>
                            <p className="mb-0 pl-2 ml-auto">{currencyFormatter(infoProduct.price)}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center btn-open-more default"
                            onClick={() => {
                                history.push('/bags');
                            }}
                        >
                            <span className="color-yuma size-14">Continue shopping</span>
                        </Button>
                        <Button
                            className="d-flex align-items-center justify-content-center ml-3 btn-open-more"
                            onClick={() => {
                                history.push('/cart');
                            }}
                        >
                            <span className="text-white size-14">View my cart</span>
                        </Button>
                    </div>
                </div>
            </Modal>

            {/* STEP */}
            {localStorage.getItem('visibleModalStep') === '1' && (
                <Modal
                    footer={null}
                    centered
                    title=""
                    closeIcon={<img alt="close" className="pr-3 cursor-pointer" src={closeIcon} />}
                    style={{ zIndex: 999, minWidth: '62vw' }}
                    visible={visibleModalStep}
                    onCancel={() => {
                        setVisibleModalStep(false);
                        window.localStorage.setItem('visibleModalStep', '0');
                    }}
                >
                    <div>
                        <BannerStep isModal />
                    </div>
                </Modal>
            )}

            {!isAuth && (
                <Modal
                    footer={null}
                    centered
                    title=""
                    closeIcon={<img alt="close" className="pr-3 cursor-pointer" src={closeIcon} />}
                    style={{ zIndex: 999, minWidth: '62vw' }}
                    visible={visibleModalStep}
                    onCancel={() => {
                        setVisibleModalStep(false);
                        window.localStorage.setItem('visibleModalStep', '0');
                    }}
                >
                    <div>
                        <BannerStep isModal />
                    </div>
                </Modal>
            )}

            <FacebookProvider appId={appId}>
                {!isAuth && (
                    <ShowModal
                        initialModalAction={modalAction.logIn()}
                        show={showLoginModal}
                        handleClose={() => setShowLoginModal(false)}
                    />
                )}
                <div className=" design-custom overflow-hidden">
                    <div className="container-fluid">
                        <div className="mt-20px mb-20px">
                            <div className="text-uppercase size-14 color-nero d-flex align-items-center justify-content-between">
                                <Link to="/" className="mr-2">
                                    <img src={logo} className="logo" />
                                </Link>
                                <p
                                    className="mb-0 cursor-pointer font-weight-bold"
                                    title="go back"
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-md-none">
                                <div className="mb-3">
                                    <h3 className="size-31 font-title-bold text-left text-capitalize mb-0">
                                        {infoProduct.name}
                                    </h3>

                                    <div className="text-left mt-30px d-flex">
                                        <h3 className="size-18 font-weight-bold">
                                            {infoProduct.price ? `${currencyFormatter(infoProduct.price)}` : ''}
                                        </h3>
                                        {infoProduct.oldPrice ? (
                                            <del className="size-14 ml-3 color-dim-gray">
                                                {infoProduct.oldPrice && currencyFormatter(infoProduct.oldPrice)}
                                            </del>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* TODO */}
                            <div className="col-12 col-xl-8">
                                <div className="d-flex position-relative justify-content-center align-items-center model-preview">
                                    <div className="btn-utils-wrapper position-absolute">
                                        {/* <Button
                                            size="large"
                                            className="btn-utils"
                                            icon={<PlusOutlined />}
                                            onClick={() => changeZoom('in')}
                                        />
                                        <Button
                                            size="large"
                                            className="btn-utils"
                                            icon={<MinusOutlined />}
                                            onClick={() => changeZoom('out')}
                                        /> */}
                                    </div>
                                    <div
                                        className="btn-utils-social position-absolute"
                                        onClick={() =>
                                            console.log(
                                                document.querySelector('.canvas-modal-3d canvas').toDataURL('image/png')
                                            )
                                        }
                                    >
                                        <ShareButton href={window.location.href} className="share-fb btn-utils">
                                            <img src={share} alt="share icon" />
                                            <span className="pl-2">Share</span>
                                        </ShareButton>
                                    </div>
                                    <ModelPreview
                                        zoom={zoom}
                                        text={text}
                                        color_select={color_select}
                                        pos_camera={pos_camera}
                                        pos_fbx={pos_fbx}
                                        rotation_fbx={rotation_fbx}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-12 col-xl-4 mb-110px mb-md-0 position-relative"
                                style={{ height: '100%' }}
                            >
                                <div className="mb-3 d-none d-md-block">
                                    <h3 className="size-31 font-title-bold text-left text-capitalize mb-0">
                                        {infoProduct.name ? infoProduct.name : 'Convertible Mini Merinal'}
                                    </h3>

                                    <div className="text-left mt-30px d-flex">
                                        <h3 className="size-18 font-weight-bold">
                                            {infoProduct.price ? `${currencyFormatter(infoProduct.price)}` : ''}
                                        </h3>
                                        {infoProduct.oldPrice ? (
                                            <del className="size-14 ml-3 color-dim-gray">
                                                {infoProduct.oldPrice && currencyFormatter(infoProduct.oldPrice)}
                                            </del>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex align-items-center mt-3">
                                    <p className="mb-0 pr-2 font-weight-bold text-dark size-20">Leather type</p>
                                </div>
                                <div className="pt-2">
                                    <TextureListLeather
                                        textures={dataCustomize}
                                        setSelectProductIdPattern={setSelectProductIdPattern}
                                    />
                                </div>
                                {listPattern.length > 0 && (
                                    <div className="pt-3">
                                        <p className="mb-0 pr-2 font-weight-bold text-dark size-20">Pattern type</p>
                                        <TextureListPattern
                                            textures={listPattern}
                                            setSelectProductIdPattern={setSelectProductIdPattern}
                                        />
                                    </div>
                                )}

                                {listPatternVersion.length > 0 && (
                                    <div className="pt-3">
                                        <p className="mb-0 pr-2 font-weight-bold text-dark size-20">
                                            Select pattern version
                                        </p>
                                        <TextureListPatternVersion
                                            textures={listPatternVersion}
                                            setSelectProductIdPattern={setSelectProductIdPattern}
                                        />
                                    </div>
                                )}

                                {listPattern.length > 0 && (
                                    <>
                                        <div className="pt-3">
                                            <p className="mb-0 pr-2 font-weight-bold text-dark size-20">
                                                Personalization:
                                            </p>
                                            <input
                                                className="ca-input"
                                                value={text}
                                                maxLength={15}
                                                onChange={(e) => setText(e.target.value)}
                                                placeholder="Input your name"
                                            />
                                        </div>

                                        <div className="pt-3">
                                            <p className="mb-0 pr-2 text-dark size-16">Text colors</p>
                                            <div className="d-flex align-items-center">
                                                {define_color?.map((item) => (
                                                    <div
                                                        key={item?.id}
                                                        className="circle-color"
                                                        style={{
                                                            border: `1px solid ${
                                                                color_select_id === item?.id
                                                                    ? item?.value
                                                                    : 'transparent'
                                                            }`,
                                                        }}
                                                    >
                                                        <div
                                                            className="circle-color__item"
                                                            style={{ backgroundColor: `${item?.value}` }}
                                                            onClick={() => {
                                                                set_color_select_id(item?.id);
                                                                set_color_select(item?.value);
                                                            }}
                                                        ></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {/* <RenderSettings /> */}
                                <div className="design-custom-status mb-4">
                                    <div className="h-100 d-flex align-items-center justify-content-between justify-content-lg-between justify-content-xl-start">
                                        <div className="w-100 d-flex  align-items-space-between pl-xl-0 text-right">
                                            <Button
                                                type="primary"
                                                size="small"
                                                className="btn-open-more size-14 text-white text-uppercase text"
                                                onClick={handleSaveCus}
                                                loading={loadingSave}
                                                disabled={!selectProductIdPattern}
                                            >
                                                SAVE
                                            </Button>
                                            <div style={{ marginLeft: '20px' }} />
                                            <Button
                                                type="primary"
                                                size="small"
                                                className="btn-open-more size-14 text-white text-uppercase text"
                                                onClick={handleAddToCart}
                                                loading={loading}
                                                disabled={!selectProductIdPattern}
                                            >
                                                DONE & ADD TO CART
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FacebookProvider>
        </>
    );
};

export default React.memo(DesignCustomize);
