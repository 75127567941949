import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './Cart.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
// import { listBagIconic } from '../Bags/mock-data';
import { Button, InputNumber, Empty, Tooltip } from 'antd';
import { get, isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import minusIcon from '../../assets/img/icon/minus.png';
import plusIcon from '../../assets/img/icon/plus.png';
import customizeIcon from '../../assets/img/icon/customize.svg';

// import bgItem from '../../assets/img/home/solid-color-image.jpeg';
// import customizeIcon from '../../assets/img/icon/customize_black.svg';
import addToCartAction from '../../actions/add-to-cart.action';
import { currencyFormatter, getQuantityCartItems } from '../../helpers';
import ShowModal from '../ModalButton/ShowModal';
import modalAction from '../../actions/modal.action';
import { SLIDER_SETTINGS } from '../Bags/BagDetails/BagDetails';
import COMMON_CONST from '../../constants/common.constant';
import model3dAction from '../../actions/model-3d.action';
// import PageLoading from '../PageLoading';
// import COMMON_CONST from '../../constants/common.constant';

const CartItem = ({ item }) => {
    const history = useHistory();
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const { cartLoading } = useSelector((state) => state.cart);

    useEffect(() => {
        setQuantity(item.quantity);
    }, [item]);

    const inc = () => {
        const _quantity = quantity + 1;
        setQuantity(_quantity);
        dispatch(addToCartAction.updateCart(item.id, _quantity));
    };

    const dec = () => {
        const _quantity = quantity - 1;
        setQuantity(_quantity);
        dispatch(addToCartAction.updateCart(item.id, _quantity));
    };

    const removeCartItem = (id) => dispatch(addToCartAction.removeCart(id));

    console.log(item);

    const handleBackToCustomize = () => {
        Promise.all([
            dispatch(model3dAction.setModel3D('')),
            dispatch(
                model3dAction.pickLeatherType({
                    name: item.attributes.leather_type_name,
                    type: item.attributes.pattern_type,
                    id: item.attributes.pattern_type_id,
                    namePattern: item.attributes.pattern_type_name,
                })
            ),
            dispatch(model3dAction.pickImg(item.attributes.pattern_type)),
            dispatch(model3dAction.setColor(item.attributes.color)),
            dispatch(model3dAction.setIsPattern(true)),
            dispatch(
                model3dAction.selectType({
                    leatherType: { id: item.attributes.leather_type_id, name: item.attributes.leather_type_name },
                    patternType: { name: item.attributes.pattern_type_name, id: item.attributes.pattern_type_id },
                })
            ),
        ]).then(() => {
            window.location.replace(
                `/design-your-own/${item.attributes.parent_id}/customize?${item.name}&price${item.price}`
            );
        });
    };

    return (
        <div>
            <div className="row pt-30px pb-30px cart-item">
                <div className="col-12 col-sm-4">
                    <div className="cart-item-img d-flex justify-content-center align-items-center">
                        <img
                            className="cursor-pointer"
                            onClick={() => history.push(`/bags/${item.attributes.parent_id}/details`)}
                            src={item.attributes.image}
                            alt=""
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-8">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column h-100 p-3 p-md-0">
                            <h3
                                className="size-18 font-title-bold color-nero cursor-pointer mb-0 mb-md-2"
                                onClick={() => history.push(`/bags/${item.attributes.parent_id}/details`)}
                            >
                                {get(item, 'name')}
                            </h3>
                            <div className="mt-0 mt-md-3 d-flex flex-column flex-md-row align-items-md-center">
                                <span className="size-18 color-black">{currencyFormatter(get(item, 'price'))}</span>
                                <div className="d-flex ml-md-4">
                                    <Button
                                        icon={<img src={minusIcon} alt="" />}
                                        className={`btn-control d-flex align-items-center justify-content-center mr-2 ${
                                            quantity <= 1 && 'disabled-half'
                                        }`}
                                        onClick={() => quantity > 1 && dec()}
                                        disabled={cartLoading}
                                    />
                                    <InputNumber
                                        className="input-quantity size-16 text-center"
                                        min={1}
                                        value={quantity}
                                    />
                                    <Button
                                        icon={<img src={plusIcon} alt="" />}
                                        className="btn-control d-flex align-items-center justify-content-center ml-2"
                                        onClick={inc}
                                        disabled={cartLoading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="btn-wrapper d-flex">
                            <Tooltip title="Delete">
                                <Button
                                    icon={<DeleteOutlined />}
                                    shape="circle"
                                    className="d-flex align-items-center justify-content-center mt-2 mt-xl-3 ml-0 ml-xl-2 mr-2 btn-icon-custom default"
                                    onClick={() => removeCartItem(item.id)}
                                ></Button>
                            </Tooltip>
                            {item.attributes.pattern_type && (
                                <Tooltip title="Back to customize">
                                    <Button
                                        icon={<ReactSVG src={customizeIcon} />}
                                        shape="circle"
                                        className="d-flex align-items-center justify-content-center mt-2 mt-xl-3 ml-0 ml-xl-2 mr-2 btn-icon-custom default"
                                        onClick={handleBackToCustomize}
                                    ></Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* {product_related.length > 0 && (
                <div className="container-fluid">
                    <h4 className="size-16 color-dim-gray text-center text-uppercase">RELATED PRODUCTS</h4>
                    <h3 className="size-48 font-title-bold text-center mt-30px">
                        There’s more that is waiting for you
                    </h3>
                    <div className="mt-20px position-relative">
                        <Slider {...SLIDER_SETTINGS} className="bag-slide">
                            {product_related.map((item, index) => (
                                <div className="item" key={index}>
                                    <NavLink to={`/bags/${item.id}/details`}>
                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                            <img src={item.thumbnail} alt={item.product_name} />
                                        </div>
                                        <div className="content">
                                            <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                {item.product_name}
                                            </h3>
                                            <p className="size-14 color-dim-gray">{currencyFormatter(item.price)}</p>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )} */}
        </div>
    );
};

const Cart = () => {
    // const dispatch = useDispatch();
    // const [total, setTotal] = useState(0);
    const [showLoginModal, setShowLoginModal] = useState(false);
    // const [loading, setLoading] = useState(true);

    const { items: cartItems, product_related } = useSelector((state) => state.cart);
    const cartSubtotal = useSelector((state) => state.cart.subtotal);
    const isAuth = useSelector((state) => !!state.auth.token);

    useEffect(() => {
        !isAuth && setShowLoginModal(true);
    }, [isAuth]);

    // const listCart = useSelector((state) => state.cart.listProduct);
    // const quantityTotal = useSelector((state) => state.cart.quantityTotal);
    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 2,
    // });

    // useEffect(() => {
    //     dispatch(addToCartAction.updateQuantity());
    //     setTotal(
    //         listCart.reduce((result, item) => {
    //             return result + Number(findBagById(item.bagId)['cost'].replace(/[^0-9.-]+/g, '')) * item.quantity;
    //         }, 0)
    //     );
    // }, [listCart]);

    // useEffect(() => {
    //     if (!isEmpty(cartItems, true) > 0) {
    //         setLoading(false);
    //     }
    // }, [cartItems]);

    // if (loading) return <PageLoading />;

    return (
        <div className="page-container cart">
            {!isAuth && (
                <ShowModal
                    initialModalAction={modalAction.logIn()}
                    show={showLoginModal}
                    handleClose={() => setShowLoginModal(false)}
                />
            )}
            <div className="container-fluid pt-80px">
                <div className="size-16 color-dim-gray text-center mb-30px">YOUR CART</div>
                <div className="contact-title size-48 color-nero text-center font-title-bold mb-80px">
                    View your items and check out
                </div>
                {!isEmpty(cartItems, true) > 0 && (
                    <div className="row mt-80px">
                        <div className="col-12 col-lg-8">
                            {Object.keys(cartItems).map((key, index) => (
                                <div key={index} className="cart-item-wrapper">
                                    <CartItem item={cartItems[key]} />
                                </div>
                            ))}
                        </div>
                        <div className="col-12 col-lg-4 mt-30px">
                            <div className="resume-cart">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="size-16 text-uppercase color-nero">total</div>
                                    <div className="size-24 color-nero">{currencyFormatter(cartSubtotal)}</div>
                                </div>
                                <div className="size-16 color-nero mt-10px">
                                    {getQuantityCartItems(cartItems)} items
                                </div>
                                {/* <div className="size-14 color-nero mt-10px">{quantityTotal} items</div> */}
                                <NavLink to="/checkout">
                                    <Button className="banner-btn w-100 mt-80px btn-checkout d-flex align-items-center text-white">
                                        <div className="size-14 d-flex justify-content-center align-items-center w-100 h-100 text-white text-uppercase">
                                            PROCEED TO CHECKOUT
                                        </div>
                                    </Button>
                                </NavLink>
                            </div>
                        </div>

                        {product_related.length > 0 && (
                            <div className="col-12">
                                <div className="container-fluid mt-80px ">
                                    <h4 className="size-16 color-dim-gray text-center text-uppercase mb-80px">
                                        RELATED PRODUCTS
                                    </h4>
                                    <div className="mt-20px position-relative">
                                        <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                            {product_related.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <NavLink to={`/bags/${item.id}/details`}>
                                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                            <img
                                                                src={
                                                                    item.thumbnail.includes('http')
                                                                        ? item.thumbnail
                                                                        : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                                }
                                                                alt={item.product_name}
                                                            />
                                                        </div>
                                                        <div className="content">
                                                            <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                {item.product_name}
                                                            </h3>
                                                            <p className="size-14 color-dim-gray">
                                                                {currencyFormatter(item.price)}
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isEmpty(cartItems, true) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
        </div>
    );
};

export default Cart;
