import React from 'react';
import { Spin } from 'antd';

import './PageLoading.scss';

const PageLoading = () => {
    return (
        <div className="page-loading">
            <Spin size="large" />
        </div>
    );
};

export default PageLoading;
