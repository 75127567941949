import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import './Collection.scss';
import collectionApi from '../../apis/collectionApi';

import OtherCollection from '../OtherCollection/OtherCollection';
import { animationConfig } from '../../helpers';
import PageLoading from '../PageLoading';
import COMMON_CONST from '../../constants/common.constant';

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const Collection = () => {
    const [collections, setCollections] = useState([]);
    const [otherCollections, setOtherCollections] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        let {
            data: {
                data: {
                    featured_collection: collections,
                    other_collections: { items: otherCollections },
                },
            },
        } = await collectionApi.get();
        setCollections(collections);
        setOtherCollections(otherCollections);
        setLoading(false);
    };

    useEffect(() => {
        AOS.init({
            disable: 'mobile',
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);
    if (loading) return <PageLoading />;
    return (
        <div className="collection pt-200px overflow-hidden">
            <div className="container-fluid">
                {collections &&
                    collections.map((item, index) => {
                        return (
                            <div className="row" key={item.id}>
                                {/* <ScrollAnimation {...ANIMATION_CONFIG} className={`col-12 col-lg-6 ${index % 2 !== 0 ? 'order-first order-lg-last' : ''}`}> */}
                                <div
                                    className={`col-12 col-md-6 ${index % 2 !== 0 ? 'order-first order-lg-last' : ''}`}
                                >
                                    <img
                                        src={
                                            item.thumbnail.includes('http')
                                                ? item.thumbnail
                                                : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                        }
                                        className="collection__image"
                                        alt=""
                                    />
                                </div>
                                {/* </ScrollAnimation> */}
                                <div
                                    className={`col-12 col-md-6 col-xl-5 align-self-center offset-xl-1 ${
                                        index % 2 !== 0 ? 'order-last order-lg-first' : ''
                                    }`}
                                >
                                    <div className="collection-content collection-content--one position-relative">
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            className="collection-title font-title-bold size-48 color-nero mb-10px"
                                        >
                                            {item.title}
                                        </ScrollAnimation>
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={200}
                                            className="collection-subtitle size-14 color-dim-gray mb-30px"
                                        >
                                            {item.excerpt}
                                        </ScrollAnimation>
                                        <ScrollAnimation {...ANIMATION_CONFIG} delay={400}>
                                            <NavLink
                                                to={`/collections/${item.slug}/details`}
                                                className="collection-btn text-white text-uppercase text-center mb-20px"
                                            >
                                                discover more
                                            </NavLink>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {otherCollections && otherCollections.length > 0 && <OtherCollection otherCollections={otherCollections} />}
        </div>
    );
};

export default Collection;
