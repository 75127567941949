import React, { useEffect, useState } from 'react';
// import AOS from 'aos';
import { Button } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import { NavLink } from 'react-router-dom';

import './DesignYourOwn.scss';
// import { listBag } from './mock-data';
import ListBag from '../Bags/ListBag/ListBag';
import productApi from '../../apis/productApi';
import collectionApi from '../../apis/collectionApi';
import PageLoading from '../PageLoading';
import { animationConfig } from '../../helpers';
import COMMON_CONST from '../../constants/common.constant';

// import banner from '../../assets/img/our-story/our-story_banner.png';

const LIMIT = 10;

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const mockData = [
    'https://backend-toi.tekup.vn/uploads/product/20221115041024637311301544f.jpg',
    'https://backend-toi.tekup.vn/uploads/product/2022111504055863731026c599b.jpg',
    'https://backend-toi.tekup.vn/uploads/product/2022111504555663731bdc9f19f.jp',
    'https://backend-toi.tekup.vn/uploads/product/202211150440086373182827fcb.jpg',
];

const DesignYourOwn = () => {
    const [listBag, setListBag] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalBag, setTotalBag] = useState(0);
    const [collections, setCollections] = useState([]);

    const getData = async () => {
        try {
            const {
                data: { data, total },
            } = await productApi.listCustomize({ page: currentPage, limit: LIMIT });
            setListBag((prev) => [...prev, ...data]);
            setTotalBag(total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const fetchData = async () => {
        let {
            data: {
                data: { featured_collection: collections },
            },
        } = await collectionApi.get();
        setCollections(collections);
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (currentPage) {
            getData();
        }
    }, [currentPage]);

    // useEffect(() => {
    //     AOS.init({
    //         disable: 'mobile',
    //     });
    // }, []);

    const renderBannerInfinite = () => {
        return (
            <div className="mb-110px section-banner-infinite">
                <div className="banner-infinite">
                    {mockData.map((item, key) => (
                        <div className="card" key={key}>
                            <img src={item} alt="Our Story" />
                        </div>
                    ))}
                </div>
                <div className="banner-infinite">
                    {mockData.map((item, key) => (
                        <div className="card" key={key}>
                            <img src={item} alt="Our Story" />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (loading) return <PageLoading />;
    return (
        <div className="page-container design-your-own">
            {renderBannerInfinite()}

            <div className="mt-120px">
                <div className="container-fluid">
                    <h4 className="size-16 color-dim-gray text-center text-uppercase">DESIGN YOUR OWN</h4>
                    <h3 className="size-48 font-title-bold text-center mt-30px">
                        Customize any bags as the way you want
                    </h3>
                    <div className="mt-80px mb-80px">
                        <ListBag listBag={listBag} col={4} path="bags" hover="custom" />
                    </div>
                    {listBag.length < totalBag && (
                        <div className="text-center mb-80px">
                            <Button
                                type="primary"
                                className="btn-open-more mr-auto ml-auto"
                                size="large"
                                loading={loading}
                                onClick={() => setCurrentPage((prev) => prev + 1)}
                            >
                                More
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="collection-wrapper">
                <div className="container mt-120px mb-120px">
                    <ScrollAnimation {...ANIMATION_CONFIG}>
                        <h3 className="size-48 font-title-bold text-center mt-30px">EXPLORE OUR LASTEST</h3>
                    </ScrollAnimation>
                    <div className="row mt-80px">
                        {collections?.map((collection, index) => (
                            <ScrollAnimation
                                {...ANIMATION_CONFIG}
                                delay={index * 150}
                                key={collection.id}
                                className="col-12 col-md-4 d-flex justify-content-center cursor-pointer collection-item-wrapper"
                            >
                                <NavLink className="collection-item" to={`/collections/${collection.slug}/details`}>
                                    <div className="collection-item__image">
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    collection.thumbnail.includes('http')
                                                        ? collection.thumbnail
                                                        : COMMON_CONST.DOMAIN_IMG + collection.thumbnail
                                                })`,
                                            }}
                                        />
                                    </div>
                                    <h3 className="size-18 font-title-bold mt-3">{collection.title}</h3>
                                </NavLink>
                            </ScrollAnimation>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesignYourOwn;
