const CART_CONST = {
    SET_LOADING: 'SET_LOADING',
    GET_LIST: 'GET_LIST',
    UPDATE_QUANTITY: 'UPDATE_QUANTITY',
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
    RESET_CART: 'RESET_CART',
};

export default CART_CONST;
