import React from 'react';

import './PageNotFound.scss';
import badBag from '../../assets/img/page-not-found/bad-bag.svg';
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
  return <div className="pnf pt-120px">
    <div className="pnf-main d-flex flex-column align-items-center">
      <img src={badBag} alt="" className="pnf-img mb-5" />
      <p className="pnf-title font-title-bold size-48 mb-2">Page not found</p>
      <p className="pnf-text size-24 text-center mb-5">Sorry, we can&apos;t find that page! It might be an old link or maybe it moved.</p>
      <NavLink to="/" className="pnf-btn color-white d-block text-uppercase">back to home</NavLink>
    </div>
  </div>;
};

export default PageNotFound;
