/* eslint-disable no-undef */
import React, { useState } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
// import InstagramLogin from 'react-instagram-login';

import modalAction from '../../actions/modal.action';
import authAction from '../../actions/auth.action';
import faceIcon from '../../assets/img/icon/Face.svg';
// import instagramIcon from '../../assets/img/icon/Instagram.svg';
import googleIcon from '../../assets/img/icon/Google.svg';
import './LoginModal.scss';

import authApi from '../../apis/authApi';

const LogInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const LoginModal = (props, ref) => {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(null);
    const dispatch = useDispatch();

    const handleShowForgotPassword = () => {
        dispatch(modalAction.forgotPassword());
    };
    const handleShowSignUp = () => {
        if (props.isPage) {
            history.push('/signup');
        } else {
            dispatch(modalAction.signUp());
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setIsAuth(null);
        // Handle store cache email
        const cacheEmail = values.email;
        dispatch(modalAction.saveEmail(cacheEmail));

        try {
            dispatch(authAction.loginRequest());
            const data = await authApi.login(values);
            // dispatch(authAction.loginSuccess(data.data.access_token));
            dispatch(authAction.loginSuccess({ token: data.data.access_token, isSocial: false }));
            dispatch(modalAction.close());
        } catch (error) {
            setIsAuth(false);
            values.password = '';
            setSubmitting(false);
            dispatch(authAction.loginFailure(error.error));
        }
    };
    const responseFacebook = (response) => {
        authApi.loginFb({ access_token: response.accessToken }).then((res) => {
            if (res.data.access_token) {
                dispatch(authAction.loginSuccess({ token: res.data.access_token, isSocial: true }));
                dispatch(modalAction.close());
            }
        });
    };
    const responseGoogle = (response) => {
        authApi.loginGg({ auth_code: response.accessToken }).then((res) => {
            if (res.data.access_token) {
                dispatch(authAction.loginSuccess({ token: res.data.access_token, isSocial: true }));
                dispatch(modalAction.close());
            }
        });
    };

    return (
        <div className="login d-flex flex-column align-items-center" ref={ref}>
            <p className="login-title color-nero size-16 text-center">LOG IN</p>
            <p className="login-greeting color-black size-25 font-title-bold text-center">Hello there, welcome back!</p>
            {isAuth === false ? (
                <div className="signup-error color-contessa text-left mb-2">Email or password is incorrect</div>
            ) : null}
            <Formik initialValues={{ email: '', password: '' }} validationSchema={LogInSchema} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form className="login-form text-center w-100">
                        <label className="login-label size-16 color-nero text-left w-100" htmlFor="login-email">
                            EMAIL
                        </label>
                        <Field
                            type="email"
                            name="email"
                            id="login-email"
                            className="login-input size-16 w-100"
                            placeholder="Email"
                        />
                        <ErrorMessage
                            className="login-error color-contessa text-left mt-2"
                            name="email"
                            component="div"
                        />
                        <label className="login-label size-16 color-nero text-left w-100" htmlFor="login-password">
                            PASSWORD
                        </label>
                        <Field
                            type="password"
                            name="password"
                            id="login-password"
                            className="login-input size-16 w-100"
                            placeholder="Password"
                        />
                        <ErrorMessage
                            className="login-error color-contessa text-left mt-2"
                            name="password"
                            component="div"
                        />
                        <div className="text-left mt-3">
                            <div role="button" className="login-forgot-password" onClick={handleShowForgotPassword}>
                                Forgot password
                            </div>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="login-btn text-white">
                            LOG IN
                        </button>
                    </Form>
                )}
            </Formik>
            <p className="login-or size-16 text-uppercase">or log in with</p>
            <nav className="login-nav">
                {/* <button className="login-nav-btn">
                    <img src={faceIcon} alt="" />
                </button>
                <button className="login-nav-btn">
                    <img src={googleIcon} alt="" />
                </button>
                <button className="login-nav-btn">
                    <img src={instagramIcon} alt="" />
                </button> */}
                <FacebookLogin
                    version="3.3"
                    appId={process.env.REACT_APP_CLIENT_ID_FB}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="login-nav-btn">
                            <img src={faceIcon} alt="" />
                        </button>
                    )}
                />
                <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="login-nav-btn">
                            <img src={googleIcon} alt="" />
                        </button>
                    )}
                />
                {/* <InstagramLogin
                    clientId={process.env.REACT_APP_CLIENT_ID_INS}
                    buttonText="Login"
                    onSuccess={responseInstagram}
                    onFailure={responseInstagram}
                    cssClass="login-nav-btn"
                >
                    <img src={instagramIcon} alt="" />
                </InstagramLogin> */}
            </nav>
            <p className="color-dark-gray-1 login-signup text-uppercase">
                don&apos;t have an account,{' '}
                <button className="login-signup-btn color-nero text-uppercase" onClick={handleShowSignUp}>
                    sign up here
                </button>
            </p>
        </div>
    );
};

export default React.forwardRef(LoginModal);
