import { createAction } from 'redux-actions';
import { notification } from 'antd';

import CART_CONST from '../constants/cart.constant';
import COMMON_CONST from '../constants/common.constant';
import cartApi from '../apis/cartApi';

import toastCloseIcon from '../assets/img/icon/toast-close.svg';


const addToCartAction = {
    list: createAction(CART_CONST.GET_LIST),
    addCart: (product_id, quantity) => {
        return async dispatch => {
            const { data: { statusCode, errorCode, errorMessage } } = await cartApi.addCart({ product_id, quantity });
            if (errorCode === 400) {
                notification.open({
                    description: errorMessage,
                    className: 'alert-add-to-cart-danger text-white size-14',
                    style: {
                        width: 600,
                    },
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                });
                return;
            }
            if (statusCode === 200) {
                notification.open({
                    description: 'Successfully added to cart. View cart and check out.',
                    className: 'alert-add-to-cart-success text-white size-14',
                    style: {
                        width: 600,
                    },
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                });
                cartApi.getCart().then(({ data: { data } }) => {
                    dispatch(addToCartAction.list(data));
                });
            }
        };
    },
    updateCart: (product_id, quantity) => {
        return async dispatch => {
            const { data: { statusCode } } = await cartApi.updateCart({ product_id, quantity });
            if (statusCode === 200) {
                cartApi.getCart().then(({ data: { data } }) => {
                    dispatch(addToCartAction.list(data));
                });
            }
        };
    },
    removeCart: product_id => {
        return async dispatch => {
            const { data: { statusCode } } = await cartApi.removeCart({ product_id });
            if (statusCode === 200) {
                cartApi.getCart().then(({ data: { data } }) => {
                    dispatch(addToCartAction.list(data));
                });
            }
        };
    },
    resetCart: createAction(CART_CONST.RESET_CART),
    // ==============

    updateQuantity: createAction(CART_CONST.UPDATE_QUANTITY),
    init: createAction(COMMON_CONST.INIT, (initData) => ({ initData })),
    addToCart: createAction(CART_CONST.ADD_TO_CART, (bagId, quantity) => ({ bagId, quantity })),
    removeCartItem: createAction(CART_CONST.REMOVE_CART_ITEM, (bagId) => ({ bagId })),
};

export default addToCartAction;
