import React, { useEffect, useState } from 'react';
import { Steps, Tooltip, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Checkout.scss';

import CheckoutAddress from '../CheckoutAddress/CheckoutAddress';
import CheckoutPayment from '../CheckoutPayment/CheckoutPayment';

import locationStepIcon from '../../assets/img/icon/location-step.svg';
import locationAcStepIcon from '../../assets/img/icon/location-step-active.svg';
import loginStepIcon from '../../assets/img/icon/login-step.svg';
import loginAcStepIcon from '../../assets/img/icon/login-step-active.svg';
import paymentStepIcon from '../../assets/img/icon/payment-step.svg';
import paymentAcStepIcon from '../../assets/img/icon/payment-step-active.svg';
import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

import checkoutStepAction from '../../actions/checkout-step.action';
import cartAction from '../../actions/add-to-cart.action';
import cartApi from '../../apis/cartApi';

const notification_options = {
    duration: 3,
    top: 120,
    closeIcon: <img src={toastCloseIcon} alt="" />,
};

const Checkout = (props) => {
    const current = useSelector((state) => state.checkoutStep.step);
    const dispatch = useDispatch();

    const [orderData, setOrderData] = useState({});

    const onChange = (current) => {
        switch (current) {
            case 1:
                dispatch(checkoutStepAction.addressStep());
                break;
            case 2:
                dispatch(checkoutStepAction.paymentStep());
                break;
        }
    };

    useEffect(() => {
        onChange(1);
    }, []);

    const handleSubmitOrder = async (data) => {
        let _orderData = { ...orderData, ...data };
        setOrderData(_orderData);
        if (current === 2) {
            const checkOutData = {
                shipping_name: _orderData.name,
                shipping_phone: _orderData.phone,
                shipping_country: _orderData.country,
                shipping_city: _orderData.city,
                shipping_district: _orderData.district,
                shipping_address: _orderData.address,
                shipping_postal_code: _orderData.postalCode,
                shipping_type: _orderData.delivery,
                paymentMethodId: _orderData.paymentMethod.id,
            };
            try {
                const checkout = await cartApi.checkout(checkOutData);

                cartApi.getCart().then(({ data: { data } }) => {
                    dispatch(cartAction.list(data));
                });
                if (checkout.data.errorCode === 400) {
                    notification.open({
                        ...notification_options,
                        description: checkout.data.errorMessage,
                        className: 'alert-add-to-cart-danger text-white size-14',
                    });
                    return;
                } else if (checkout.data.statusCode === 200) {
                    notification.open({
                        ...notification_options,
                        description: 'Check out successfully.',
                        className: 'alert-add-to-cart-success text-white size-14',
                    });
                    props.history.push('/');
                    return;
                }
            } catch (error) {
                notification.open({
                    ...notification_options,
                    description: 'Errors',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
            }
        }
    };

    const steps = [
        {
            title: 'SIGN IN',
            content() {
                return <div>First-content</div>;
            },
            icon: loginStepIcon,
            iconAc: loginAcStepIcon,
        },
        {
            title: 'ADDRESS',
            content() {
                return <CheckoutAddress handleSubmitOrder={handleSubmitOrder} />;
            },
            icon: locationStepIcon,
            iconAc: locationAcStepIcon,
        },
        {
            title: 'PAYMENT & CHECKOUT',
            content() {
                return <CheckoutPayment handleSubmitOrder={handleSubmitOrder} />;
            },
            icon: paymentStepIcon,
            iconAc: paymentAcStepIcon,
        },
    ];

    return (
        <div className="checkout pt-170px">
            <Steps current={current} onChange={onChange}>
                {steps.map((item, index) => (
                    <Steps.Step
                        key={item.title}
                        icon={
                            <div className="position-relative">
                                {/*<div className="size-12 text-uppercase title position-absolute w-100 d-flex justify-content-center">*/}
                                {/*    <div className="position-static">{item.title}</div>*/}
                                {/*</div>*/}
                                <Tooltip placement="top" title={item.title}>
                                    <img src={index <= current ? item.iconAc : item.icon} alt="" />
                                </Tooltip>
                            </div>
                        }
                    />
                ))}
            </Steps>
            <div className="steps-content pt-60px">{steps[current].content()}</div>
        </div>
    );
};

export default withRouter(Checkout);
