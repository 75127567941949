import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import bottomArrow from '../../assets/img/icon/Bottom-Arrow.svg';
import './CheckoutAddress.scss';
import checkoutStepAction from '../../actions/checkout-step.action';
import shippingApi from '../../apis/shippingApi';

const disabledInputStyle = {
    color: '#acacac',
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postalCodeExp = /^([0-9]{5}|[a-zA-Z][a-zA-Z ]{0,49})$/;

const CheckoutAddressSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone number is required').matches(phoneRegExp, 'Phone number is not valid'),
    country: Yup.number().required('Country is required'),
    city: Yup.number().required('City is required'),
    district: Yup.number().required('District is required'),
    address: Yup.string().required('Address is required'),
    postalCode: Yup.string().required('Postal code is required').matches(postalCodeExp, 'Postal code is not valid'),
});

const CheckoutAddress = (props) => {
    const user = useSelector((state) => state.auth.user);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isCityChanged, setIsCityChanged] = useState(false);
    const [isDistrictChanged, setIsDistrictChanged] = useState(false);
    // const [chosenCity, setChosenCity] = useState(null);
    // const [chosenValueCity, setChosenValueCity] = useState('');
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchCountriesData();
    }, []);

    const fetchCountriesData = async () => {
        let {
            data: { data },
        } = await shippingApi.countries();
        setCountries(data);
        setCities([]);
        setDistricts([]);
    };

    const handleCountryChange = async (event, { setFieldValue }) => {
        const { value } = event.target;
        setIsCountryChanged(true);
        setFieldValue('country', value);
        let {
            data: { data },
        } = await shippingApi.cities(value);
        setCities(data);
        setDistricts([]);
    };

    const handleCityChange = async (event, { values, setFieldValue }) => {
        const { value } = event.target;
        setFieldValue('city', value);
        setIsCityChanged(true);
        let {
            data: { data },
        } = await shippingApi.districts(values.country, value);
        setDistricts(data);
    };

    const handleDistrictChange = (event, { setFieldValue }) => {
        const { value } = event.target;
        setFieldValue('district', value);
        setIsDistrictChanged(true);
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        props.handleSubmitOrder(values);
        dispatch(checkoutStepAction.paymentStep());
    };

    return (
        <div className="ca">
            <div className="ca-main">
                <p className="ca-title font-title-bold size-25 color-nero mb-20px">
                    Where should we deliver your order?
                </p>
                <Formik
                    initialValues={{
                        name: user.first_name,
                        phone: user.phone ? user.phone : '',
                        country: '',
                        city: '',
                        district: '',
                        address: '',
                        postalCode: '',
                    }}
                    validationSchema={CheckoutAddressSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ handleChange, isSubmitting, ...other }) => (
                        <Form className="ca-form text-center w-100">
                            <div className="field-container">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-email"
                                >
                                    name
                                </label>
                                <Field
                                    type="text"
                                    name="name"
                                    id="ca-email"
                                    className="ca-input size-16 w-100"
                                    placeholder="Your name"
                                />
                                <ErrorMessage className="color-contessa text-left mt-2" name="name" component="div" />
                            </div>

                            <div className="field-container">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-phone"
                                >
                                    phone number
                                </label>
                                <Field
                                    type="text"
                                    name="phone"
                                    id="ca-phone"
                                    className="ca-input size-16 w-100"
                                    placeholder="Phone number"
                                />
                                <ErrorMessage className="color-contessa text-left mt-2" name="phone" component="div" />
                            </div>

                            <div className="field-container position-relative">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-country"
                                >
                                    country
                                </label>
                                <Field
                                    name="country"
                                    id="ca-country"
                                    className="ca-input size-16 w-100"
                                    placeholder="Country"
                                    component="select"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleCountryChange(e, other);
                                    }}
                                    // value={chosenValueCity}
                                    style={!isCountryChanged ? disabledInputStyle : null}
                                >
                                    <option value="" disabled defaultValue>
                                        Country
                                    </option>
                                    {countries.map((item) => (
                                        <option className="color-nero" key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    className="color-contessa text-left mt-2"
                                    name="country"
                                    component="div"
                                />
                                <img src={bottomArrow} alt="" className="position-absolute" />
                            </div>
                            <div className="field-container position-relative">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-city"
                                >
                                    PROVINCE / STATES
                                </label>
                                <Field
                                    name="city"
                                    id="ca-city"
                                    className="ca-input size-16 w-100"
                                    placeholder="City"
                                    component="select"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleCityChange(e, other);
                                    }}
                                    // value={chosenValueCity}
                                    style={!isCityChanged ? disabledInputStyle : null}
                                >
                                    <option value="" disabled defaultValue>
                                        Province/States
                                    </option>
                                    {cities.map((city) => (
                                        <option className="color-nero" key={city.id} value={city.id}>
                                            {city.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage className="color-contessa text-left mt-2" name="city" component="div" />
                                <img src={bottomArrow} alt="" className="position-absolute" />
                            </div>

                            <div className="field-container position-relative">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-district"
                                >
                                    city
                                </label>
                                <Field
                                    name="district"
                                    id="ca-district"
                                    className="ca-input size-16 w-100"
                                    placeholder="District"
                                    component="select"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleDistrictChange(e, other);
                                    }}
                                    style={!isDistrictChanged ? disabledInputStyle : null}
                                >
                                    <option value="" disabled defaultValue>
                                        City
                                    </option>
                                    {districts.map((item) => (
                                        <option className="color-nero" key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    className="color-contessa text-left mt-2"
                                    name="district"
                                    component="div"
                                />
                                <img src={bottomArrow} alt="" className="position-absolute" />
                            </div>

                            <div className="field-container">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-address"
                                >
                                    address
                                </label>
                                <Field
                                    type="text"
                                    name="address"
                                    id="ca-address"
                                    className="ca-input size-16 w-100"
                                    placeholder="Address"
                                />
                                <ErrorMessage
                                    className="color-contessa text-left mt-2"
                                    name="address"
                                    component="div"
                                />
                            </div>

                            <div className="field-container">
                                <label
                                    className="ca-label text-uppercase size-16 color-nero text-left w-100"
                                    htmlFor="ca-address"
                                >
                                    postal code
                                </label>
                                <Field
                                    type="text"
                                    name="postalCode"
                                    id="ca-address"
                                    className="ca-input size-16 w-100"
                                    placeholder="Postal Code"
                                />
                                <ErrorMessage
                                    className="color-contessa text-left mt-2"
                                    name="postalCode"
                                    component="div"
                                />
                            </div>

                            <button type="submit" disabled={isSubmitting} className="ca-btn text-white text-uppercase">
                                deliver to this address
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CheckoutAddress;
