import { callApi } from './axiosClient';

const homeApi = {
    get: () => {
        const url = 'api/homepage';
        return callApi(url);
    },
};

export default homeApi;
