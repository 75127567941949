import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { forEach } from 'lodash';
import { useDispatch } from 'react-redux';
import { Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import './SignupModal.scss';
import authAction from '../../actions/auth.action';
import modalAction from '../../actions/modal.action';
import faceIcon from '../../assets/img/icon/Face.svg';
import googleIcon from '../../assets/img/icon/Google.svg';

import authApi from '../../apis/authApi';
import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

const SignUpSchema = Yup.object().shape({
    first_name: Yup.string().required('Name is required'),
    // last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Password confirm must match with password'),
});

const SignupModal = (props, ref) => {
    const history = useHistory();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleShowLogIn = () => {
        if (props.isPage) {
            history.push('/login');
        } else {
            dispatch(modalAction.logIn());
        }
    };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        setErrors([]);

        try {
            const { data } = await authApi.register({
                first_name: values.first_name,
                email: values.email,
                password: values.password,
                password_confirmation: values.passwordConfirmation,
            });
            if (data.message === 'Success') {
                dispatch(modalAction.logIn());
                notification.open({
                    description: 'Sign up successfully!',
                    className: 'alert-add-to-cart-success text-white size-14',
                    style: {
                        width: 600,
                    },
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                });
                setLoading(false);
                resetForm();
            } else {
                throw new Error();
            }
        } catch (error) {
            let errors = [];
            forEach(error.errors || [['Errors']], (value) => {
                errors = [...errors, ...value];
            });
            setLoading(false);
            setErrors(errors);
            values.password = '';
            values.passwordConfirmation = '';
        }
    };

    const responseFacebook = (response) => {
        authApi.loginFb({ access_token: response.accessToken }).then((res) => {
            if (res.data.access_token) {
                dispatch(authAction.loginSuccess({ token: res.data.access_token, isSocial: true, isFirstLogin: true }));
                dispatch(modalAction.close());
                history.push('/profile');
            }
        });
    };
    const responseGoogle = (response) => {
        authApi.loginGg({ auth_code: response.accessToken }).then((res) => {
            if (res.data.access_token) {
                dispatch(authAction.loginSuccess({ token: res.data.access_token, isSocial: true, isFirstLogin: true }));
                dispatch(modalAction.close());
                history.push('/profile');
            }
        });
    };

    return (
        <div className="signup d-flex flex-column align-items-center" ref={ref}>
            <p className="signup-title text-uppercase color-nero size-16 text-center">sign up</p>
            <p className="signup-greeting color-black size-25 font-title-bold text-center">
                Hello there, welcome to TOI!
            </p>
            {errors.length > 0 &&
                errors.map((value, k) => (
                    <div key={k} className="signup-error color-contessa text-left mb-2">
                        {value}
                    </div>
                ))}

            <Formik
                initialValues={{ first_name: '', last_name: '', email: '', password: '', passwordConfirmation: '' }}
                validationSchema={SignUpSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="signup-form text-center w-100">
                        <label
                            className="signup-label text-uppercase size-16 color-nero text-left w-100"
                            htmlFor="signup-first-name"
                        >
                            name
                        </label>
                        <Field
                            type="text"
                            name="first_name"
                            id="signup-first-name"
                            className="signup-input size-16 w-100"
                            placeholder="Name"
                        />
                        <ErrorMessage
                            className="signup-error color-contessa text-left mt-2"
                            name="first_name"
                            component="div"
                        />

                        {/* <label
                            className="signup-label text-uppercase size-16 color-nero text-left w-100"
                            htmlFor="signup-last-name"
                        >
                            last name
                        </label>
                        <Field
                            type="text"
                            name="last_name"
                            id="signup-last-name"
                            className="signup-input size-16 w-100"
                            placeholder="Last name"
                        />
                        <ErrorMessage
                            className="signup-error color-contessa text-left mt-2"
                            name="last_name"
                            component="div"
                        /> */}

                        <label
                            className="signup-label text-uppercase size-16 color-nero text-left w-100"
                            htmlFor="signup-email"
                        >
                            email
                        </label>
                        <Field
                            type="email"
                            name="email"
                            id="signup-email"
                            className="signup-input size-16 w-100"
                            placeholder="Email"
                        />
                        <ErrorMessage
                            className="signup-error color-contessa text-left mt-2"
                            name="email"
                            component="div"
                        />

                        <label
                            className="signup-label text-uppercase size-16 color-nero text-left w-100"
                            htmlFor="signup-password"
                        >
                            password
                        </label>
                        <Field
                            type="password"
                            name="password"
                            id="signup-password"
                            className="signup-input size-16 w-100"
                            placeholder="Password"
                        />
                        <ErrorMessage
                            className="signup-error color-contessa text-left mt-2"
                            name="password"
                            component="div"
                        />

                        <label
                            className="signup-label text-uppercase size-16 color-nero text-left w-100"
                            htmlFor="signup-password-comfirmation"
                        >
                            confirm password
                        </label>
                        <Field
                            type="password"
                            name="passwordConfirmation"
                            id="signup-password-confirmation"
                            className="signup-input size-16 w-100"
                            placeholder="Password"
                        />
                        <ErrorMessage
                            className="signup-error color-contessa text-left mt-2"
                            name="passwordConfirmation"
                            component="div"
                        />

                        <Button
                            htmlType="submit"
                            disabled={loading}
                            loading={loading}
                            className="signup-btn text-white text-uppercase"
                        >
                            sign up
                        </Button>
                    </Form>
                )}
            </Formik>
            <p className="login-or size-16 text-uppercase">or sign in with</p>
            <div className="signup-btn-group">
                <FacebookLogin
                    version="3.3"
                    // eslint-disable-next-line no-undef
                    appId={process.env.REACT_APP_CLIENT_ID_FB}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="">
                            <img src={faceIcon} alt="" />
                        </button>
                    )}
                />
                <GoogleLogin
                    // eslint-disable-next-line no-undef
                    clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="">
                            <img src={googleIcon} alt="" />
                        </button>
                    )}
                />
            </div>
            <p className="signup-login color-dark-gray-1 text-uppercase">
                had an account,{' '}
                <button className="signup-login-btn color-nero text-uppercase" onClick={handleShowLogIn}>
                    log in here
                </button>
            </p>
        </div>
    );
};

export default React.forwardRef(SignupModal);
