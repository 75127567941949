import { callApi } from './axiosClient';

const newsApi = {
    get: (params) => {
        const url = '/api/news';
        return callApi(url, 'GET', null, params);
    },
    getCategory: () => {
        const url = '/api/news-categories';
        return callApi(url);
    },
    getDetail: ({ categoryId, slug }) => {
        const url = `/api/news/${categoryId}/${slug}`;
        return callApi(url);
    },
};

export default newsApi;
