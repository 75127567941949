import React, { useEffect, useRef, useState } from 'react';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import Slider from 'react-slick';
import './ReviewImg.scss';
import { useDispatch, useSelector } from 'react-redux';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import reviewImgDialogAction from '../../../../actions/review-img-dialog.action';
// import { ReactComponent as IconLeft } from '../../../../assets/img/iconmonstr-arrow-left-thin.svg';
// import { ReactComponent as IconRight } from '../../../../assets/img/iconmonstr-arrow-right-thin.svg';
import closeIcon from '../../../../assets/img/icon/Close.svg';
import prevIcon from '../../../../assets/img/icon/prev.svg';
import nextIcon from '../../../../assets/img/icon/next.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ReviewImg = () => {
    // const isOpen = useSelector((state) => state?.dialog?.reviewImg?.isOpen);
    const { images, isOpen, indexImage } = useSelector((state) => state.dialog.reviewImg);
    // const [bgPosition, setBgPosition] = useState('0% 0%');
    const [oldY, setOldY] = useState(0);
    const [addScroll, setAddScroll] = useState(0);

    const dispatch = useDispatch();

    const ref = useRef({});
    const refBoxImgDialog = useRef();

    const next = () => {
        ref.current.slickNext();
    };
    const previous = () => {
        ref.current.slickPrev();
    };

    useEffect(() => {
        if (isOpen) {
            ref.current.slickGoTo(indexImage);
        }
    }, [isOpen, indexImage]);

    const handleCancel = () => {
        dispatch(reviewImgDialogAction.closeDialog());
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    // const handleMouseMove = (e) => {
    //     const { left, top, width, height } = e.target.getBoundingClientRect();
    //     const x = ((e.pageX - left) / width) * 100;
    //     const y = ((e.pageY - top) / height) * 100;
    //     setBgPosition(`${x}% ${y}%`);
    // };

    const handleMouseMove = (e) => {
        if (e.pageY < oldY) {
            if (addScroll + (e.pageY - oldY) >= 0) {
                setAddScroll(addScroll + (e.pageY - oldY));
            }
        } else if (e.pageY > oldY) {
            if (addScroll + (e.pageY - oldY) <= refBoxImgDialog.current.scrollHeight) {
                setAddScroll(addScroll + (e.pageY - oldY));
            }
        }
        setOldY(e.pageY);
    };

    useEffect(() => {
        if (refBoxImgDialog.current && addScroll) {
            refBoxImgDialog.current.scrollTop = addScroll;
        }
    }, [refBoxImgDialog, addScroll]);

    return (
        <Modal
            visible={isOpen}
            onCancel={handleCancel}
            footer={null}
            className="review-img-dialog"
            closeIcon={<img alt="close" src={closeIcon} />}
            // style={{ top: 0, height: '100vh' }}
        >
            <div className="review-img-dialog__box" ref={refBoxImgDialog}>
                <Slider {...settings} ref={ref}>
                    {images.map((img, index) => (
                        <div key={index}>
                            <img alt="" src={img} onMouseMove={handleMouseMove} />
                            {/* <figure
                            className="position-relative"
                        >
                            <img
                                alt=""
                                src={img}
                                style={{ position: 'relative', zIndex: 5 }}
                                onMouseMove={handleMouseMove}
                            />
                            <div className="position-absolute review-img-dialog__bg w-100 h-100" />
                            <div
                                className="position-absolute review-img-dialog__item w-100 h-100"
                                style={{ backgroundImage: `url(${img})`, backgroundPosition: bgPosition }}
                            />
                        </figure> */}
                        </div>
                    ))}
                </Slider>
            </div>
            {/* <div className="review-img-dialog-btn-control d-flex align-items-center justify-content-center">
                <p onClick={previous}>
                    <IconLeft />
                </p>
                <p onClick={next}>
                    <IconRight />
                </p>
            </div> */}

            <p className="review-img-dialog-arrow review-img-dialog-arrow__prev" onClick={previous}>
                <img src={prevIcon} />
            </p>
            <p className="review-img-dialog-arrow review-img-dialog-arrow__next" onClick={next}>
                <img src={nextIcon} />
            </p>
        </Modal>
    );
};

export default ReviewImg;
