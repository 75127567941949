import _isPlainObject from 'lodash-es/isPlainObject';

export default function convertObjectToFormData(obj, formData = new FormData(), parentKey) {
    Object.entries(obj).forEach(([key, value]) => {
        const propName = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(value)) {
            if (value.length > 0) convertObjectToFormData(value, formData, propName);
        } else if (_isPlainObject(value) && !(value instanceof File) && !(value instanceof Blob)) {
            convertObjectToFormData(value, formData, propName);
        } else {
            formData.append(propName, value);
        }
    });

    return formData;
}

export const currencyFormatter = (value) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);

export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const getIsLoginBySocial = () => localStorage.getItem('loginBySocial');
export const setIsLoginBySocial = (type) => localStorage.setItem('loginBySocial', type);
export const removeIsLoginBySocial = () => localStorage.removeItem('loginBySocial');

export const getQuantityCartItems = (items) => {
    let quantity = 0;
    Object.keys(items).map((key) => {
        quantity += items[key].quantity;
    });
    return quantity;
};

export const animationConfig = {
    initiallyVisible: false,
    // animatePreScroll: false,
    animateIn: 'fadeInUp',
    // animateOut: 'fadeInUp',
    duration: 1.2,
};
