import React, { useEffect, useState } from 'react';
// import AOS from 'aos';
import './ListBag.scss';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';

import customizeIcon from '../../../assets/img/icon/customize.svg';
import { ReactSVG } from 'react-svg';
import { currencyFormatter } from '../../../helpers';

const ListBag = ({ listBag = [], col = 4, path }) => {
    const [width, setWidth] = useState();

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const renderItem = (bag) => (
        <>
            <div className="content-wrapper">
                <div className={`img-wrapper d-flex justify-content-center align-items-center`}>
                    <>
                        <img className="thumbnail cursor-default" src={bag.thumbnail} />
                        <img
                            className="thumbnail-hover cursor-default position-absolute w-100 h-100"
                            src={bag.thumbnail_hover}
                        />
                    </>
                </div>
                <div className="content">
                    <h3 className={`size-16 font-title mt-3 font-weight-normal`}>{bag.product_name}</h3>
                    <p className={`{size-14 color-dim-gray`}>{currencyFormatter(bag.price)}</p>
                </div>
            </div>
            <NavLink className="d-flex justify-content-center mt-2" to={`/${path}/${bag.id}/details`}>
                <Button
                    icon={<ReactSVG src={customizeIcon} alt="" />}
                    className={`d-flex align-items-center justify-content-center ${
                        width > 600 ? 'btn-hover-change-color' : 'btn-hover-change-color-sm'
                    } ml-0 mr-0`}
                >
                    <span className={`color-yuma size-14 ml-10px text-uppercase`}>get started</span>
                </Button>
            </NavLink>
        </>
    );

    return (
        <div className="row list-bag">
            {listBag.map((bag, index) => (
                <div
                    key={index}
                    className={`bag-item col-6 ${`col-lg-${12 / col} col-md-4  ${
                        !index || index % 2 === 0 ? 'pl-2 pl-md-3' : 'pr-2 pr-md-3'
                    }`}`}
                >
                    {renderItem(bag)}
                </div>
            ))}
        </div>
    );
};

export default ListBag;
