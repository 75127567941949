import { callAuthorizationApi } from './axiosClient';

const cartApi = {
    getCart: () => {
        const url = 'api/cart';
        return callAuthorizationApi(url);
    },

    addCart: (data) => {
        const url = 'api/cart/add';
        return callAuthorizationApi(url, 'POST', data);
    },

    addCartCustomize: (data) => {
        const url = 'api/save-customize/add';
        return callAuthorizationApi(url, 'POST', data);
    },

    saveCustomize: (data) => {
        const url = `api/draft-design/add`;
        return callAuthorizationApi(url, 'POST', data);
    },

    getCustomize: (id) => {
        const url = `api/save-customize/${id}`;
        return callAuthorizationApi(url, 'GET');
    },

    updateCart: (data) => {
        const url = 'api/cart/update';
        return callAuthorizationApi(url, 'POST', data);
    },

    removeCart: (data) => {
        const url = 'api/cart/remove';
        return callAuthorizationApi(url, 'POST', data);
    },

    checkout: (data) => {
        const url = 'api/checkout';
        return callAuthorizationApi(url, 'POST', data);
    },
};

export default cartApi;
