import { callAuthorizationApi } from './axiosClient';

const orderApi = {
    orders: (params) => {
        const url = 'api/order';
        return callAuthorizationApi(url, 'GET', {}, params);
    },
    getDetail: (orderNumber) => {
        const url = `api/order/${orderNumber}`;
        return callAuthorizationApi(url);
    },
};

export default orderApi;
