import bag1 from '../../assets/img/bags/image_2.png';
import bag2 from '../../assets/img/bags/image_4.png';
import bag3 from '../../assets/img/bags/image_5.png';
import icon1 from '../../assets/img/bags/bag1.svg';
import icon2 from '../../assets/img/bags/bag2.svg';
import icon_2 from '../../assets/img/bags/bag-2.svg';
import icon_3 from '../../assets/img/bags/bag-3.svg';
import bag6 from '../../assets/img/bags/image_6.png';
import bag7 from '../../assets/img/bags/image_7.png';
import bag8 from '../../assets/img/bags/image_8.png';
import avatar from '../../assets/img/home/avatar.png';
import bagDailyImg1 from '../../assets/img/home/Rectangle_28.png';
import bagDailyImg2 from '../../assets/img/home/Rectangle_29.png';
import bagDailyImg3 from '../../assets/img/home/Rectangle_30.png';

export const listTrend = [
    {
        img: bag1,
        name: 'Crepe Open Back Gown',
        cost: '$1,000.00',
    },
    {
        img: bag2,
        name: 'Crepe Open Back Gown',
        cost: '$1,000.00',
    },
    {
        img: bag1,
        name: 'Crepe Open Back Gown',
        cost: '$1,000.00',
    },
    {
        img: bag3,
        name: 'Crepe Open Back Gown',
        cost: '$1,000.00',
    },
];

export const listDes = [
    {
        title: 'LEADERS IN LUXURY',
        des:
            'TOI are proud artisans of luxury leather handbags and stylish leather accessories. We pride ourselves in premium-quality pieces that are made to last for generations, in timeless styles that can be used on repeat. Classy, elegant, and customized – these are just a few of the core features that can be found within each item, making them staples for all seasons, events, and occasions.',
        img: icon1,
    },
    {
        title: 'BESPOKE CRAFTSMANSHIP',
        des:
            'TOI has the capabilities to customize each style with your very own designs, making them uniquely yours. Our one-of-a-kind luxury accessories set the new gold standard in the industry, crafting personalized handbags where you have your say in how it is made. We ethically produce every item on-demand, with exceptional attention to detail and outstanding workmanship.',
        img: icon_2,
    },
    {
        title: 'INNOVATIVE TECHNOLOGY',
        des:
            'TOI takes luxury design to new heights – made possible by industry-leading technology and world-class facilities. We blend traditional tailoring and embroidery with cutting-edge processes such as 3D printing to blur the lines between art and fashion – constantly pushing the boundaries to deliver luxurious goods made just for you.',
        img: icon_3,
    },
];

export const listDesignYourOwn = [
    {
        img: bag6,
        title: 'KeriKit Lennox Black Crossover Leather',
    },
    {
        img: bag7,
        title: 'Paul Poppy The Ravenscourt',
    },
    {
        img: bag8,
        title: 'KeriKit Lennox Crossover Leather',
    },
];

export const listOurCommitment = [
    {
        name: 'Design',
        content: [
            'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.',
        ],
    },
    {
        name: 'Materials',
        content: [
            'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum.',
        ],
    },
    {
        name: 'Craft',
        content: [
            'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
            'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.',
        ],
    },
];

export const comment = [
    {
        content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text.",
        author: {
            avatar: avatar,
            name: 'LOLIANA NGUYEN',
            date: 'OCT 14, 2020',
        },
    },
    {
        content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text.",
        author: {
            avatar: avatar,
            name: 'LOLIANA NGUYEN',
            date: 'OCT 14, 2020',
        },
    },
    {
        content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text.",
        author: {
            avatar: avatar,
            name: 'LOLIANA NGUYEN',
            date: 'OCT 14, 2020',
        },
    },
];

export const listBagInDaily = [
    {
        img: bagDailyImg1,
        title: 'It is a long established fact that a reader will be distracted',
        date: 'OCT 15, 2020',
    },
    {
        img: bagDailyImg2,
        title: 'It is a long established fact that a',
        date: 'OCT 15, 2020',
    },
    {
        img: bagDailyImg3,
        title: 'It is a long established fact that a reader will be distracted',
        date: 'OCT 15, 2020',
    },
];
