import reviewImgDialogAction from '../actions/review-img-dialog.action';
import { handleActions } from 'redux-actions';

const initialState = {
    isOpen: false,
    images: [],
    indexImage: 0,
};

const reviewImgDialogReducer = handleActions(
    {
        [reviewImgDialogAction.openDialog]: (state, { payload }) => ({
            ...state,
            isOpen: true,
            indexImage: payload.indexImg,
        }),
        [reviewImgDialogAction.closeDialog]: (state) => ({ ...state, isOpen: false }),

        [reviewImgDialogAction.setDataImg]: (state, { payload }) => ({ ...state, images: payload.img }),
    },
    initialState
);

export default reviewImgDialogReducer;
