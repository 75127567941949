import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Modal, notification } from 'antd';
import * as Yup from 'yup';

import newsLetterApi from '../../apis/newsLetterApi';

import closeIcon from '../../assets/img/icon/Close.svg';
import './NewsLetter.scss';
// import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

const NewsLetterSchema = Yup.object().shape({
    email: Yup.string().email('Email is not valid').required('Email is required'),
});

const NewsLetter = ({ setSendNewLetterSuccess, isModal }) => {
    const [loading, setLoading] = useState(false);
    const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setLoading(true);

        const postDate = async () => {
            setSubmitting(true);
            try {
                const { data } = await newsLetterApi.post(values);
                setLoading(false);
                if (data.message === 'Success') {
                    // notification.open({
                    //     placement: 'topRight',
                    //     description: 'Subscribe successfully!',
                    //     className: 'alert-add-to-cart-success text-white size-14',
                    //     duration: 3,
                    //     closeIcon: <img src={toastCloseIcon} alt="" />,
                    // });
                    setVisibleModalSuccess(true);
                    resetForm();
                    if (setSendNewLetterSuccess) {
                        setSendNewLetterSuccess();
                    }
                } else {
                    notification.open({
                        placement: 'topRight',
                        description: 'This email has already been taken',
                        className: 'alert-add-to-cart-danger text-white size-14',
                    });
                }
            } catch (error) {
                // Handle UI when error is triggered
                console.log(error);
            }
        };
        postDate();
    };

    return (
        <>
            <Modal
                visible={visibleModalSuccess && !isModal}
                closeIcon={<img alt="close" src={closeIcon} />}
                footer={null}
                title=""
                centered
                onCancel={() => setVisibleModalSuccess(false)}
            >
                <h5 className="news-letter-subtitle size-48 font-title-bold text-center mb-0">
                    Thank you for subscribe
                </h5>
            </Modal>
            <div className="news-letter pt-80px pb-80px">
                <div className="container-fluid">
                    <h4 className="size-16 color-dim-gray text-center text-uppercase">NEWSLETTER</h4>
                    <h3 className="news-letter-subtitle size-48 font-title-bold text-center mt-30px">
                        Subscribe now & get 15% off
                    </h3>
                    <div className="d-flex justify-content-center mt-5">
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={NewsLetterSchema}
                            onSubmit={handleSubmit}
                        >
                            {() => (
                                <Form className="news-letter-form position-relative">
                                    <Field
                                        type="text"
                                        name="email"
                                        placeholder="Your email"
                                        className="w-100 size-16"
                                    />
                                    <ErrorMessage name="email" component="div" className="color-contessa mt-2" />
                                    <Button
                                        htmlType="submit"
                                        loading={loading}
                                        className="news-letter-btn text-white text-uppercase size-14 position-absolute"
                                    >
                                        subscribe
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsLetter;
