import { handleActions } from 'redux-actions';
import model3dAction from '../actions/model-3d.action';
// import pattern3 from '../assets/pattern/AVA_Optimize_03_Diffuse.jpg';

export const initialState = {
    model: { AVA_leather001: null },
    isPattern: false,
    listPartName: [],
    pickImg: '',
    model3d: '',
    listLeatherTypePicked: [],
    selectedType: {
        leatherType: { name: '', id: '' },
        patternType: { name: '', id: '' },
        patternTypeVersion: { name: '', id: '' },
    },
    selectedTypeVersion: { patternType: { name: '', id: '' }, patternTypeVersion: { name: '', id: '' } },
    isSmooth: false,
    color: null,
    typeLeather: '',
    leatherRough: '',
    listPattern: [],
    listPatternVersion: [],
    colorText: '#333',
    text: '',
    colorBorder: null,
};

const model3dReducer = handleActions(
    {
        [model3dAction.pickTexture]: (state, { payload }) => ({
            ...state,
            model: { ...state.model, [payload.partName]: payload.img },
        }),
        [model3dAction.pickImg]: (state, { payload }) => ({ ...state, pickImg: payload.img }),
        [model3dAction.setListPartName]: (state, { payload }) => ({
            ...state,
            listPartName: payload.listPartName,
        }),
        [model3dAction.setIsPattern]: (state, { payload }) => ({ ...state, isPattern: payload }),
        [model3dAction.setModel3D]: (state, { payload }) => ({ ...state, model3d: payload.model }),
        [model3dAction.pickLeatherType]: (state, { payload }) => {
            const indexOfLeatherType = state.listLeatherTypePicked.findIndex((item) => item.name === payload.name);
            if (indexOfLeatherType === -1)
                return { ...state, listLeatherTypePicked: [...state.listLeatherTypePicked, payload] };
            else if (payload.type) {
                const newListLeatherTypePicked = [...state.listLeatherTypePicked];
                newListLeatherTypePicked[indexOfLeatherType].type = payload.type;
                newListLeatherTypePicked[indexOfLeatherType].id = payload.id;
                newListLeatherTypePicked[indexOfLeatherType].namePattern = payload.namePattern;
                return { ...state, listLeatherTypePicked: newListLeatherTypePicked };
            }
            return state;
        },
        [model3dAction.selectType]: (state, { payload }) => {
            return { ...state, selectedType: payload };
        },
        [model3dAction.selectedTypeVersion]: (state, { payload }) => {
            return { ...state, selectedTypeVersion: payload };
        },
        [model3dAction.resetModel3D]: () => {
            return initialState;
        },
        [model3dAction.setIsSmooth]: (state, { payload }) => {
            return { ...state, isSmooth: payload };
        },
        [model3dAction.setColor]: (state, { payload }) => {
            return { ...state, color: payload };
        },
        [model3dAction.setTypeLeather]: (state, { payload }) => {
            return { ...state, typeLeather: payload };
        },
        [model3dAction.setLeatherRough]: (state, { payload }) => {
            return { ...state, leatherRough: payload };
        },
        [model3dAction.setListPattern]: (state, { payload }) => {
            return { ...state, listPattern: payload };
        },
        [model3dAction.setListPatternVersion]: (state, { payload }) => {
            return { ...state, listPatternVersion: payload };
        },
        [model3dAction.setPosition]: (state, { payload }) => {
            return { ...state, position: payload };
        },
        [model3dAction.setColorText]: (state, { payload }) => {
            return { ...state, colorText: payload };
        },
        [model3dAction.setColorBorder]: (state, { payload }) => {
            return { ...state, colorBorder: payload };
        },
        [model3dAction.setText]: (state, { payload }) => {
            return { ...state, text: payload };
        },
    },
    initialState
);

export default model3dReducer;
