import React, { useEffect, useState } from 'react';
import { Button, Empty, notification } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
import { isEmpty } from 'lodash';

import NewsLetter from '../NewsLetter/NewsLetter';
import productApi from '../../apis/productApi';
import ListBag from '../Bags/ListBag/ListBagNew';
import '../Bags/Bags.scss';
import authApi from '../../apis/authApi';
import { animationConfig } from '../../helpers';
import PageLoading from '../PageLoading';
import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const MyFavorites = () => {
    const [productFavorites, setProductFavorites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProduct, setTotalProduct] = useState(0);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loadingDislike, setLoadingDislike] = useState(false);

    const getProductFavorites = async () => {
        setLoading(true);
        const {
            data: { data },
        } = await authApi.favorite({ page: currentPage, records_per_page: 10 });
        setLoading(false);
        setFirstLoad(false);
        if (data?.items) {
            setTotalProduct(data.total);
            if (currentPage === 1) {
                setProductFavorites(
                    data.items.map((item) => {
                        return { ...item, id: item.product_id, thumbnail: item.product_thumbnail };
                    })
                );
            } else {
                setProductFavorites([
                    ...productFavorites,
                    ...data.items.map((item) => {
                        return { ...item, id: item.product_id, thumbnail: item.product_thumbnail };
                    }),
                ]);
            }
        }
    };
    const handleLoadMore = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handleDislike = async (product_id) => {
        setLoadingDislike(true);
        const {
            data: { code },
        } = await productApi.favorite(product_id);
        if (code === 200 || code === 201) {
            setProductFavorites((prev) => prev.filter((item) => item.product_id !== product_id));
            setTotalProduct((prev) => prev - 1);
        } else {
            notification.open({
                duration: 3,
                top: 120,
                closeIcon: <img src={toastCloseIcon} alt="" />,
                description: 'error',
                className: 'alert-add-to-cart-danger text-white size-14',
            });
        }
        setLoadingDislike(false);
    };

    useEffect(() => {
        getProductFavorites();
    }, [currentPage]);

    if (firstLoad && loading) return <PageLoading />;

    return (
        <div className="page-container">
            <div className="container-fluid">
                <h4 className="size-16 color-dim-gray text-center text-uppercase mt-40px mb-30px">FAVORITES</h4>
                <div className="my-orders_title size-48 color-nero text-center font-title-bold mb-40px">
                    Products of your favorites
                </div>
                <div data-aos-duration="1250" data-aos-delay="300">
                    <ListBag
                        listBag={productFavorites}
                        path="bags"
                        hover="detail"
                        handleDislike={handleDislike}
                        loadingDislike={loadingDislike}
                    />
                    {isEmpty(productFavorites, true) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>

                {productFavorites.length < totalProduct && (
                    <ScrollAnimation {...ANIMATION_CONFIG} delay={150}>
                        <div className="text-center">
                            <Button
                                type="primary"
                                className="btn-open-more"
                                size="large"
                                loading={loading}
                                onClick={handleLoadMore}
                            >
                                More
                            </Button>
                        </div>
                    </ScrollAnimation>
                )}
            </div>

            <NewsLetter />
        </div>
    );
};

export default MyFavorites;
