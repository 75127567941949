import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './MyOrders.scss';
// import { NewsLetter } from '../index';
// import { mockData } from './mock-data';
import orderApi from '../../apis/orderApi';
import { currencyFormatter } from '../../helpers';

const PAGE_SIZE = 10;

const MyOrders = () => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // define columns of table
    const columns = [
        { title: 'ORDER CODE', dataIndex: 'order_number' },
        {
            title: 'IMAGE',
            dataIndex: 'order_thumb',
            render: function renderElement(img) {
                return <img src={img} alt="image" width="120px" />;
            },
        },
        {
            title: 'DATE',
            dataIndex: 'created_at',
            key: 'created_at',
            render: function renderElement(item) {
                return (
                    <span className="truncate d-block" style={{ maxWidth: '340px' }}>
                        {moment(item).format('MMM DD, YYYY')}
                    </span>
                );
            },
        },
        // {
        //     title: 'PRODUCTS',
        //     dataIndex: 'products',
        //     key: 'products',
        //     render: function renderElement(text) {
        //         return (
        //             <span className="truncate d-block" style={{ maxWidth: '340px' }}>
        //                 {text}
        //             </span>
        //         );
        //     },
        // },
        {
            title: 'AMOUNT',
            dataIndex: 'total',
            render: function renderElement(total) {
                return <span>{currencyFormatter(total)}</span>;
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: function renderElement(text) {
                return <span className={text === 'Delivered' ? 'color-atlantis' : 'color-denim'}>{text}</span>;
            },
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: function renderAction(_, record) {
                return (
                    <div>
                        <Button
                            htmlType="button"
                            onClick={() => {
                                history.push(`/my-order/${record.order_number}/detail`);
                            }}
                        >
                            View Detail
                        </Button>
                    </div>
                );
            },
        },
    ];

    const fetchData = async () => {
        let {
            data: { data },
        } = await orderApi.orders();
        setTableData(data);
        console.log(data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="my-orders pt-200px mb-80px">
                <div className="container">
                    <div className="my-orders-wrapper">
                        <div className="my-orders_header mb-80px">
                            <div className="my-orders_sub-title mb-30px size-16 color-dim-gray text-center">
                                MY ORDERS
                            </div>
                            <div className="my-orders_title size-48 color-nero text-center font-title-bold">
                                Tracking your orders
                            </div>
                        </div>
                        <PerfectScrollbar>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={{ pageSize: PAGE_SIZE }}
                                rowKey={(record) => record.order_number}
                                loading={loading}
                            />
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
            {/* <NewsLetter /> */}
        </div>
    );
};

export default MyOrders;
