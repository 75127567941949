import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'antd';
// import { HeartOutlined } from '@ant-design/icons';
// import toastCloseIcon from '../../../assets/img/icon/toast-close.svg';
// import favoriteAction from '../../../actions/favorite.action';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
// import productApi from '../../../apis/productApi';
// import customizeIcon from '../../../assets/img/icon/customize_black.svg';
import { currencyFormatter, animationConfig } from '../../../helpers';
import { ReactSVG } from 'react-svg';
import customizeIcon from '../../../assets/img/icon/customize.svg';
import cartApi from '../../../apis/cartApi';
import { useDispatch } from 'react-redux';
import model3dAction from '../../../actions/model-3d.action';
import './ListBag.scss';

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const ListBag = ({
    listBag = [],
    highlightIndex = null,
    col = 4,
    path,
    hover = null,
    handleDislike,
    loadingDislike,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [id, setId] = useState('');

    const handleBackToCustomize = (data) => {
        setId(data);
    };

    useEffect(() => {
        if (id !== '') {
            cartApi
                .getCustomize(id)
                .then((response) => {
                    console.log(response);
                    // window.location.replace(`/design-your-own/${response.data.data.pattern_type_id}/customize`);
                    Promise.all([
                        dispatch(model3dAction.setModel3D('')),
                        dispatch(
                            model3dAction.pickLeatherType({
                                name: response.data.data.leather_type_name,
                                type: response.data.data.image_display_leather,
                                id: response.data.data.pattern_type_id,
                                namePattern: response.data.data.pattern_type_name,
                            })
                        ),
                        dispatch(model3dAction.pickImg(response.data.data.image_patent)),
                        dispatch(model3dAction.setColor(response.data.data.text_color)),
                        dispatch(model3dAction.setIsPattern(true)),
                        dispatch(
                            model3dAction.selectType({
                                leatherType: {
                                    id: response.data.data.leather_type_id,
                                    name: response.data.data?.leather_type_name,
                                },
                                patternType: {
                                    name: response.data.data?.pattern_type_name,
                                    id: response.data.data.pattern_type_id,
                                },
                                patternTypeVersion: {
                                    name: response.data.data?.pattern_type_child_name,
                                    id: response.data.data.pattern_type_child_id,
                                },
                            })
                        ),
                    ]).then(() => {
                        // window.location.replace(`/design-your-own/${response.data.data.product_id}/customize`);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id]);

    const detailThisOnHover = (link, bag) => (
        <div className="position-absolute hover w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <NavLink to={link}>
                <Button className="banner-btn d-flex align-items-center justify-content-center">
                    <div className="text-center">
                        <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase ">
                            detail
                        </div>
                    </div>
                </Button>
            </NavLink>

            <Button
                className="banner-btn d-flex align-items-center justify-content-center mt-2"
                onClick={() => handleDislike(bag.id_remove)}
                loading={loadingDislike}
            >
                <div className="text-center">
                    <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase ">
                        remove
                    </div>
                </div>
            </Button>
        </div>
    );

    const renderItem = (bag, index) => (
        <div className={`content-wrapper ${highlightIndex === index && 'position-relative '}`}>
            <div
                className={`img-wrapper d-flex justify-content-center align-items-end ${
                    highlightIndex !== index && 'position-relative'
                }`}
            >
                {hover === 'thumbnail' ? (
                    <>
                        <img className="thumbnail w-100" src={bag.thumbnail} />
                        <img
                            className="thumbnail-hover position-absolute"
                            src={bag.thumbnail_hover}
                            onClick={() => history.push(`/${path}/${bag.id}/details`)}
                        />
                    </>
                ) : (
                    <img src={bag.thumbnail} />
                )}
                {hover === 'detail' && detailThisOnHover(`/${path}/${bag.id}/details`, bag)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="content">
                    <h3
                        className={`size-16 font-title mt-3 font-weight-normal ${
                            highlightIndex === index && 'text-dark'
                        }`}
                    >
                        {bag.product_name}
                    </h3>
                    <p className={`{size-14 color-dim-gray font-title ${highlightIndex === index && 'text-dark'}`}>
                        {currencyFormatter(bag.price)}
                    </p>
                </div>
                <div>
                    {bag?.id_remove && (
                        <Button
                            icon={<ReactSVG src={customizeIcon} />}
                            shape="circle"
                            className="d-flex align-items-center justify-content-center mt-2 mt-xl-3 ml-0 ml-xl-2 mr-2 btn-icon-custom default"
                            onClick={() => handleBackToCustomize(bag.product_id)}
                        ></Button>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div className="row list-bag">
            {listBag.map((bag, index) => (
                <ScrollAnimation
                    {...ANIMATION_CONFIG}
                    delay={index * 150}
                    key={index}
                    // className={`bag-item col-6 ${
                    //     highlightIndex !== index
                    //         ? `col-lg-${12 / col} col-md-4 ${
                    //               index < highlightIndex
                    //                   ? !index || index % 2 === 0
                    //                       ? 'pr-2 pr-md-3'
                    //                       : 'pl-2 pl-md-3'
                    //                   : !index || index % 2 === 0
                    //                   ? 'pl-2 pl-md-3'
                    //                   : 'pr-2 pr-md-3'
                    //           }`
                    //         : `col-12 col-md-8 col-lg-${(12 / col) * 2} highlight`
                    // }`}
                    className={`bag-item col-6 col-sm-6 col-lg-3 col-xl-3`}
                >
                    {!hover ? (
                        <NavLink to={`/${path}/${bag.id}/details`}>{renderItem(bag, index)}</NavLink>
                    ) : (
                        renderItem(bag, index)
                    )}
                </ScrollAnimation>
            ))}
        </div>
    );
};

export default ListBag;
