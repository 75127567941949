import { callApi } from './axiosClient';

const collectionApi = {
    get: () => {
        const url = 'api/collections';
        return callApi(url);
    },
    getCollectionDetail: (slug) => {
        const url = `api/collection/${slug}`;
        return callApi(url);
    },
};

export default collectionApi;
