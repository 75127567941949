import { Button, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import authApi from '../../apis/authApi';
import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

import './reset-password.scss';

const ResetPassword = () => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const [loading, setLoading] = useState(false);

    const onFinishForm = (values) => {
        setLoading(true);
        authApi
            .resetPassword({ email: query.get('email'), token: query.get('reset_token'), ...values })
            .then((res) => {
                if (res.data.message === 'OK') {
                    notification.open({
                        placement: 'topRight',
                        description: 'Reset password successfully!',
                        className: 'alert-add-to-cart-success text-white size-14',
                        duration: 3,
                        closeIcon: <img src={toastCloseIcon} alt="" />,
                    });
                    history.push('/login');
                } else {
                    notification.open({
                        placement: 'topRight',
                        description: 'Sorry something went wrong.',
                        className: 'alert-add-to-cart-danger text-white size-14',
                    });
                }
                setLoading(false);
            })
            .catch(() => {
                notification.open({
                    placement: 'topRight',
                    description: 'Sorry something went wrong.',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
                setLoading(false);
            });
    };

    if (query.get('reset_token')?.length > 0 && query.get('email')?.length > 0) {
        return (
            <div className="container">
                <div className="pt-200px reset-password">
                    <h2 className="mb-5 text-center">Reset password</h2>
                    <Form layout="vertical" onFinish={onFinishForm} requiredMark={false}>
                        <Form.Item
                            label={<span className="mb-0 text-uppercase size-16 color-nero">Password</span>}
                            name="password"
                            rules={[{ required: true, message: 'Password is required!' }]}
                        >
                            <Input type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            label={<span className="mb-0 text-uppercase size-16 color-nero">Confirm Password</span>}
                            name="password_confirmation"
                            dependencies={['password']}
                            rules={[
                                { required: true, message: 'Please confirm your password!' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input type="password" placeholder="Confirm Password" />
                        </Form.Item>
                        <div className="text-right">
                            <Button className="reset-password__btn" htmlType="submit" loading={loading}>
                                Confirm
                            </Button>
                            <Button
                                className="reset-password__btn bg-secondary ml-2"
                                htmlType="button"
                                onClick={() => history.push('/')}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
    return <Redirect to="/" />;
};

export default ResetPassword;
