import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import './Footer.scss';
import logo from '../../assets/img/icon/Toi-White 1.svg';
import faceGray from '../../assets/img/icon/Face-gray.svg';
import twitterGray from '../../assets/img/icon/Twitter-gray.svg';
// import youtubeGray from '../../assets/img/icon/Youtube-gray.svg';
import { ReactComponent as IconPinterest } from '../../assets/img/pinterest-svgrepo-com.svg';
import { NewsLetter } from '../../components';

const Footer = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const [visibleFooter, setVisibleFooter] = useState(true);
    const [visibleNewsLetter, setVisibleNewsLetter] = useState(true);

    useEffect(() => {
        if (['customize'].some((el) => _.includes(pathname, el))) {
            setVisibleFooter(false);
        } else if (
            ['collections', 'my-order', 'details', '/bags', '/cart', 'design-your-own', 'my-favorites'].some((el) =>
                _.includes(pathname, el)
            )
        ) {
            setVisibleNewsLetter(false);
        } else {
            setVisibleNewsLetter(true);
            setVisibleFooter(true);
        }
    }, [pathname]);

    return (
        <div>
            {visibleFooter ? (
                <div className="footer-container">
                    <div className="">{visibleNewsLetter && <NewsLetter />}</div>
                    <div className="footer pt-80px pb-80px">
                        <div className="container">
                            <div className="footer-wrapper flex-wrap flex-lg-nowrap mb-60px d-flex justify-content-between align-items-start">
                                <div className="footer_logo d-flex flex-column justify-content-center align-items-center">
                                    <img
                                        onClick={() => {
                                            if (pathname === '/') {
                                                window.scroll({ top: 0, left: 0 });
                                            } else {
                                                history.push('/');
                                            }
                                        }}
                                        className="footer_logo-img mb-3 cursor-pointer"
                                        src={logo}
                                        alt="logo"
                                    />
                                    <div className="footer_logo-text text-center color-white size-12">
                                        Your Style Essence - Our identity
                                    </div>
                                </div>
                                <div className="footer_nav">
                                    <div className="footer_nav-title mb-20px color-white size-14">NAVIGATION</div>
                                    {/* <Link className="footer_nav-item  d-block text-decoration-none size-14 mb-3" to="/">
                                        Home
                                    </Link> */}
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/bags"
                                    >
                                        Shop
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/design-your-own"
                                    >
                                        Design your own
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/collections"
                                    >
                                        Our collections
                                    </Link>

                                    {/* <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/our-story"
                                    >
                                        Our Story
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/news"
                                    >
                                        Blog
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/contact-us"
                                    >
                                        Contact{' '}
                                    </Link> */}
                                </div>
                                <div>
                                    <div className="footer_nav-title mb-20px color-white size-14">&nbsp;</div>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/our-story"
                                    >
                                        Our Story
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/news"
                                    >
                                        News
                                    </Link>
                                    <Link
                                        className="footer_nav-item d-block text-decoration-none size-14 mb-3"
                                        to="/contact-us"
                                    >
                                        Contact us
                                    </Link>
                                </div>
                                {/* <div className="footer_support d-none d-md-block">
                                    <div className="footer_support-title mb-20px color-white size-14">SUPPORT</div>
                                    <Link
                                        className="footer_support-item d-block text-decoration-none size-14 mb-3"
                                        to="#"
                                    >
                                        Store location
                                    </Link>
                                    <Link
                                        className="footer_support-item d-block text-decoration-none size-14 mb-3"
                                        to="#"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                </div> */}
                                <div className="footer_social d-flex flex-column d-sm-block">
                                    <div className="footer_social-title mb-20px color-white size-14">SOCIAL</div>
                                    <a
                                        className="footer_social-item mb-20px mb-sm-0 mr-20px text-decoration-none"
                                        href="https://www.facebook.com/T0ISTUDIO"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={faceGray} alt="facebook icon" />
                                    </a>
                                    <a
                                        className="footer_social-item mb-20px mb-sm-0 mr-20px text-decoration-none"
                                        href="https://www.instagram.com/toiatelier/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img src={twitterGray} alt="twitter icon" />
                                    </a>
                                    <a
                                        className="footer_social-item text-decoration-none"
                                        href="https://www.pinterest.com/toandnguyen/toi-ateliers-shoes-and-bags/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconPinterest style={{ fill: '#909090', width: 30 }} />
                                    </a>
                                    {/* <div className="footer_support d-none d-md-block">
                                        <div className="footer_support-title mb-20px pt-40px color-white size-14">
                                            SUPPORT
                                        </div>
                                        <Link
                                            className="footer_support-item d-block text-decoration-none size-14 mb-3"
                                            to="#"
                                        >
                                            Store location
                                        </Link>
                                        <Link
                                            className="footer_support-item d-block text-decoration-none size-14 mb-3"
                                            to="#"
                                        >
                                            Terms &amp; Conditions
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="footer_copy-right size-12 text-center">
                                All rights reserved — 2020 © TOI Atelier
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default Footer;
