import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Slider from 'react-slick';
import { NavLink, useHistory } from 'react-router-dom';

import { SLIDER_SETTINGS } from '../Bags/BagDetails/BagDetails';
import { currencyFormatter } from '../../helpers';
import orderApi from '../../apis/orderApi';
import productApi from '../../apis/productApi';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MyOrderDetail.scss';
import PageLoading from '../PageLoading';

const MyOrderDetail = (props) => {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const [detailOrder, setDetailOrder] = useState({});
    const [related, setRelated] = useState([]);
    const [loading, setLoading] = useState(true);

    const orderId = _.get(props, 'match.params.id');
    const getDetailOrder = async (id) => {
        // await orderApi.getDetail(id);
        const {
            data: { data },
        } = await orderApi.getDetail(id);
        setDetailOrder(data);
        setLoading(false);
    };
    const fetchRelatedData = async (bagId) => {
        let {
            data: { data },
        } = await productApi.related(bagId);
        setRelated(data);
    };

    useEffect(() => {
        if (orderId) {
            getDetailOrder(orderId);
        }
    }, [orderId]);
    useEffect(() => {
        if (detailOrder.created_at) {
            fetchRelatedData(detailOrder.products[0].id);
        }
    }, [detailOrder]);

    if (loading) return <PageLoading />;
    return (
        <div className="page-container my-order-detail">
            <div className="container-fluid">
                {detailOrder.created_at ? (
                    <>
                        <h4 className="size-16 color-dim-gray text-center text-uppercase mt-40px mb-20px">ORDER</h4>
                        <div className="my-orders_title size-48 color-nero text-center font-title-bold mb-40px">
                            Details of your order
                        </div>
                        <div className="padding mb-80px">
                            <p>
                                <i className="fas fa-file-invoice-dollar fa-3x" />
                            </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="box">
                                        <h6 className="mb-0">{detailOrder.shipping_name}</h6>
                                        <p className="text-muted">
                                            Phone: {detailOrder.shipping_phone}
                                            <br />
                                            Email: {user.email}
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p>
                                Order date: <strong>{moment(detailOrder.created_at).format('hh:mm MM/DD/YYYY')}</strong>
                                <br />
                                Order Status:
                                <span className="badge success">{detailOrder.status}</span>
                                <br />
                                Payment Status:
                                <span className="badge success">{detailOrder.payment_status}</span>
                                <br />
                                Order ID: <strong> #{orderId}</strong>
                            </p>
                            <div className="table-responsive">
                                <table className="table table-bordered white">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }}>#</th>
                                            <th style={{ width: 100 }}>IMAGE</th>
                                            <th>NAME</th>
                                            <th style={{ width: 60 }}>QUANTITY</th>
                                            <th style={{ width: 140 }}>PRICE</th>
                                            <th style={{ width: 100 }}>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailOrder.products.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <img
                                                        onClick={() => history.push(`/bags/${item.parent_id}/details`)}
                                                        src={item.thumbnail}
                                                        width="100%"
                                                        alt=""
                                                        className="cursor-pointer"
                                                    />
                                                </td>
                                                <td>
                                                    <p
                                                        className="mb-0 cursor-pointer"
                                                        onClick={() => history.push(`/bags/${item.parent_id}/details`)}
                                                    >
                                                        {item.product_name}
                                                    </p>
                                                </td>

                                                <td>{item.quantity}</td>
                                                <td>{currencyFormatter(item.price)}</td>
                                                <td>{currencyFormatter(item.subtotal)}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={5} className="text-right">
                                                <strong>Subtotal</strong>
                                            </td>
                                            <td>{currencyFormatter(detailOrder.total)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} className="text-right border0">
                                                <strong>Shipping</strong>
                                            </td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} className="text-right border0">
                                                <strong>VAT Included in Total</strong>
                                            </td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} className="text-right border0">
                                                <strong>Total</strong>
                                            </td>
                                            <td>
                                                <strong>{currencyFormatter(detailOrder.total)}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mt-80px position-relative pb-5">
                            {related?.length > 0 && (
                                <div className="mb-80px">
                                    <h4 className="size-16 color-dim-gray text-center text-uppercase mt-40px mb-20px">
                                        RELATED PRODUCTS
                                    </h4>
                                    <div className="my-orders_title size-48 color-nero text-center font-title-bold mb-40px">
                                        There’s more that is waiting for you
                                    </div>
                                    {/* <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                        {related.map((item, index) => (
                                            <div className="item" key={index}>
                                                <NavLink to={`/bags/${item.id}/details`}>
                                                    <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                        <img
                                                            src={_.get(item, 'product_image[0]')}
                                                            alt={item.product_name}
                                                        />
                                                    </div>
                                                    <div className="content">
                                                        <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                            {item.product_name}
                                                        </h3>
                                                        <p className="size-14 color-dim-gray">
                                                            {currencyFormatter(item.actual_price)}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        ))}
                                    </Slider> */}

                                    {related.length > 0 && related.length >= 4 ? (
                                        <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                            {related.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <NavLink to={`/bags/${item.id}/details`}>
                                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                            <img
                                                                src={_.get(item, 'product_image[0]')}
                                                                alt={item.product_name}
                                                            />
                                                        </div>
                                                        <div className="content">
                                                            <h3 className="size-18 font-title mt-3 font-weight-normal cursor-pointer">
                                                                {item.product_name}
                                                            </h3>
                                                            <p className="size-14 color-dim-gray">
                                                                {currencyFormatter(item.actual_price)}
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))}
                                        </Slider>
                                    ) : related.length === 3 ? (
                                        window.innerWidth <= 1024 ? (
                                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                                {related.map((item, index) => (
                                                    <div className="item" key={index}>
                                                        <NavLink to={`/bags/${item.id}/details`}>
                                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                <img
                                                                    src={_.get(item, 'product_image[0]')}
                                                                    alt={item.product_name}
                                                                />
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                    {item.product_name}
                                                                </h3>
                                                                <p className="size-14 color-dim-gray">
                                                                    {currencyFormatter(item.actual_price)}
                                                                </p>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                            </Slider>
                                        ) : (
                                            <div className="row bag-slide">
                                                {related.map((item, index) => (
                                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="item">
                                                            <NavLink to={`/bags/${item.id}/details`}>
                                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                    <img
                                                                        src={_.get(item, 'product_image[0]')}
                                                                        alt={item.product_name}
                                                                    />
                                                                </div>
                                                                <div className="content">
                                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                        {item.product_name}
                                                                    </h3>
                                                                    <p className="size-14 color-dim-gray">
                                                                        {currencyFormatter(item.actual_price)}
                                                                    </p>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ) : related.length === 2 ? (
                                        window.innerWidth <= 991 ? (
                                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                                {related.map((item, index) => (
                                                    <div className="item" key={index}>
                                                        <NavLink to={`/bags/${item.id}/details`}>
                                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                <img
                                                                    src={_.get(item, 'product_image[0]')}
                                                                    alt={item.product_name}
                                                                />
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                    {item.product_name}
                                                                </h3>
                                                                <p className="size-14 color-dim-gray">
                                                                    {currencyFormatter(item.actual_price)}
                                                                </p>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                            </Slider>
                                        ) : (
                                            <div className="row bag-slide">
                                                {related.map((item, index) => (
                                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="item">
                                                            <NavLink to={`/bags/${item.id}/details`}>
                                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                    <img
                                                                        src={_.get(item, 'product_image[0]')}
                                                                        alt={item.product_name}
                                                                    />
                                                                </div>
                                                                <div className="content">
                                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                        {item.product_name}
                                                                    </h3>
                                                                    <p className="size-14 color-dim-gray">
                                                                        {currencyFormatter(item.actual_price)}
                                                                    </p>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ) : related.length === 1 ? (
                                        window.innerWidth <= 576 ? (
                                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                                {related.map((item, index) => (
                                                    <div className="item" key={index}>
                                                        <NavLink to={`/bags/${item.id}/details`}>
                                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                <img
                                                                    src={_.get(item, 'product_image[0]')}
                                                                    alt={item.product_name}
                                                                />
                                                            </div>
                                                            <div className="content">
                                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                    {item.product_name}
                                                                </h3>
                                                                <p className="size-14 color-dim-gray">
                                                                    {currencyFormatter(item.actual_price)}
                                                                </p>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                            </Slider>
                                        ) : (
                                            <div className="row bag-slide">
                                                {related.map((item, index) => (
                                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <div className="item">
                                                            <NavLink to={`/bags/${item.id}/details`}>
                                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                                    <img
                                                                        src={_.get(item, 'product_image[0]')}
                                                                        alt={item.product_name}
                                                                    />
                                                                </div>
                                                                <div className="content">
                                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                                        {item.product_name}
                                                                    </h3>
                                                                    <p className="size-14 color-dim-gray">
                                                                        {currencyFormatter(item.actual_price)}
                                                                    </p>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default MyOrderDetail;
