import React from 'react';
import Slider from 'react-slick';
// import { Button } from 'antd';

// import ImgStep1 from '../../assets/img/img1.png'
// import ImgStep2 from '../../assets/img/img2.png'
import prevIcon from '../../assets/img/icon/prev.svg';
import nextIcon from '../../assets/img/icon/next.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BannerStep.scss';

const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
        <p className="mb-0">
            <img src={nextIcon} height="36px" alt="" className="cursor-pointer" />
        </p>
    ),
    prevArrow: (
        <p className="mb-0">
            <img src={prevIcon} height="36px" alt="" className="cursor-pointer" />
        </p>
    ),
};

const MOCK_DATA = [
    {
        id: 1,
        content: 'Choose the bag or accessory style that peaks to you',
        image: 'https://web-images.1atelier.com/images/step-1.jpg',
    },
    {
        id: 2,
        content: 'Make it your own with custom materials, colors and hardware',
        image: 'https://web-images.1atelier.com/images/step-2.jpg',
    },
    {
        id: 3,
        content: "Place your order and we'll get started on your unique creation",
        image: 'https://web-images.1atelier.com/images/step-3.jpg',
    },
];

const BannerStep = ({ isModal }) => {
    // const [currentStep, setCurrentStep] = useState(1);
    return (
        <div>
            {!isModal && (
                <div className="banner-step">
                    {MOCK_DATA.map((item, index) => (
                        <div key={item.id} className="banner-step__item">
                            <div className="banner-step__image">
                                <div style={{ backgroundImage: `url(${item.image})` }} />
                            </div>
                            <p className="banner-step__content">
                                <strong>Step {index + 1}&nbsp;</strong>
                                <span className="color-dim-gray">{item.content}</span>
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {isModal && (
                <div className=" banner-step-wrapper-modal">
                    <Slider {...SLIDER_SETTINGS}>
                        {MOCK_DATA.map((item, index) => (
                            <div key={item.id} className="banner-step__item banner-step__item--modal">
                                <div className="banner-step__image banner-step__image--modal">
                                    <div style={{ backgroundImage: `url(${item.image})` }} />
                                </div>
                                <p className="banner-step__content">
                                    <strong>Step {index + 1}&nbsp;</strong>
                                    <span className="color-dim-gray">{item.content}</span>
                                </p>
                            </div>
                        ))}
                    </Slider>
                </div>
            )}

            {/* {isModal && (
                <>
                    <div className="banner-step-wrapper-modal">
                        <div
                            className="banner-step banner-step--modal"
                            style={{ transform: `translateX(-${(currentStep - 1) * 100}%)` }}
                        >
                            {MOCK_DATA.map((item, index) => (
                                <div key={item.id} className="banner-step__item banner-step__item--modal">
                                    <div className="banner-step__image banner-step__image--modal">
                                        <div style={{ backgroundImage: `url(${item.image})` }} />
                                    </div>
                                    <p className="banner-step__content">
                                        <strong>Step {index + 1}&nbsp;</strong>
                                        <span className="color-dim-gray">{item.content}</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="d-flex justify-content-end banner-step__btn-wrapper">
                        {currentStep > 1 && (
                            <Button
                                className="banner-step__btn"
                                onClick={() => {
                                    setCurrentStep((prev) => prev - 1);
                                }}
                            >
                                Prev
                            </Button>
                        )}
                        {currentStep < 3 && (
                            <Button
                                className="banner-step__btn"
                                onClick={() => {
                                    setCurrentStep((prev) => prev + 1);
                                }}
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </>
            )} */}
        </div>
    );
};

export default BannerStep;
