import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import banner from '../../assets/img/our-story/our-story_banner.png';
import img17 from '../../assets/img/our-story/image_17.png';
import img89 from '../../assets/img/our-story/Rectangle_89.png';
import img93 from '../../assets/img/our-story/Rectangle_93.png';

import './OurStory.scss';

const OurStory = () => {
    useEffect(() => {
        AOS.init({ disable: 'mobile' });
    }, []);

    return (
        <div className="ourstory">
            <div className="mb-110px mt-30px">
                <img className="w-100" src={banner} alt="Our Story" />
            </div>
            <div className="container-fluid" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                <p className="text-uppercase color-dim-gray size-16 text-center mb-0">our story</p>
                {/* <p className="ourstory-subtitle font-title-bold color-nero size-48 text-center mb-60px">
                    About TOI Atelier
                </p> */}
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <div
                            className="os-img-container position-relative"
                            data-aos="fade-up"
                            data-aos-delay="100"
                            data-aos-duration="1500"
                        >
                            <img className="position-absolute os-img" src={img17} alt="" />
                            <img className="position-absolute os-img" src={img89} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-xl-5 offset-xl-1 align-self-xl-center">
                        <div className="os-content" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                            <p className="os-title font-title-section size-38 color-nero">Our Philosophy</p>
                            <div className="os-desc">
                                <p className="size-16 color-dim-gray mb-30px">
                                    Since our founding in 2020, TOI has been crafting luxury leather goods for women
                                    that are both high-quality, exquisite, and affordable.
                                </p>
                                <p className="size-16 color-dim-gray mb-30px">
                                    We’ve set out on a mission to let our customers have their say in how their bag is
                                    made – offering them the opportunity to add the final touch before it is made
                                    on-demand and packaged for delivery.
                                </p>
                                <p className="size-16 color-dim-gray">
                                    At TOI Atelier, we abide by the philosophy that every individual is unique and
                                    should embody their unique style in their own way. Our customized leather bags allow
                                    all women to express their individuality in a way they feel proud, by fusing classic
                                    silhouettes with traditional embroidery to offer you pieces that cannot be found
                                    elsewhere. Durability tests, lasting construction techniques, sustainable production
                                    with minimized waste – these are just some of the things you can expect from us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid white-smoke-bg mt-140px">
                <div className="row">
                    <div className="order-last order-xl-first col-12 col-xl-5 offset-xl-1 align-self-xl-center">
                        <div className="os-content" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                            <p className="os-title font-title-section size-38 color-nero text-capitalize">
                                Our Mission
                            </p>
                            <div className="os-desc">
                                <p className="size-16 color-dim-gray mb-30px">
                                    TOI was brought to life by two Vietnamese women who wanted to produce a line of
                                    luxury goods that proudly represent the diligence of Vietnamese people, pride, and
                                    craftsmanship to the world. Our collection boasts a range of accessories ready to be
                                    worn, and loved – and we hope you treasure them for years to come.
                                </p>
                                {/* <p className="os-desc-tab size-16 color-dim-gray">
                                    Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries, but also the leap into{' '}
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="order-first order-xl-last col-12 col-xl-5">
                        <div
                            className="position-relative d-flex justify-content-center"
                            data-aos="fade-up"
                            data-aos-delay="100"
                            data-aos-duration="1500"
                        >
                            <img className="w-100" src={img93} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurStory;
