import React from 'react';
// import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import prevIcon from '../../assets/img/icon/prev.svg';
import nextIcon from '../../assets/img/icon/next.svg';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './OtherCollection.scss';

import { animationConfig } from '../../helpers';
import COMMON_CONST from '../../constants/common.constant';

const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function NextArrow({ className, onClick }) {
    return (
        <div className={`${className} btn-next-wrapper d-flex align-items-center position-absolute`}>
            <img src={nextIcon} onClick={onClick} className="cursor-pointer" />
        </div>
    );
}

function PrevArrow({ className, onClick }) {
    return (
        <div className={`${className} btn-prev-wrapper d-flex align-items-center position-absolute`}>
            <img src={prevIcon} onClick={onClick} className="cursor-pointer" />
        </div>
    );
}

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const OtherCollection = ({ otherCollections, subtitle = 'Last season collections' }) => {
    // const slideRef = useRef();

    return (
        <div className="oclt">
            <div className="container-fluid">
                <ScrollAnimation
                    {...ANIMATION_CONFIG}
                    className="oclt-title text-uppercase color-dim-gray size-16 text-center"
                >
                    other collections
                </ScrollAnimation>
                <ScrollAnimation
                    {...ANIMATION_CONFIG}
                    className="oclt-subtitle font-title-bold color-nero size-48 text-center"
                >
                    {subtitle}
                </ScrollAnimation>
                <div className="position-relative">
                    {/*{otherCollections.length && (
                        <OwlCarousel 
                            className="owl-theme octl-slide"
                            loop
                            margin={30}
                            ref={slideRef}
                            dots={false}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                992: {
                                    items: 2,
                                },
                                1200: {
                                    items: 3,
                                },
                            }}
                        >
                            {otherCollections.map((item) => (
                                <NavLink to={`/collections/${item.slug}/details`}  className="octl-nav color-nero" key={item.id}>
                                    <div className="octl-slide-item">
                                        <div
                                            className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                            role="button"
                                        >
                                            <img className="octl-slide-item-img" src={item.thumbnail} alt={item.title} />
                                        </div>
                                        <p className="font-title-light size-25 color-nero mb-0 mt-30px" role="button">
                                            {item.title}
                                        </p>
                                    </div>
                                </NavLink>
                            ))}
                        </OwlCarousel>
                    )} 
                    <div className="octl-nav octl-nav--prev position-absolute">
                        <img
                            src={prevIcon}
                            alt="prev"
                            role="button"
                            onClick={() => {
                                slideRef.current.prev();
                            }}
                        />
                    </div>
                    <div className="octl-nav octl-nav--next position-absolute">
                        <img
                            src={nextIcon}
                            alt="next"
                            role="button"
                            onClick={() => {
                                slideRef.current.next();
                            }}
                        />
                    </div>*/}
                    {/* {otherCollections.length && (
                        <Slider {...SLIDER_SETTINGS} autoplay={false} className="octl-slide">
                            {otherCollections.map((item, index) => (
                                <ScrollAnimation {...ANIMATION_CONFIG} key={item.id} delay={index * 200}>
                                    <NavLink to={`/collections/${item.slug}/details`} className="octl-nav color-nero">
                                        <div className="octl-slide-item">
                                            <div
                                                className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                role="button"
                                            >
                                                <img
                                                    className="octl-slide-item-img"
                                                    src={
                                                        item.thumbnail.includes('http')
                                                            ? item.thumbnail
                                                            : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                    }
                                                    alt={item.title}
                                                />
                                            </div>
                                            <p className="font-title-light size-25 color-nero mb-0 mt-30px" role="button">
                                                {item.title}
                                            </p>
                                        </div>
                                    </NavLink>
                                </ScrollAnimation>
                            ))}
                        </Slider>
                    )} */}

                    {otherCollections.length > 0 && otherCollections.length >= 4 ? (
                        <Slider {...SLIDER_SETTINGS} className="bag-slide">
                            {otherCollections.map((item, index) => (
                                <div className="item" key={index}>
                                    <NavLink to={`/collections/${item.slug}/details`} className="octl-nav color-nero">
                                        <div className="octl-slide-item">
                                            <div
                                                className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                role="button"
                                            >
                                                <img
                                                    className="octl-slide-item-img"
                                                    src={
                                                        item.thumbnail.includes('http')
                                                            ? item.thumbnail
                                                            : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                    }
                                                    alt={item.title}
                                                />
                                            </div>
                                            <p
                                                className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                role="button"
                                            >
                                                {item.title}
                                            </p>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </Slider>
                    ) : otherCollections.length === 3 ? (
                        window.innerWidth <= 1024 ? (
                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div className="item" key={index}>
                                        <NavLink
                                            to={`/collections/${item.slug}/details`}
                                            className="octl-nav color-nero"
                                        >
                                            <div className="octl-slide-item">
                                                <div
                                                    className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                    role="button"
                                                >
                                                    <img
                                                        className="octl-slide-item-img"
                                                        src={
                                                            item.thumbnail.includes('http')
                                                                ? item.thumbnail
                                                                : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                        }
                                                        alt={item.title}
                                                    />
                                                </div>
                                                <p
                                                    className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                    role="button"
                                                >
                                                    {item.title}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink
                                                to={`/collections/${item.slug}/details`}
                                                className="octl-nav color-nero"
                                            >
                                                <div className="octl-slide-item">
                                                    <div
                                                        className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                        role="button"
                                                    >
                                                        <img
                                                            className="octl-slide-item-img"
                                                            src={
                                                                item.thumbnail.includes('http')
                                                                    ? item.thumbnail
                                                                    : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                            }
                                                            alt={item.title}
                                                        />
                                                    </div>
                                                    <p
                                                        className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                        role="button"
                                                    >
                                                        {item.title}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : otherCollections.length === 2 ? (
                        window.innerWidth <= 991 ? (
                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div className="item" key={index}>
                                        <NavLink
                                            to={`/collections/${item.slug}/details`}
                                            className="octl-nav color-nero"
                                        >
                                            <div className="octl-slide-item">
                                                <div
                                                    className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                    role="button"
                                                >
                                                    <img
                                                        className="octl-slide-item-img"
                                                        src={
                                                            item.thumbnail.includes('http')
                                                                ? item.thumbnail
                                                                : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                        }
                                                        alt={item.title}
                                                    />
                                                </div>
                                                <p
                                                    className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                    role="button"
                                                >
                                                    {item.title}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink
                                                to={`/collections/${item.slug}/details`}
                                                className="octl-nav color-nero"
                                            >
                                                <div className="octl-slide-item">
                                                    <div
                                                        className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                        role="button"
                                                    >
                                                        <img
                                                            className="octl-slide-item-img"
                                                            src={
                                                                item.thumbnail.includes('http')
                                                                    ? item.thumbnail
                                                                    : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                            }
                                                            alt={item.title}
                                                        />
                                                    </div>
                                                    <p
                                                        className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                        role="button"
                                                    >
                                                        {item.title}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : otherCollections.length === 1 ? (
                        window.innerWidth <= 576 ? (
                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div className="item" key={index}>
                                        <NavLink
                                            to={`/collections/${item.slug}/details`}
                                            className="octl-nav color-nero"
                                        >
                                            <div className="octl-slide-item">
                                                <div
                                                    className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                    role="button"
                                                >
                                                    <img
                                                        className="octl-slide-item-img"
                                                        src={
                                                            item.thumbnail.includes('http')
                                                                ? item.thumbnail
                                                                : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                        }
                                                        alt={item.title}
                                                    />
                                                </div>
                                                <p
                                                    className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                    role="button"
                                                >
                                                    {item.title}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {otherCollections.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink
                                                to={`/collections/${item.slug}/details`}
                                                className="octl-nav color-nero"
                                            >
                                                <div className="octl-slide-item">
                                                    <div
                                                        className="octl-slide-item-bg d-flex align-items-end justify-content-center"
                                                        role="button"
                                                    >
                                                        <img
                                                            className="octl-slide-item-img"
                                                            src={
                                                                item.thumbnail.includes('http')
                                                                    ? item.thumbnail
                                                                    : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                            }
                                                            alt={item.title}
                                                        />
                                                    </div>
                                                    <p
                                                        className="font-title-light size-25 color-nero mb-0 mt-30px"
                                                        role="button"
                                                    >
                                                        {item.title}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OtherCollection;
