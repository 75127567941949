import React, { useEffect, useState, useCallback } from 'react';
import AOS from 'aos';
import { useParams, NavLink, useHistory } from 'react-router-dom';
// import _ from 'lodash';
import Slider from 'react-slick';
import OtherCollection from '../OtherCollection/OtherCollection';

import collectionApi from '../../apis/collectionApi';
import './CollectionDetail.scss';
import PageLoading from '../PageLoading';
import COMMON_CONST from '../../constants/common.constant';
import { currencyFormatter } from '../../helpers';
import { SLIDER_SETTINGS } from '../Bags/BagDetails/BagDetails';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CollectionDetail = () => {
    const history = useHistory();
    const [collection, setCollection] = useState({});
    const [collectionProduct, setCollectionProduct] = useState([]);
    const [relatedCollections, setRelatedCollections] = useState([]);
    const [loading, setLoading] = useState(true);

    const { collectionId: slug } = useParams();
    const [swiped, setSwiped] = useState(false);

    const handleSwiped = useCallback(() => {
        setSwiped(true);
    }, [setSwiped]);

    const handleOnItemClick = useCallback(
        (e) => {
            if (swiped) {
                e.stopPropagation();
                e.preventDefault();
                setSwiped(false);
            }
        },
        [swiped]
    );

    useEffect(() => {
        AOS.init({
            disable: 'mobile',
        });
    }, []);

    const fetchData = async () => {
        setLoading(true);
        let {
            data: {
                data: { collection_detail: collection, related_collections: relatedCollections, collection_product },
            },
        } = await collectionApi.getCollectionDetail(slug);
        setCollection(collection);
        setRelatedCollections(relatedCollections);
        setCollectionProduct(collection_product);
        setLoading(false);
    };

    useEffect(() => {
        if (slug) {
            fetchData();
        }
    }, [slug]);

    if (loading) return <PageLoading />;
    return (
        <div className="cltd pt-200px">
            <div className="container-fluid">
                <div className="news-detail_nav size-14 mb-50px">
                    <span className="color-nero cursor-pointer mr-2" onClick={() => history.push('/')}>
                        HOME
                    </span>
                    -
                    <span className="color-nero cursor-pointer mr-2 ml-2" onClick={() => history.push('/collections')}>
                        COLLECTION
                    </span>
                    - <span className="color-dim-gray ml-2 text-uppercase">{collection.title}</span>
                </div>
            </div>
            <div className="cltd-hero">
                <div className="container-fluid cltd-banner-container">
                    <div className="row">
                        <div className="col-12 col-xl-6 order-last order-xl-first">
                            <div className="cltd-banner-img-container">
                                <div className="cltd-banner-img">
                                    <img
                                        src={
                                            collection.thumbnail.includes('http')
                                                ? collection.thumbnail
                                                : COMMON_CONST.DOMAIN_IMG + collection.thumbnail
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 pb-xl-5 mb-xl-5 align-self-xl-center order-first order-xl-last">
                            <p className="cltd-title size-16 color-dim-gray text-uppercase align-self-center">
                                color of the year
                            </p>
                            <p className="cltd-subtitle font-title-bold size-48 color-nero text-capitalize">
                                {collection.title}
                            </p>
                            <p className="cltd-letter-content color-nero size-18" style={{ textAlign: 'justify' }}>
                                {collection.excerpt}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="cltd-letter-container container-fluid">
                    <div className="cltd-letter">
                        <p className="cltd-letter-content color-nero size-18">{collection.excerpt}</p>
                    </div>
                </div> */}
            </div>
            <div className="container">
                {collection.contents?.length > 1 && (
                    <div className="cltd__contents">
                        <div className="row">
                            {collection.contents &&
                                collection.contents.map((item, index) => {
                                    if (item.content_type === 'text') {
                                        return (
                                            <>
                                                <div
                                                    key={index}
                                                    className="col-12 col-lg-6 cltd__text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.content,
                                                    }}
                                                ></div>
                                            </>
                                        );
                                    }

                                    if (item.content_type === 'image') {
                                        if (item.caption) {
                                            let imageClass;
                                            let captionClass;

                                            switch (item.caption_align) {
                                                case 'top':
                                                    imageClass = 'col-12';
                                                    captionClass = 'col-12';
                                                    break;
                                                case 'bottom':
                                                    imageClass = 'col-xl-12 order-first order-xl-last';
                                                    captionClass = 'col-12';
                                                    break;
                                                case 'left':
                                                    imageClass = 'col-12 col-xl-6 order-first order-xl-last';
                                                    captionClass = 'col-12 col-xl-6 d-flex align-items-center size-16';
                                                    break;
                                                case 'right':
                                                    imageClass = 'col-12 col-xl-6';
                                                    captionClass = 'col-12 col-xl-6 d-flex align-items-center size-16';
                                            }
                                            return (
                                                <div className="col-12" key={index}>
                                                    <div className="row">
                                                        <div className={imageClass}>
                                                            <img
                                                                src={
                                                                    item.content.includes('http')
                                                                        ? item.content
                                                                        : COMMON_CONST.DOMAIN_IMG + item.content
                                                                }
                                                                alt="image"
                                                            />
                                                        </div>
                                                        <div className={captionClass}>{item.caption}</div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="col-12 col-xl-6" key={index}>
                                                    <img
                                                        src={
                                                            item.content.includes('http')
                                                                ? item.content
                                                                : COMMON_CONST.DOMAIN_IMG + item.content
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            );
                                        }
                                    }
                                })}
                        </div>
                    </div>
                )}
            </div>

            <div className="container-fluid">
                {collectionProduct.length > 0 && (
                    <>
                        <h4 className="color-black text-center size-50 font-title-section mt-120px">
                            Products in collection
                        </h4>
                        {/* <h3 className="size-48 font-title-bold text-center mt-30px">
                            There’s more that is waiting for you
                        </h3> */}
                    </>
                )}
                <div className="mt-5 position-relative pb-5">
                    <div className="row bag-slide">
                        {collectionProduct.slice(0, 3).map((item) => (
                            <div key={item.id} className="col-12 col-md-4">
                                <div className="item" onClickCapture={handleOnItemClick}>
                                    <NavLink to={`/bags/${item.id}/details`}>
                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                            <img src={item.thumbnail} alt={item.product_name} />
                                        </div>
                                        <div className="content">
                                            <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                {item.product_name}
                                            </h3>
                                            <p className="size-14 color-dim-gray">
                                                {currencyFormatter(item.original_price)}
                                            </p>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* {collectionProduct.length > 0 && collectionProduct.length >= 4 ? (
                        <Slider onSwipe={handleSwiped} {...SLIDER_SETTINGS} className="bag-slide">
                            {collectionProduct.map((item, index) => (
                                <div className="item" key={index} onClickCapture={handleOnItemClick}>
                                    <NavLink to={`/bags/${item.id}/details`}>
                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                            <img src={item.thumbnail} alt={item.product_name} />
                                        </div>
                                        <div className="content">
                                            <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                {item.product_name}
                                            </h3>
                                            <p className="size-14 color-dim-gray">
                                                {currencyFormatter(item.original_price)}
                                            </p>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </Slider>
                    ) : collectionProduct.length === 3 ? (
                        window.innerWidth <= 1024 ? (
                            <Slider onSwipe={handleSwiped} {...SLIDER_SETTINGS} className="bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div className="item" key={index} onClickCapture={handleOnItemClick}>
                                        <NavLink to={`/bags/${item.id}/details`}>
                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                <img src={item.thumbnail} alt={item.product_name} />
                                            </div>
                                            <div className="content">
                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                    {item.product_name}
                                                </h3>
                                                <p className="size-14 color-dim-gray">
                                                    {currencyFormatter(item.original_price)}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img src={item.thumbnail} alt={item.product_name} width="100%" />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.original_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : collectionProduct.length === 2 ? (
                        window.innerWidth <= 991 ? (
                            <Slider onSwipe={handleSwiped} {...SLIDER_SETTINGS} className="bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div className="item" key={index} onClickCapture={handleOnItemClick}>
                                        <NavLink to={`/bags/${item.id}/details`}>
                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                <img src={item.thumbnail} alt={item.product_name} />
                                            </div>
                                            <div className="content">
                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                    {item.product_name}
                                                </h3>
                                                <p className="size-14 color-dim-gray">
                                                    {currencyFormatter(item.original_price)}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img src={item.thumbnail} alt={item.product_name} width="100%" />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.original_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : collectionProduct.length === 1 ? (
                        window.innerWidth <= 576 ? (
                            <Slider onSwipe={handleSwiped} {...SLIDER_SETTINGS} className="bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div className="item" key={index} onClickCapture={handleOnItemClick}>
                                        <NavLink to={`/bags/${item.id}/details`}>
                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                <img src={item.thumbnail} alt={item.product_name} />
                                            </div>
                                            <div className="content">
                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                    {item.product_name}
                                                </h3>
                                                <p className="size-14 color-dim-gray">
                                                    {currencyFormatter(item.original_price)}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row bag-slide">
                                {collectionProduct.map((item, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="item">
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img src={item.thumbnail} alt={item.product_name} width="100%" />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.original_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    ) : (
                        <div></div>
                    )} */}
                </div>
            </div>

            <div>
                <OtherCollection otherCollections={relatedCollections} subtitle="Related collections" />
            </div>
        </div>
    );
};

export default CollectionDetail;
