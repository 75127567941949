import { callApi } from './axiosClient';

const authApi = {
    countries: () => {
        const url = 'api/shipping/countries';
        return callApi(url);
    },
    cities: (country_id) => {
        const url = `api/shipping/cities/${country_id}`;
        return callApi(url);
    },
    districts: (country_id, city_id) => {
        const url = `api/shipping/districts/${country_id}/${city_id}`;
        return callApi(url);
    },
};

export default authApi;
