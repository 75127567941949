import { handleActions } from 'redux-actions';
import authAction from '../actions/auth.action';
import {
    getToken,
    setToken,
    removeToken,
    getIsLoginBySocial,
    setIsLoginBySocial,
    removeIsLoginBySocial,
} from '../helpers';

const initialState = {
    isFirstLogin: false,
    token: getToken(),
    isLoginBySocial: JSON.parse(getIsLoginBySocial()),
    loading: false,
    user: {},
    errors: null,
};

const authReducer = handleActions(
    {
        [authAction.loginRequest]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [authAction.loginSuccess]: (state, { payload }) => {
            setToken(payload['token']);
            setIsLoginBySocial(payload['isSocial']);
            window.localStorage.setItem('visibleModalStep', '1');
            return {
                ...state,
                ...payload,
                isLoginBySocial: payload.isSocial,
                loading: false,
            };
        },
        [authAction.loginFailure]: (state, { payload }) => {
            return {
                ...state,
                loggedIn: false,
                token: null,
                loading: false,
                errors: payload,
            };
        },
        [authAction.getProfile]: (state, { payload }) => {
            return {
                ...state,
                ...payload,
                loading: false,
            };
        },
        [authAction.logout]: (state) => {
            removeToken();
            removeIsLoginBySocial();
            window.localStorage.setItem('visibleModalStep', '0');
            return {
                ...state,
                token: null,
                loading: false,
                user: {},
                errors: null,
            };
        },
    },
    initialState
);

export default authReducer;
