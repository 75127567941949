import { callApi, callAuthorizationApi } from './axiosClient';

const productApi = {
    list: (params) => {
        const url = `api/products?${new URLSearchParams(params).toString()}`;
        return callApi(url);
    },
    detail: (id) => {
        const url = `api/product-customize/${id}`;
        return callApi(url);
    },
    review: (id) => {
        const url = `api/product/${id}/review`;
        return callApi(url);
    },
    related: (id) => {
        const url = `api/product/${id}/related`;
        return callApi(url);
    },
    favorite: (product_id) => {
        const url = `api/product/${product_id}/favorite`;
        return callAuthorizationApi(url, 'POST', { product_id });
    },
    getCustomize: (product_id) => {
        const url = `/api/product-customize/${product_id}`;
        return callApi(url);
    },
    listCustomize: (params) => {
        const url = `api/products-customizable?${new URLSearchParams(params).toString()}`;
        return callApi(url);
    },
    checkFavorite: (id) => {
        const url = `api/user/check-favorite/${id}`;
        return callAuthorizationApi(url);
    },
    deleteDraftDesign: (id) => {
        const url = `api/draft-design/remove/${id}`;
        return callAuthorizationApi(url, 'GET');
    },
};

export default productApi;
