import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import AOS from 'aos';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { notification, Button } from 'antd';

import contactApi from '../../apis/contactApi';
import contact1 from '../../assets/img/contact/contact-1.png';
import './Contact.scss';
import toastCloseIcon from '../../assets/img/icon/toast-close.svg';

const ContactSchema = Yup.object().shape({
    name: Yup.string().trim().min(2, 'Too Short!').required('Name is required'),
    email: Yup.string().trim().email('Email is not valid').required('Email is required'),
    comment: Yup.string().trim().min(2, 'Too Short!').required('Comment is required'),
});

const Contact = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        AOS.init({
            disable: 'mobile',
        });
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        const dataPostContact = {
            full_name: values.name.trim(),
            email: values.email.trim(),
            comment: values.comment.trim(),
        };
        try {
            const { data } = await contactApi.post(dataPostContact);
            if (data.message === 'Success') {
                notification.open({
                    placement: 'topRight',
                    description: 'Send contact successfully!',
                    className: 'alert-add-to-cart-success text-white size-14',
                    duration: 3,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                });
                resetForm();
            } else {
                notification.open({
                    placement: 'topRight',
                    description: 'Sorry something went wrong.',
                    className: 'alert-add-to-cart-danger text-white size-14',
                });
            }
            setLoading(false);
        } catch (error) {
            notification.open({
                placement: 'topRight',
                description: 'Sorry something went wrong.',
                className: 'alert-add-to-cart-danger text-white size-14',
            });
            setLoading(false);
        }
    };

    return (
        <div className="contact pt-200px overflow-hidden">
            <div className="container">
                <div className="size-16 color-dim-gray text-center mb-30px" data-aos="fade-up" data-aos-duration="1250">
                    CONTACT US
                </div>
                <div
                    className="contact-title size-48 color-nero text-center font-title-bold mb-80px"
                    data-aos="fade-up"
                    data-aos-duration="1250"
                    data-aos-delay="300"
                >
                    Thanks for reaching out!
                </div>
                <div className="row">
                    <div
                        className="col-12 mb-5 mb-md-0 col-md-6"
                        data-aos="fade-right"
                        data-aos-duration="1250"
                        data-aos-delay="300"
                    >
                        <div className="p-3 contact-info">
                            <img src={contact1} alt="contact image" className="w-100 mb-40px" />
                            <div className="size-24 color-nero text-center mb-30px">toiatelier@support.com</div>
                            <div className="size-24 color-nero text-center">+84 903 456 678</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6" data-aos="fade-left" data-aos-duration="1250" data-aos-delay="300">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: '',
                                email: '',
                                comment: '',
                            }}
                            validationSchema={ContactSchema}
                            onSubmit={handleSubmit}
                        >
                            {() => (
                                <Form className="contact-form p-3">
                                    <div className="contact-form-group mb-35px">
                                        <div className="size-16 color-nero mb-12px">NAME</div>
                                        <Field
                                            placeholder="Name"
                                            name="name"
                                            className="size-16 color-nero pb-18px w-100"
                                        />
                                        <ErrorMessage
                                            className="color-contessa text-left mt-2"
                                            name="name"
                                            component="div"
                                        />
                                    </div>
                                    <div className="contact-form-group mb-35px">
                                        <div className="size-16 color-nero mb-12px">EMAIL</div>
                                        <Field
                                            placeholder="Email"
                                            name="email"
                                            className="size-16 color-nero pb-18px w-100"
                                        />
                                        <ErrorMessage
                                            className="color-contessa text-left mt-2"
                                            name="email"
                                            component="div"
                                        />
                                    </div>
                                    <div className="contact-form-group mb-35px">
                                        <div className="size-16 color-nero mb-12px">YOUR COMMENT</div>
                                        <Field
                                            rows={8}
                                            name="comment"
                                            placeholder="Your comment"
                                            className="w-100"
                                            component="textarea"
                                        />
                                        <ErrorMessage
                                            className="color-contessa text-left mt-2"
                                            name="comment"
                                            component="div"
                                        />
                                    </div>
                                    <Button
                                        className="contact-btn text size-14 text color-white text-uppercase"
                                        disabled={loading}
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        SEND COMMENT
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
