import React, { useEffect, useRef, useState } from 'react';
import { useFBX, Html } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
// import path1 from '../../../../../assets/FBX/ccc.FBX';
import * as THREE from 'three';
import { Loader } from '../ModelPreview';
import accessory from '../../../../../assets/textures/accessory_diffuse-min.jpg';
import bgWhite from '../../../../../assets/textures/AVA_Optimize_White_Diffuse.jpg';
import { useSelector } from 'react-redux';

// import imgDemo from '../../../../../assets/textures/Rectangle-533.png';
// import imgDemo1 from '../../../../../assets/pattern/AVA_Optimize_01_Diffuse.jpg';
import Roboto from '../../../../../assets/img/text/Optima_Regular.json';

function degToRad(deg) {
    return deg * (Math.PI / 180);
}

const Guide = () => {
    return (
        <Html center>
            <p className="mb-0 bg-white text-center pt-2 pb-1" style={{ width: 100, borderRadius: 3 }}>
                Rotate in 360&#176;
            </p>
        </Html>
    );
};

const TIME_LOADING_DELAY = 900;

const Model = ({
    model,
    model3d,
    zoom,
    leatherRough,
    text,
    idBag,
    colorText,
    position,
    canvas,
    colorBorder,
    pos_fbx,
    rotation_fbx
}) => {
    const fbx = useFBX(model3d);
    const [showGuide, setShowGuide] = useState(true);
    const [loading, setLoading] = useState(false);
    const meshRef = useRef();

    const texMeshRef = useRef();
    var textMesh = null;
    // parse JSON file with Three
    const font = new THREE.FontLoader().parse(Roboto);
    // configure font geometry
    const textOptions = {
        font,
        size: 0.8,
        height: 0.06, // change thickness of text
        // hover: 30,
        curveSegments: 50,
        // bevelEnabled: true,
        bevelThickness: 10,
        // bevelSize: -1.0,
    };
    var textGeometry = new THREE.TextGeometry(text, textOptions);
    var material = new THREE.MeshPhongMaterial({ color: colorText });
    textMesh = new THREE.Mesh(textGeometry, material);

    textMesh.position.z = Number(position?.z);
    // textMesh.position.z = -6.52;

    textMesh.position.y = Number(position?.y);
    // textMesh.position.y = -8.8;

    textMesh.position.x = Number(position?.x);
    // textMesh.position.x = -5;

    textMesh.rotation.y = 3.18;

    useFrame(() => {
        if (meshRef.current?.rotation.y < 6.36) {
            meshRef.current.rotation.y += 0.04;
            texMeshRef.current.rotation.y += 0.04;
            return;
        }
        setShowGuide(false);
        return;
    });

    useEffect(() => {
        let boundingBox = new THREE.Box3().setFromObject(fbx);
        let center = boundingBox.getCenter();

        fbx.position.x = Number(pos_fbx?.x);
        fbx.position.y = Number(pos_fbx?.y) - center.y/2;
        fbx.position.z = Number(pos_fbx?.z);

        // fbx.rotation.x = degToRad(rotation_fbx?.x);
        // fbx.rotation.y = degToRad(rotation_fbx?.y);
        // fbx.rotation.z = degToRad(rotation_fbx?.z);

        fbx.children.forEach((mesh) => {
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            mesh.clipShadows = true;
            if (mesh.name.includes('Metal') || mesh.name.includes('GOLD')) {
                const textureLoader = new THREE.TextureLoader();
                textureLoader.load(accessory, (texture) => {
                    texture.encoding = THREE.sRGBEncoding;
                    mesh.material = new THREE.MeshPhysicalMaterial({
                        map: texture,
                        roughness: 0.25,
                        metalness: 1,
                    });
                });
            } else if (mesh.name.includes('Duong_chi')) {
                const textureLoader = new THREE.TextureLoader();
                textureLoader.load(bgWhite, (texture) => {
                    texture.encoding = THREE.sRGBEncoding;
                    mesh.material = new THREE.MeshPhysicalMaterial({
                        map: texture,
                        roughness: 0.5,
                        metaness: 0,
                    });
                });
            } else {
                mesh.material = new THREE.MeshPhysicalMaterial({
                    roughness: 0.5,
                    metaness: 0,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (model) {
            setLoading(true);
            const textureLoader = new THREE.TextureLoader();
            textureLoader.crossOrigin = '';
            const newNormalMap = leatherRough ? textureLoader.load(leatherRough) : null;
            let newMap;
            newMap = textureLoader.load(model);
            newMap.encoding = THREE.sRGBEncoding;
            let newMapVien;
            newMapVien = textureLoader.load(accessory);
            newMapVien.encoding = THREE.sRGBEncoding;

            const newMaterial = new THREE.MeshPhysicalMaterial({
                map: newMap,
                bumpMap: newNormalMap,
                // roughnessMap: newNormalMap,
                roughness: leatherRough ? 0.6 : 0.4,
                metalness: 0.1,
            });

            const newMaterialVien = new THREE.MeshPhysicalMaterial({
                map: newMapVien,
                roughness: leatherRough ? 0.5 : 0.4,
                metalness: 0,
                color: colorBorder === undefined ? '#ECBF22' : colorBorder,
            });

            fbx.children.forEach((mesh) => {
                if (
                    !mesh.name.includes('Metal') &&
                    !mesh.name.includes('GOLD') &&
                    !mesh.name.includes('VIEN') &&
                    !mesh.name.includes('Duong_chi')
                ) {
                    mesh.material = newMaterial;
                } else if (mesh.name.includes('VIEN')) {
                    mesh.material = newMaterialVien;
                }
            });
        }
    }, [model, leatherRough]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, TIME_LOADING_DELAY);
    }, [loading]);

    useEffect(() => {
        if (zoom || zoom === 0) {
            fbx.children.forEach((mesh) => {
                mesh.scale.set(1 + zoom, 1 + zoom, 1 + zoom);
            });
        }
    }, [zoom]);


    return loading ? (
        <Loader />
    ) : (
        <>
            <primitive object={fbx} dispose={null} ref={meshRef} />
            <primitive object={textMesh} dispose={null} ref={texMeshRef} />
            {showGuide && <Guide />}
        </>
    );
};

export default React.memo(Model);
