import './App.scss';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import {
    Home,
    MyOrders,
    Profile,
    Bags,
    BagDetails,
    News,
    NewsDetail,
    Collection,
    CollectionDetail,
    OurStory,
    Contact,
    // DesignDetails,
    DesignYourOwn,
    DesignCustomize,
    Cart,
    Checkout,
    ScrollToTop,
    PageNotFound,
    ResetPassword,
} from './components/index';
import { Footer, Header } from './layouts';
import authApi from './apis/authApi';
import cartApi from './apis/cartApi';
import authAction from './actions/auth.action';
import cartAction from './actions/add-to-cart.action';
import LoginPage from './components/Login';
import RegisterPage from './components/Register';
// import favoriteAction from './actions/favorite.action';
import MyFavorites from './components/MyFavorites';
import DraftDesign from './components/DraftDesign';
import MyOrderDetail from './components/MyOrders/MyOrderDetail';

const ELEMENTS_OPTIONS = {};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);

// function to guard the component for private access
const PrivateRoute = (props) => {
    const authToken = useSelector((state) => state.auth.token);
    if (!authToken) {
        return <Redirect to="/" />;
    }
    return <Route {...props} />;
};

function App() {
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        if (authToken) {
            authApi.me().then(({ data: { data } }) => {
                if (data) {
                    dispatch(authAction.getProfile(data));
                    cartApi.getCart().then(({ data: { data } }) => {
                        dispatch(cartAction.list(data));
                    });
                    // authApi.favorite().then(({ data: { data } }) => {
                    //     dispatch(favoriteAction.list(data));
                    // });
                } else {
                    dispatch(authAction.logout());
                }
            });
        }
    }, [authToken]);

    return (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <Router>
                <Header />
                <ScrollToTop />
                <Switch>
                    <Route path="/" component={Home} exact={true} />
                    <Route path="/design-your-own" component={DesignYourOwn} exact={true} />
                    {/* <Route path="/design-your-own/:bagName/details" component={DesignDetails} /> */}
                    <Route path="/design-your-own/:idBag/customize" component={DesignCustomize} />
                    <Route path="/bags" component={Bags} exact={true} />
                    <Route path="/bags/:bagName/details" component={BagDetails} />
                    <PrivateRoute path="/my-orders" component={MyOrders} exact={true} />
                    <PrivateRoute path="/my-order/:id/detail" component={MyOrderDetail} exact={true} />
                    <PrivateRoute path="/my-favorites" component={MyFavorites} exact={true} />
                    <PrivateRoute path="/draft-design" component={DraftDesign} exact={true} />
                    <PrivateRoute path="/profile" component={Profile} />
                    <Route path="/news" component={News} exact={true} />
                    <Route path="/news/:categoryId/:slug" component={NewsDetail} />
                    <Route path="/collections" component={Collection} exact={true} />
                    <Route path="/collections/:collectionId/details" component={CollectionDetail} />
                    <Route path="/our-story" component={OurStory} />
                    <Route path="/contact-us" component={Contact} exact={true} />
                    <Route path="/cart" component={Cart} />
                    <Route path="/reset-password" exact component={ResetPassword} />
                    <Route path="/login" exact component={LoginPage} />
                    <Route path="/signup" exact component={RegisterPage} />
                    <PrivateRoute path="/checkout" component={Checkout} exact={true} />
                    <Route component={PageNotFound} />
                </Switch>
                <Footer />
            </Router>
        </Elements>
    );
}

export default App;
