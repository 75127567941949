import { createAction } from 'redux-actions';
import MODEL_3D_CONST from '../constants/model3d.constant';

const model3dAction = {
    pickTexture: createAction(MODEL_3D_CONST.PICK_TEXTURE, (partName, img) => ({ partName, img })),
    pickImg: createAction(MODEL_3D_CONST.PICK_IMG, (img) => ({ img })),
    setListPartName: createAction(MODEL_3D_CONST.LIST_PART_NAME, (listPartName) => ({ listPartName })),
    setIsPattern: createAction(MODEL_3D_CONST.IS_PATTERN),
    setModel3D: createAction(MODEL_3D_CONST.PICK_PATTERN, (model) => ({ model })),
    pickLeatherType: createAction(MODEL_3D_CONST.PICK_LEATHER_TYPE),
    selectType: createAction(MODEL_3D_CONST.SELECTED_TYPE),
    selectedTypeVersion: createAction(MODEL_3D_CONST.SELECTED_TYPE_VERSION),
    resetModel3D: createAction(MODEL_3D_CONST.RESET_MODEL_3D),
    setIsSmooth: createAction(MODEL_3D_CONST.SET_IS_SMOOTH),
    setColor: createAction(MODEL_3D_CONST.SET_COLOR),
    setTypeLeather: createAction(MODEL_3D_CONST.SET_TYPE_LEATHER),
    setLeatherRough: createAction(MODEL_3D_CONST.SET_LEATHER_ROUGH),
    setListPattern: createAction(MODEL_3D_CONST.SET_LIST_PATTERN),
    setListPatternVersion: createAction(MODEL_3D_CONST.SET_LIST_PATTERN_VERSION),
    setPosition: createAction(MODEL_3D_CONST.SET_POSITION),
    setColorText: createAction(MODEL_3D_CONST.SET_COLOR_TEXT),
    setColorBorder: createAction(MODEL_3D_CONST.SET_COLOR_BORDER),
    setText: createAction(MODEL_3D_CONST.SET_TEXT),
};

export default model3dAction;
