const MODEL_3D_CONST = {
    PICK_TEXTURE: 'PICK_TEXTURE',
    PICK_IMG: 'PICK_IMG',
    LIST_PART_NAME: 'LIST_PART_NAME',
    PICK_PATTERN: 'PICK_PATTERN',
    IS_PATTERN: 'IS_PATTERN',
    NAME_FOR_CHANGE_PATTERN: 'AVA_leather001',
    SET_MODEL_3D: 'SET_MODEL_3D',
    PICK_LEATHER_TYPE: 'PICK_LEATHER_TYPE',
    SELECTED_TYPE: 'SELECTED_TYPE',
    RESET_MODEL_3D: 'RESET_MODEL_3D',
    SET_IS_SMOOTH: 'SET_IS_SMOOTH',
    SET_COLOR: 'SET_COLOR',
    SET_TYPE_LEATHER: 'SET_TYPE_LEATHER',
    SET_LEATHER_ROUGH: 'SET_LEATHER_ROUGH',
    SET_LIST_PATTERN: 'SET_LIST_PATTERN',
    SET_LIST_PATTERN_VERSION: 'SET_LIST_PATTERN_VERSION',
    SET_POSITION: 'SET_POSITION',
    SET_COLOR_TEXT: 'SET_COLOR_TEXT',
    SET_COLOR_BORDER: 'SET_COLOR_BORDER',
    SET_TEXT: 'SET_TEXT',
    SELECTED_TYPE_VERSION: 'SELECTED_TYPE_VERSION',
};

export default MODEL_3D_CONST;
