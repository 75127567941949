import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Carousel, Modal } from 'antd';
import moment from 'moment';
import ScrollAnimation from 'react-animate-on-scroll';
import AOS from 'aos';

import PageLoading from '../PageLoading';
import NewsLetter from '../NewsLetter/NewsLetter';
import { listDes } from './mock-data';
import homeApi from '../../apis/homeApi';
import { animationConfig, currencyFormatter } from '../../helpers';

import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';
import './Home.scss';

import img1 from '../../assets/img/home/Rectangle_30.png';
// import bannerImg from '../../assets/img/home/banner.png';
import vectorImg from '../../assets/img/home/Vector.png';
import calendarImg from '../../assets/img/icon/Calendar.png';
import bagBg from '../../assets/img/home/solid-color-image.jpeg';
import closeIcon from '../../assets/img/icon/Close.svg';
import COMMON_CONST from '../../constants/common.constant';
import BannerStep from '../BannerStep';
import prevIcon from '../../assets/img/icon/prev.svg';
import nextIcon from '../../assets/img/icon/next.svg';

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <img src={nextIcon} style={{ width: `${window.innerWidth < 768 ? '8px' : '18px'}` }} />
        </div>
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <img src={prevIcon} style={{ width: `${window.innerWidth < 768 ? '8px' : '18px'}` }} />
        </div>
    );
};

const settingsAntdCarousel = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
};

const Home = () => {
    const history = useHistory();
    const [bannerList, setBannerList] = useState([]);
    const [newArrivals, setNewArrivals] = useState([]);
    const [designYourOwn, setDesignYourOwn] = useState(null);
    const [ourCommitment, setOurCommitment] = useState({});
    const [testimonials, setTestimonials] = useState([]);
    const [diaries, setDiaries] = useState([]);
    const [latestCollection, setLatestCollection] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [sendNewLetterSuccess, setSendNewLetterSuccess] = useState(false);
    const [width, setWidth] = useState();
    const vidRef = useRef(null);

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <img src={nextIcon} style={{ width: `${width < 768 ? '8px' : '18px'}` }} />
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style }} onClick={onClick}>
                <img src={prevIcon} style={{ width: `${width < 768 ? '8px' : '18px'}` }} />
            </div>
        );
    };

    const settingsAntdCarousel = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const fetchData = async () => {
        try {
            const {
                data: {
                    data: {
                        banner,
                        new_arrivals: newArrivals,
                        design_your_own: designYourOwn,
                        diary,
                        our_commitment: ourCommitment,
                        testimonials,
                        latest_collection: latestCollection,
                    },
                },
            } = await homeApi.get();

            setBannerList(banner.items);
            setDesignYourOwn(designYourOwn);
            setNewArrivals(newArrivals);
            setOurCommitment(ourCommitment);
            setDiaries(diary);
            setTestimonials(testimonials);
            setLatestCollection(latestCollection);
            setLoading(false);
            if (!sessionStorage.getItem('loaded')) {
                setFirstLoad(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!firstLoad) {
            const timer = setTimeout(() => setVisibleModal(true), 5000);
            return () => clearTimeout(timer);
        }
    }, [firstLoad]);

    useEffect(() => {
        fetchData();
        AOS.init({
            disable: 'mobile',
        });
    }, []);

    // const handleAfterChange = () => {
    // vidRef && vidRef.current.play();
    // };

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    if (loading) return <PageLoading />;
    return (
        <>
            <Modal
                visible={visibleModal}
                width={800}
                onCancel={() => {
                    setVisibleModal(false);
                    sessionStorage.setItem('loaded', 'true');
                }}
                centered
                footer={null}
                title=""
                closeIcon={<img alt="close" src={closeIcon} />}
                className="modal-new-letter"
                bodyStyle={{ padding: 0 }}
            >
                <div className="d-flex">
                    <div className="d-none d-lg-block w-50">
                        <img
                            src={img1}
                            alt="image"
                            width="100%"
                            height="100%"
                            style={{ objectFit: 'cover', borderRadius: 3 }}
                        />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            borderRadius: 3,
                            overflow: 'hidden',
                            paddingTop: 10,
                        }}
                    >
                        {sendNewLetterSuccess ? (
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <h5 className="news-letter-subtitle size-48 font-title-bold text-center mb-0">
                                    Thank you for subscribe
                                </h5>
                            </div>
                        ) : (
                            <NewsLetter
                                isModal
                                setSendNewLetterSuccess={() => {
                                    setSendNewLetterSuccess(true);
                                    // setVisibleModal(false);
                                    // sessionStorage.setItem('loaded', 'true');
                                }}
                            />
                        )}
                    </div>
                </div>
            </Modal>
            <div className="home-page">
                <div className="banner-session position-relative">
                    {bannerList?.length > 0 && (
                        <Carousel
                            autoplay
                            autoplaySpeed={5000}
                            speed={1000}
                            // effect="fade"
                            // afterChange={handleAfterChange}
                            draggable
                            swipeToSlide
                        >
                            {bannerList?.map((banner) => {
                                return (
                                    <div className="h-100 position-relative" key={banner.id}>
                                        <div className="cover-wrapper-banner">
                                            {banner.type === 'image' && (
                                                <div
                                                    className="position-absolute w-100 h-100 img"
                                                    style={{
                                                        backgroundImage: `url(${banner.url})`,
                                                        minHeight: `100vh`,
                                                    }}
                                                />
                                            )}
                                            {banner.type === 'video' && (
                                                <video ref={vidRef} className="video" controls={false} autoPlay>
                                                    <source src={banner.url} />
                                                </video>
                                            )}
                                        </div>

                                        <div className="container-fluid row h-100">
                                            <div className="col-6" />
                                            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                                                {/* TODO: Hidden to confirm to customer */}
                                                {/* <ScrollAnimation {...ANIMATION_CONFIG}>
                                                    <h3 className="size-60 banner-title font-title-section">
                                                        {banner.title}
                                                    </h3>
                                                </ScrollAnimation> */}

                                                {/* <div
                                                    className="d-none"
                                                    data-aos="fade-left"
                                                    data-aos-delay="600"
                                                    data-aos-duration="1000"
                                                >
                                                    <Button className="banner-btn">
                                                        <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase">
                                                            discover more
                                                        </div>
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    )}
                </div>
                <div className="self-session mt-3 mt-lg-5 mb-5">
                    <div className="container-fluid">
                        <ScrollAnimation {...ANIMATION_CONFIG}>
                            <div className="self-session-title position-relative d-flex align-items-center">
                                {/* <div className="size-200 color-gainsboro text-center position-absolute font-title-self-session w-100 pt-4 bg">
                                    TOI
                                </div> */}
                                <div className="size-50 color-black font-title-section title text-center line-normal position-relative">
                                    Your Style Essence - Our identity
                                </div>
                            </div>
                        </ScrollAnimation>
                        <div>
                            <ScrollAnimation {...ANIMATION_CONFIG}>
                                <h4 className="size-16 color-dim-gray text-center text-uppercase mt-3 mt-lg-5">
                                    new arrivals
                                </h4>
                            </ScrollAnimation>
                            <ScrollAnimation {...ANIMATION_CONFIG}>
                                <h3 className="size-48 font-title-section text-center mt-30px color-black">
                                    {/* SPICE UP YOUR LIFE <br /> DISCOVER OUR NEW ARRIVALS */}
                                    Spice Up Your Life <br /> Discover Our New Arrivals
                                </h3>
                            </ScrollAnimation>
                            <div className="row trend-list align-items-center mt-40px">
                                {newArrivals &&
                                    newArrivals.map((arrival, index) => (
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={index * 150}
                                            key={arrival.id}
                                            className={`col-6 col-md-6 col-lg-3 trend-item trend-item-${index} mt-4`}
                                        >
                                            <div className="content-wrapper w-100 h-100">
                                                <div className="content w-100 h-100 d-flex justify-content-center align-items-end position-relative">
                                                    <div>
                                                        <img src={arrival.thumbnail} className="w-100" />
                                                    </div>

                                                    <div
                                                        className="trend-detail position-absolute w-100 h-100 text-center cursor-pointer"
                                                        onClick={() => history.push(`/bags/${arrival.id}/details`)}
                                                    >
                                                        {/* <h2 className="size-25 font-title-bold text-center">
                                                            {arrival.product_name}
                                                        </h2> */}
                                                        {/* <p className="size-16 color-dim-gray text-center">
                                                            {currencyFormatter(arrival.price)}
                                                        </p>
                                                        <NavLink to={`/bags/${arrival.id}/details`}>
                                                            <Button>
                                                                <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase">
                                                                    show now
                                                                </div>
                                                            </Button>
                                                        </NavLink> */}
                                                    </div>

                                                    <div className="trend-detail-thumbnail-hover position-absolute w-100 h-100">
                                                        <img src={arrival.thumbnail_hover} className="w-100" />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h2 className="size-16 font-title mt-3 mb-1">
                                                        {arrival.product_name}
                                                    </h2>
                                                    <p className="size-14 font-title color-dim-gray mb-1">
                                                        {currencyFormatter(arrival.price)}
                                                    </p>

                                                    {/* <NavLink to={`/bags/${arrival.id}/details`}>
                                                        <Button className="btn-open-more mt-2 default">
                                                            <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase">
                                                                go started
                                                            </div>
                                                        </Button>
                                                    </NavLink> */}
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ))}
                            </div>
                            {newArrivals && newArrivals.length > 0 && (
                                <ScrollAnimation
                                    {...ANIMATION_CONFIG}
                                    delay={newArrivals.length + 2 * 300}
                                    className="text-center"
                                >
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="btn-open-more mt-3 mt-lg-0 mr-auto ml-auto"
                                    >
                                        <NavLink to="/bags" className="size-14 text-white text-uppercase text">
                                            all bags
                                        </NavLink>
                                    </Button>
                                </ScrollAnimation>
                            )}

                            {/* TODO */}
                            <div className="descriptions">
                                <Carousel arrows {...settingsAntdCarousel} dots={false} draggable swipeToSlide>
                                    {listDes.map((des, index) => (
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={index * 300}
                                            key={index}
                                            className="d-flex align-items-center description-item mt-60px"
                                        >
                                            <div className="responsive">
                                                <div>
                                                    <img
                                                        src={des.img}
                                                        style={{ width: '80px', paddingBottom: '15px' }}
                                                    />
                                                </div>
                                                <div className={window.innerWidth > 768 ? 'ml-30px' : ''}>
                                                    <h3
                                                        className={`${
                                                            width < 600 ? 'size-20' : 'size-25'
                                                        } font-title-section color-black`}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {des.title}
                                                    </h3>
                                                    <p
                                                        className={`${
                                                            width < 600 ? 'size-12' : 'size-16'
                                                        } color-dim-gray`}
                                                    >
                                                        {des.des}
                                                    </p>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-120px ">
                    {/* <div className="row best-seller mt-80px ">
                        {MOCK_BEST_SELLER.map((item, index) => {
                            if (!index) {
                                return (
                                    <div className="col-12 col-md-6 pt-2 pt-md-3 pb-md-3 pr-md-0" key={item.id}>
                                        <div className="best-seller__item highlight">
                                            <div
                                                style={{
                                                    backgroundImage: `url(${item.thumbnail})`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div className="col-12 col-md-6 pb-2 pt-md-3 pb-md-3 pl-md-0" key={item.id}>
                                    <div className="best-seller__item">
                                        <div className="best-seller__img">
                                            <img src={item.thumbnail} alt="" />
                                        </div>
                                        <div className="best-seller__box">
                                            <div className="best-seller__box-info">
                                                <h2 className="size-25 font-title-bold text-center">
                                                    {item.product_name}
                                                </h2>
                                                <p className="size-16 color-dim-gray text-center">
                                                    {currencyFormatter(item.price)}
                                                </p>
                                            </div>
                                            <Button
                                                type="ghost"
                                                className="border-dark"
                                                onClick={() => history.push(`/bags/${item.id}/details`)}
                                            >
                                                Show Now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div> */}
                </div>

                <div className="mt-120px">
                    {/* <ScrollAnimation {...ANIMATION_CONFIG}>
                        <h4 className="size-16 color-dim-gray text-center text-uppercase">DESIGN YOUR OWN</h4>
                    </ScrollAnimation> */}
                    <ScrollAnimation {...ANIMATION_CONFIG}>
                        <h3 className="size-48 font-title-section text-center mt-30px">Play With Your Style</h3>
                    </ScrollAnimation>

                    <div className="mt-80px">
                        <BannerStep />
                    </div>

                    <div className="container">
                        <div className="row d-md-flex own-design align-items-start mt-3">
                            {designYourOwn &&
                                designYourOwn.map((item, index) =>
                                    window.innerWidth < 768 ? (
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={index * 150}
                                            key={item.id}
                                            className={`${
                                                window.innerWidth < 768 && 'col-6 col-md-6 col-lg-3'
                                            } text-center ${
                                                window.innerWidth < 768 ? 'mb--25px' : 'mb-40px'
                                            } own-design-item own-design-item-${index}`}
                                        >
                                            <div className="img-wrapper d-flex justify-content-center align-items-end position-relative">
                                                <img src={bagBg} className="w-100" alt="" />
                                                <div
                                                    className="img w-100 h-100 position-absolute"
                                                    onClick={() => history.push(`/bags/${item.id}/details`)}
                                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                                />
                                                <div
                                                    className="img img-hover w-100 h-100 position-absolute"
                                                    onClick={() => history.push(`/bags/${item.id}/details`)}
                                                    style={{ backgroundImage: `url(${item.thumbnail_hover})` }}
                                                />
                                                <div className="bg" />
                                                <div className="info">
                                                    <h3 className="size-16 font-title color-nero text-center">
                                                        {item.product_name}
                                                    </h3>
                                                    <Button
                                                        type="primary"
                                                        // size={window.innerWidth > 768 && 'large'}
                                                        className={`mt-10px ml-auto mr-auto ${
                                                            window.innerWidth < 768
                                                                ? 'btn-hover-change-color-sm'
                                                                : 'btn-hover-change-color'
                                                        }`}
                                                    >
                                                        <NavLink
                                                            // to={`/design-your-own/${item.id}/customize?name=${item.product_name}&price=${item.price}`}
                                                            to={`/bags/${item.id}/details`}
                                                            className={`${
                                                                window.innerWidth < 768 ? 'size-12' : 'size-14'
                                                            } text-uppercase text`}
                                                        >
                                                            GET STARTED
                                                        </NavLink>
                                                    </Button>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ) : index < 3 ? (
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={index * 150}
                                            key={item.id}
                                            className={`${
                                                window.innerWidth < 768 && 'col-6 col-md-6 col-lg-3'
                                            } text-center ${
                                                window.innerWidth < 768 ? 'mb-70px' : 'mb-40px'
                                            } own-design-item own-design-item-${index}`}
                                        >
                                            <div className="img-wrapper d-flex justify-content-center align-items-end position-relative">
                                                <img src={bagBg} className="w-100" alt="" />
                                                <div
                                                    className="img w-100 h-100 position-absolute"
                                                    onClick={() => history.push(`/bags/${item.id}/details`)}
                                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                                />
                                                <div
                                                    className="img img-hover w-100 h-100 position-absolute"
                                                    onClick={() => history.push(`/bags/${item.id}/details`)}
                                                    style={{ backgroundImage: `url(${item.thumbnail_hover})` }}
                                                />
                                                <div className="bg" />
                                                <div className="info">
                                                    <h3 className="size-16 font-title color-nero text-center">
                                                        {item.product_name}
                                                    </h3>
                                                    <Button
                                                        type="primary"
                                                        // size={window.innerWidth > 768 && 'large'}
                                                        className={`mt-10px ml-auto mr-auto ${
                                                            window.innerWidth < 768
                                                                ? 'btn-hover-change-color-sm'
                                                                : 'btn-hover-change-color'
                                                        }`}
                                                    >
                                                        <NavLink
                                                            // to={`/design-your-own/${item.id}/customize?name=${item.product_name}&price=${item.price}`}
                                                            to={`/bags/${item.id}/details`}
                                                            className={`${
                                                                window.innerWidth < 768 ? 'size-12' : 'size-14'
                                                            } text-uppercase text`}
                                                        >
                                                            GET STARTED
                                                        </NavLink>
                                                    </Button>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ) : null
                                )}
                        </div>
                        {designYourOwn && designYourOwn.length > 0 && (
                            <ScrollAnimation
                                {...ANIMATION_CONFIG}
                                delay={designYourOwn.length + 2 * 300}
                                className="text-center"
                            >
                                <Button type="primary" size="large" className="btn-open-more mt-xl-5 ml-auto mr-auto">
                                    <NavLink to="/design-your-own" className="size-14 text-white text-uppercase text">
                                        all bags
                                    </NavLink>
                                </Button>
                            </ScrollAnimation>
                        )}
                    </div>
                </div>

                <div className="latest-collection-wrapper">
                    <div className="latest-collection">
                        <div className="container-fluid">
                            <div className="d-md-flex row">
                                <ScrollAnimation
                                    {...ANIMATION_CONFIG}
                                    delay={500}
                                    className="col-12 col-md-6 content pl-md-5 pr-md-5"
                                >
                                    <h4 className="text-white size-16 text-uppercase">LATEST COLLECTION</h4>
                                    <h3 className="text-white size-48 font-title-section mt-40px mt-40px">
                                        {latestCollection.title}
                                    </h3>
                                    <p className="text-white size-14 mt-10px">{latestCollection.excerpt}</p>
                                    <Button type="primary" size="large" className="btn-open-more mt-30px">
                                        <NavLink
                                            to={`/collections/${latestCollection.slug}/details`}
                                            className="size-14 text-white text-uppercase text size-14"
                                        >
                                            DISCOVER MORE
                                        </NavLink>
                                    </Button>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    {...ANIMATION_CONFIG}
                                    delay={0}
                                    className="col-12 col-md-6 d-none img-container text-right position-relative d-md-flex align-items-center justify-content-end"
                                >
                                    <img alt="" src={latestCollection.thumbnail} width="100%" className="d-xl-none" />
                                    <div className="h-100 w-100 position-relative d-none d-xl-block">
                                        <div
                                            className="img h-100 w-100 position-absolute"
                                            style={{
                                                backgroundImage: `url(${
                                                    latestCollection.thumbnail.includes('http')
                                                        ? latestCollection.thumbnail
                                                        : COMMON_CONST.DOMAIN_IMG + latestCollection.thumbnail
                                                })`,
                                            }}
                                        />
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-commitment-session">
                    <div className="container-fluid">
                        <ScrollAnimation {...ANIMATION_CONFIG}>
                            <h4 className="size-16 color-dim-gray text-center text-uppercase">OUR COMMITMENT</h4>
                        </ScrollAnimation>
                        {/* <ScrollAnimation {...ANIMATION_CONFIG}>
                            <h3 className="size-48 font-title-bold text-center mt-30px">
                                {ourCommitment.heading && ourCommitment.heading}
                            </h3>
                        </ScrollAnimation> */}
                        <div className="d-md-flex mt-80px row align-items-center">
                            <ScrollAnimation
                                {...ANIMATION_CONFIG}
                                delay={0}
                                className="col-12 col-lg-6 mb-50px mb-md-0 banner p-0"
                            >
                                <img
                                    src={
                                        ourCommitment.image && ourCommitment.image.includes('http')
                                            ? ourCommitment.image
                                            : COMMON_CONST.DOMAIN_IMG + ourCommitment.image
                                    }
                                />
                            </ScrollAnimation>
                            <div className="col-12 col-lg-6 ">
                                {ourCommitment.items &&
                                    ourCommitment.items.map((item, index) => (
                                        <ScrollAnimation
                                            {...ANIMATION_CONFIG}
                                            delay={index * 400}
                                            key={item.label}
                                            className="commitment-item position-relative"
                                        >
                                            <h3 className={`${width < 600 ? 'size-25' : 'size-31'} font-title-bold`}>
                                                {item.label}
                                            </h3>
                                            <p
                                                className={`mt-10px ${
                                                    width < 600 ? 'size-13' : 'size-16'
                                                } color-dim-gray`}
                                            >
                                                {item.value}
                                            </p>
                                        </ScrollAnimation>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="comments">
                        <Carousel arrows {...settingsAntdCarousel} dots={false} draggable swipeToSlide>
                            {testimonials.map((item, index) => (
                                <div key={index} className="comment-item-wrapper">
                                    <div className="comment-item">
                                        <div className="container content-wrapper d-flex justify-content-center position-relative">
                                            <ScrollAnimation
                                                {...ANIMATION_CONFIG}
                                                className="position-absolute sticker"
                                            ></ScrollAnimation>
                                            <div className="content">
                                                <ScrollAnimation
                                                    {...ANIMATION_CONFIG}
                                                    delay={300}
                                                    className={`${width < 600 ? 'size-12' : 'size-18'} content-text`}
                                                >
                                                    <div className="d-flex">
                                                        <img src={vectorImg} className="comment-item__vector" />
                                                        {item.comment}
                                                    </div>
                                                </ScrollAnimation>
                                                <ScrollAnimation
                                                    {...ANIMATION_CONFIG}
                                                    delay={600}
                                                    className="d-flex align-items-center mt-30px"
                                                >
                                                    <div>
                                                        <img
                                                            src={
                                                                item.customer_image.includes('http')
                                                                    ? item.customer_image
                                                                    : COMMON_CONST.DOMAIN_IMG + item.customer_image
                                                            }
                                                            width="80px"
                                                            height="80px"
                                                            className="rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="ml-30px">
                                                        <h4 className="size-16 font-weight-bold">
                                                            {item.customer_name}
                                                        </h4>
                                                        <p className="size-14 mb-0">
                                                            {moment(item.created_at)
                                                                .format('MMM DD, yyyy')
                                                                .toUpperCase()}
                                                        </p>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className="diary-wrapper">
                    <div className="container mt-120px mb-120px">
                        <ScrollAnimation {...ANIMATION_CONFIG}>
                            <h4 className="size-16 color-dim-gray text-center text-uppercase">IN DIARY</h4>
                        </ScrollAnimation>
                        <ScrollAnimation {...ANIMATION_CONFIG}>
                            <h3 className="size-48 font-title-bold text-center mt-30px">Stay Tune With News</h3>
                        </ScrollAnimation>
                        <div className="row mt-80px">
                            {diaries &&
                                diaries.map((diary, index) => (
                                    <ScrollAnimation
                                        {...ANIMATION_CONFIG}
                                        delay={index * 150}
                                        key={diary.id}
                                        className="col-6 col-md-4 d-flex justify-content-center cursor-pointer diary-item-wrapper"
                                    >
                                        <NavLink className="diary-item" to={`/news/${diary.category_id}/${diary.slug}`}>
                                            <div className="diary-item__image">
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${
                                                            diary.thumbnail.includes('http')
                                                                ? diary.thumbnail
                                                                : COMMON_CONST.DOMAIN_IMG + diary.thumbnail
                                                        })`,
                                                    }}
                                                />
                                            </div>
                                            {/* <img src={diary.thumbnail} className="w-100" /> */}
                                            <h3
                                                className={`${
                                                    width < 600 ? 'size-12' : 'size-18'
                                                } font-title-bold mt-3`}
                                            >
                                                {diary.title}
                                            </h3>
                                            <div className="d-flex align-items-start mt-auto">
                                                <img src={calendarImg} />
                                                <div className="size-14 color-dim-gray ml-2">{`${moment(
                                                    diary.created_at
                                                )
                                                    .format('MMM DD, yyyy')
                                                    .toUpperCase()}`}</div>
                                            </div>
                                        </NavLink>
                                    </ScrollAnimation>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
