import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import model3dAction from '../../../actions/model-3d.action';

const TextureListLeather = ({ textures, setSelectProductIdPattern }) => {
    // const [selectedId, setSelectedId] = useState(null);
    const dispatch = useDispatch();
    const [defaultValue, setDefaultValue] = useState({});
    const { selectedTypeVersion } = useSelector((state) => state.model3d);

    useEffect(() => {
        dispatch(
            model3dAction.selectedTypeVersion({
                patternType: { id: '', name: '' },
                patternTypeVersion: { id: textures?.[0]?.id, name: textures?.[0]?.name },
            })
        );
        dispatch(model3dAction.pickImg(textures?.[0]?.cover));
    }, [dispatch, textures]);

    const selectTexture = React.useCallback(
        ({ img, nameLeather, namePatternVersion, idLeather, idPattern, customize_smooth, textColor }) => {
            // setSelectedId(index);
            dispatch(model3dAction.setIsSmooth(customize_smooth === 1));
            dispatch(model3dAction.pickImg(img));
            dispatch(model3dAction.setIsPattern(true));
            // dispatch(
            //     model3dAction.pickLeatherType({
            //         namePattern: namePattern,
            //         type: img,
            //         id: idPattern,
            //         name: nameLeather,
            //     })
            // );
            dispatch(
                model3dAction.selectedTypeVersion({
                    patternType: { id: idLeather, name: nameLeather },
                    patternTypeVersion: { id: idPattern, name: namePatternVersion },
                })
            );
        },
        []
    );

    return (
        <div className="d-flex flex-wrap texture-list">
            {_.map(textures, (texture, index) => {
                if (texture.is_default_customize) return '';
                return (
                    <div
                        key={index}
                        className="texture-item-wrapper cursor-pointer"
                        onClick={() => {
                            selectTexture({
                                img: texture.cover,
                                namePatternVersion: texture.name,
                                nameLeather: texture.customize_parent_name,
                                idLeather: texture.customize_type,
                                idPattern: texture.id,
                                customize_smooth: texture.customize_parent_smooth,
                                textColor: texture.text_color,
                            });
                            setSelectProductIdPattern(texture.id);
                        }}
                    >
                        <div
                            className={`texture-item ${
                                texture.id === selectedTypeVersion.patternTypeVersion.id && 'active_sub'
                            } `}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        texture.display_cover ? texture.display_cover : texture.cover
                                    })`,
                                }}
                            />
                            <div className="desc text-center">{texture.name}</div>
                        </div>{' '}
                        {texture.id === selectedTypeVersion.patternTypeVersion.id && <p>{texture.name}</p>}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(TextureListLeather);
