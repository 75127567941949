import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { MailOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
// import { Anchor } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';

import './BagDetails.scss';
// import { listBagIconic } from '../mock-data';
import ReviewImg from './ReviewImg/ReviewImg';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import reviewImgDialogAction from '../../../actions/review-img-dialog.action';
import { Button, Collapse, InputNumber, notification, Modal, Input, Form } from 'antd';

import minusIcon from '../../../assets/img/icon/minus.png';
import plusIcon from '../../../assets/img/icon/plus.png';
import cartIcon from '../../../assets/img/icon/cart_white.svg';
import customizeIcon from '../../../assets/img/icon/customize.svg';
import collapseIcon from '../../../assets/img/icon/collapse.svg';
import prevIcon from '../../../assets/img/icon/prev.svg';
import nextIcon from '../../../assets/img/icon/next.svg';
// import heartIcon from '../../../assets/img/icon/heart.svg';
// import { ReactComponent as IconHeart } from '../../../assets/img/icon/heart.svg';
// import closeIcon from '../../../assets/img/icon/close_icon.svg';
// import toastCloseIcon from '../../../assets/img/icon/toast-close.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

// import Reviews from './Revews/Reviews';
import addToCartAction from '../../../actions/add-to-cart.action';
import productApi from '../../../apis/productApi';
import { currencyFormatter } from '../../../helpers';
import ShowModal from '../../ModalButton/ShowModal';
import modalAction from '../../../actions/modal.action';
import toastCloseIcon from '../../../assets/img/icon/toast-close.svg';
import favoriteAction from '../../../actions/favorite.action';
import PageLoading from '../../PageLoading';
// import COMMON_CONST from '../../../constants/common.constant';
import cartApi from '../../../apis/cartApi';
import COMMON_CONST from '../../../constants/common.constant';
import model3dAction from '../../../actions/model-3d.action';

export const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function NextArrow({ className, onClick }) {
    return (
        <div className={`${className} btn-next-wrapper right-0 d-flex align-items-center position-absolute`}>
            <img src={nextIcon} onClick={onClick} className="cursor-pointer" />
        </div>
    );
}

function PrevArrow({ className, onClick }) {
    return (
        <div className={`${className} btn-prev-wrapper left-0 d-flex align-items-center position-absolute`}>
            <img src={prevIcon} onClick={onClick} className="cursor-pointer" />
        </div>
    );
}

const settings_slider_img = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};

// const { Link } = Anchor;

const BagDetails = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [selectedId, setSelectedId] = useState(0);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [quality, setQuality] = useState(1);
    const [bag, setBag] = useState(null);
    const bagId = _.get(props, 'match.params.bagName');
    // const [visible, setVisible] = useState(false);
    // const [reviews, setReviews] = useState([]);
    const [related, setRelated] = useState([]);
    const [currentIdBag, setCurrentIdBag] = useState(null);
    // const [loadingFavorite, setLoadingFavorite] = useState(false)
    const [selectProductColor, setSelectProductColor] = useState({});
    const [loading, setLoading] = useState(true);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalNot, setVisibleModalNot] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [fakeSendMail, setFakeSendMail] = useState('');
    const [isFavorite, setIsFavorite] = useState(0);

    const isAuth = useSelector((state) => !!state.auth.token);
    // const { items: listFavorite } = useSelector((state) => state.favoriteReducer);

    const dispatch = useDispatch();

    const openReviewImg = (indexImg) => {
        dispatch(reviewImgDialogAction.openDialog(indexImg));
        // dispatch(reviewImgDialogAction.setDataImg(img));
    };

    // const showReviews = async () => {
    //     let {
    //         data: { data },
    //     } = await productApi.review(bagId);
    //     setVisible(true);
    // };

    // const closeReviews = () => {
    //     setVisible(false);
    // };

    const pushNotifyAddToCart = async () => {
        if (!isAuth) {
            setShowLoginModal(true);
            return false;
        }
        // dispatch(addToCartAction.addCart(currentIdBag, quality));
        const {
            data: { statusCode, errorCode },
        } = await cartApi.addCart({ product_id: currentIdBag, quantity: quality });
        if (errorCode === 400) {
            setVisibleModalNot(true);
            return;
        }
        if (statusCode === 200) {
            setVisibleModal(true);
            // notification.open({
            //     description: 'Added to your cart. Thanks!',
            //     className: 'alert-add-to-cart-success text-white size-14',
            //     style: {
            //         width: 600,
            //     },
            //     duration: 3,
            //     top: 120,
            //     closeIcon: <img src={toastCloseIcon} alt="" />,
            // });

            cartApi.getCart().then(({ data: { data } }) => {
                dispatch(addToCartAction.list(data));
            });
        }
    };

    const fetchData = async () => {
        let { data } = await productApi.detail(bagId);
        if (data.statusCode === 200 || data.statusCode === 201) {
            setBag(data.data);
            if (data.data.colors.length && data.data.colors.filter((ele) => ele.color_name).length) {
                setCurrentIdBag(data.data.colors.filter((ele) => ele.color_name)[0]?.product_id);
                setSelectProductColor(data.data.colors.filter((ele) => ele.color_name)[0]);
                dispatch(
                    reviewImgDialogAction.setDataImg([...data.data.colors.filter((ele) => ele.color_name)[0]?.image])
                );
            } else {
                setCurrentIdBag(Number(bagId));
                dispatch(reviewImgDialogAction.setDataImg([...data.data.product_image]));
            }
            setLoading(false);
        }
    };

    const fetchRelatedData = async () => {
        let {
            data: { data },
        } = await productApi.related(bagId);
        setRelated(data);
    };

    const handleFavorite = async () => {
        if (!isAuth) {
            setShowLoginModal(true);
            return;
        }
        // setLoadingFavorite(true);
        const {
            data: { code },
        } = await productApi.favorite(bagId);
        if (code === 200 || code === 201) {
            dispatch(favoriteAction.updateFavorite({ ...bag, product_id: bagId }));
            setIsFavorite(!isFavorite);
        } else {
            notification.open({
                duration: 3,
                top: 120,
                closeIcon: <img src={toastCloseIcon} alt="" />,
                description: 'error',
                className: 'alert-add-to-cart-danger text-white size-14',
            });
        }
        // setLoadingFavorite(false);
    };

    const onFinishForm = (values) => {
        setFakeSendMail(values.email);
    };

    useEffect(() => {
        if (fakeSendMail) {
            setLoadingForm(true);
            const timer = setTimeout(() => {
                setLoadingForm(false);
                setFakeSendMail('');
                setVisibleModalNot(false);
                form.resetFields();
                notification.open({
                    description: 'Thanks for your information!',
                    className: 'alert-add-to-cart-success text-white size-14',
                    style: {
                        width: 600,
                    },
                    duration: 3,
                    top: 120,
                    closeIcon: <img src={toastCloseIcon} alt="" />,
                });
            }, 400);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [fakeSendMail]);

    useEffect(() => {
        fetchData();
        fetchRelatedData();
    }, [bagId]);

    useEffect(() => {
        if (isAuth) {
            productApi.checkFavorite(bagId).then((res) => {
                setIsFavorite(res.data.status);
            });
        }
    }, [isAuth]);

    if (loading) return <PageLoading />;
    return bag ? (
        <>
            <Modal
                visible={visibleModalNot}
                footer={null}
                onCancel={() => {
                    setVisibleModalNot(false);
                    form.resetFields();
                }}
                title={<h3 className="size-31 font-title-bold text-left text-capitalize mb-0">{bag.product_name}</h3>}
            >
                <Form form={form} onFinish={onFinishForm}>
                    <div className="d-flex mb-3">
                        <p className="mb-0 size-18">{currencyFormatter(bag.actual_price)}</p>
                        <p className="mb-0 size-18 ml-3 text-secondary">Not Available</p>
                    </div>
                    <div className="border-bottom pb-3 mb-3">
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Email is required!' },
                                { type: 'email', message: 'Invalid email!' },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Email me when this product is back in stock"
                                size="large"
                            />
                        </Form.Item>
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center btn-open-more default"
                            onClick={() => {
                                setVisibleModalNot(false);
                                form.resetFields();
                            }}
                        >
                            <span className="color-yuma size-14">Cancel</span>
                        </Button>
                        <Button
                            loading={loadingForm}
                            htmlType="submit"
                            className="d-flex align-items-center justify-content-center ml-3 btn-open-more"
                            // onClick={() => {
                            //     setVisibleModalNot(false);
                            // }}
                        >
                            <span className="text-white size-14">Ok</span>
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Modal
                title={<h3 className="size-31 font-title-bold text-left text-capitalize mb-0">Added to your cart</h3>}
                visible={visibleModal}
                onCancel={() => setVisibleModal(false)}
                footer={null}
                className="modal-add-cart-success"
            >
                <div>
                    <div className="d-flex border-bottom pb-3 mb-3">
                        <div
                            style={{
                                width: 150,
                                height: 150,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${
                                    selectProductColor.image ? selectProductColor.image[0] : bag.product_image[0]
                                })`,
                            }}
                        />
                        <div>
                            <h4 className="mb-0 pl-2">{bag.product_name}</h4>
                            <p className="mb-0 pl-2 ml-auto">{currencyFormatter(bag.actual_price)}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                        <Button
                            className="d-flex align-items-center justify-content-center btn-open-more default"
                            onClick={() => {
                                history.push('/bags');
                            }}
                        >
                            <span className="color-yuma size-14">Continue shopping</span>
                        </Button>
                        <Button
                            className="d-flex align-items-center justify-content-center ml-3 btn-open-more"
                            onClick={() => {
                                history.push('/cart');
                            }}
                        >
                            <span className="text-white size-14">View my cart</span>
                        </Button>
                    </div>
                </div>
            </Modal>
            <div className="page-container bag-details">
                {!isAuth && (
                    <ShowModal
                        initialModalAction={modalAction.logIn()}
                        show={showLoginModal}
                        handleClose={() => setShowLoginModal(false)}
                    />
                )}
                <div className="container-fluid">
                    <div className="mt-50px mb-50px">
                        <div className="text-uppercase size-14 color-nero d-flex flex-wrap">
                            <span className="cursor-pointer" onClick={() => history.push('/')}>
                                HOME
                            </span>
                            <span className="mr-2 ml-2">-</span>
                            <span className=" cursor-pointer" onClick={() => history.push('/bags')}>
                                BAGS
                            </span>
                            <span className="mr-2 ml-2">-</span>
                            <span>{_.get(bag, 'category.name')}</span>
                            <span className="mr-2 ml-2">-</span>
                            <span className="color-dim-gray">{bag.product_name}</span>
                        </div>
                    </div>
                    <div className="d-flex row">
                        <div className="col-md-6 col-lg-5 review-img">
                            {window.innerWidth >= 768 && (
                                <>
                                    {selectProductColor.product_id
                                        ? selectProductColor.image.map((item, index) => (
                                              <div
                                                  key={index}
                                                  id={`review-img-item`}
                                                  className="text-center review-img-item cursor-pointer"
                                                  onClick={() => openReviewImg(index)}
                                              >
                                                  <img src={item} alt="image" />
                                              </div>
                                          ))
                                        : _.map(
                                              _.get(bag, 'product_image'),
                                              (img, index) =>
                                                  img && (
                                                      <div
                                                          key={index}
                                                          id={`review-img-item${index}`}
                                                          className="text-center review-img-item cursor-pointer"
                                                          onClick={() =>
                                                              openReviewImg(bag.colors.length ? index + 1 : index)
                                                          }
                                                      >
                                                          <img src={img} alt={bag.product_name} />
                                                      </div>
                                                  )
                                          )}
                                </>
                            )}
                            {window.innerWidth < 768 && (
                                <Slider {...settings_slider_img}>
                                    {selectProductColor.product_id
                                        ? selectProductColor.image.map((item, index) => (
                                              <div
                                                  key={index}
                                                  id={`review-img-item`}
                                                  className="text-center review-img-item cursor-pointer"
                                                  onClick={() => openReviewImg(index)}
                                              >
                                                  <img src={item} alt="image" />
                                              </div>
                                          ))
                                        : _.map(
                                              _.get(bag, 'product_image'),
                                              (img, index) =>
                                                  img && (
                                                      <div
                                                          key={index}
                                                          id={`review-img-item${index}`}
                                                          className="text-center review-img-item cursor-pointer"
                                                          onClick={() =>
                                                              openReviewImg(bag.colors.length ? index + 1 : index)
                                                          }
                                                      >
                                                          <img src={img} alt={bag.product_name} />
                                                      </div>
                                                  )
                                          )}
                                </Slider>
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-7 d-flex flex-column align-items-center align-items-md-end align-items-lg-start">
                            <div className="sticky-content">
                                <div className="right-col">
                                    <div className="mt-50px mt-md-0">
                                        <div className="d-flex">
                                            <h3 className="w-75 size-31 font-title-bold text-left text-capitalize mb-0">
                                                {bag.product_name}
                                            </h3>
                                            <div className="w-25 pl-3">
                                                <p className="mb-0 cursor-pointer" onClick={handleFavorite}>
                                                    {isAuth && isFavorite ? (
                                                        <HeartFilled style={{ fontSize: 30, color: '#c5b67e' }} />
                                                    ) : (
                                                        <HeartOutlined style={{ fontSize: 30, color: '#c5b67e' }} />
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-left mt-20px d-flex">
                                            <h3 className="size-18 font-weight-bold">
                                                {currencyFormatter(bag.actual_price)}
                                            </h3>
                                            <del className="size-14 ml-3 color-dim-gray">
                                                {bag.original_price && currencyFormatter(bag.original_price)}
                                            </del>
                                        </div>
                                        <p className="size-16 color-dim-gray text-left mt-20px mb-0">
                                            {bag.product_description}
                                        </p>
                                        <div className="d-flex flex-column align-items-start mt-30px choose-style">
                                            <div>
                                                {bag.colors.filter((ele) => ele.color_name).length > 0 && (
                                                    <div className="d-flex align-items-center">
                                                        <div className="size-16 font-weight-bold text-capitalize color-dim-gray label">
                                                            {bag.colors.filter((ele) => ele.color_name) > 1
                                                                ? 'colors'
                                                                : 'color'}
                                                        </div>
                                                        <div className="color-list d-flex flex-wrap">
                                                            {bag.colors
                                                                .filter((ele) => ele.color_name)
                                                                .map((color, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className={`cursor-pointer color-item-wrapper d-flex justify-content-center align-items-center ${
                                                                            index === selectedId && 'active'
                                                                        }`}
                                                                        onClick={() => {
                                                                            setSelectedId(index);
                                                                            setCurrentIdBag(color.product_id);
                                                                            setSelectProductColor(color);
                                                                            dispatch(
                                                                                reviewImgDialogAction.setDataImg([
                                                                                    ...color.image,
                                                                                ])
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="color-item"
                                                                            style={{
                                                                                backgroundColor: `${color.color_code}`,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="d-flex align-items-center mt-50px">
                                                    <div className="size-16 font-weight-bold text-capitalize color-dim-gray label">
                                                        quantity
                                                    </div>
                                                    <div className="color-list d-flex">
                                                        <Button
                                                            icon={<img src={minusIcon} alt="" />}
                                                            className={`btn-control d-flex align-items-center justify-content-center mr-2 ${
                                                                quality <= 1 && 'disabled-half'
                                                            }`}
                                                            onClick={() => quality > 1 && setQuality(quality - 1)}
                                                        />
                                                        <InputNumber
                                                            className="input-quantity size-16 text-center"
                                                            min={1}
                                                            value={quality}
                                                        />
                                                        <Button
                                                            icon={<img src={plusIcon} alt="" />}
                                                            className="btn-control d-flex align-items-center justify-content-center ml-2"
                                                            onClick={() => setQuality(quality + 1)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`d-flex  mt-30px justify-content-start w-100`}>
                                                {bag.is_customizable === 1 && (
                                                    <Button
                                                        onClick={() => {
                                                            Promise.all([dispatch(model3dAction.resetModel3D())]).then(
                                                                () => {
                                                                    window.location.replace(
                                                                        `/design-your-own/${bagId}/customize?name=${bag.product_name}&price=${bag.actual_price}&old=${bag.original_price}`
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        icon={<img src={customizeIcon} alt="" />}
                                                        className="d-flex align-items-center justify-content-center btn-open-more default mt-2 mt-xl-3 ml-0 mr-2"
                                                    >
                                                        <span className="color-yuma size-14 ml-10px">
                                                            CUSTOMIZE THIS
                                                        </span>
                                                    </Button>
                                                )}
                                                <Button
                                                    icon={<img src={cartIcon} alt="" />}
                                                    className="d-flex align-items-center justify-content-center mt-2 mt-xl-3 ml-0 ml-xl-2 mr-2 btn-open-more"
                                                    onClick={pushNotifyAddToCart}
                                                >
                                                    <span className="text-white size-14 ml-10px">ADD TO CART</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details mt-80px">
                                        <Collapse ghost>
                                            <Collapse.Panel
                                                header={
                                                    <div className="d-flex">
                                                        <div className="size-16 font-weight-bold font-weight-bold text-capitalize">
                                                            Product details
                                                        </div>
                                                        <img src={collapseIcon} alt="" className="collapse-icon ml-3" />
                                                    </div>
                                                }
                                                key="1"
                                                showArrow={false}
                                            >
                                                <div
                                                    className="size-16 color-dim-gray mb-20px mt-3 details__content"
                                                    dangerouslySetInnerHTML={{ __html: bag.product_detail }}
                                                />
                                            </Collapse.Panel>
                                        </Collapse>
                                        {/* <div className="d-flex mt-60px">
                                        <div className="size-18 font-weight-bold cursor-pointer" onClick={showReviews}>
                                            REVIEWS
                                        </div>
                                        <img src={collapseIcon} alt="" className="collapse-icon ml-3" />
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ReviewImg />
                    </div>
                </div>
                {bag.collection_related.id ? (
                    <div
                        className="banner-session d-flex align-items-center mt-120px"
                        style={{
                            backgroundImage: `url(${
                                bag.collection_related.thumbnail.includes('http')
                                    ? bag.collection_related.thumbnail
                                    : COMMON_CONST.DOMAIN_IMG + bag.collection_related.thumbnail
                            })`,
                        }}
                    >
                        <div className="container-fluid row">
                            <div className="col-6" />
                            <div className="col-12 col-md-6 h-100">
                                <h3 className="size-48 font-title-bold text-white">{bag.collection_related.title}</h3>
                                <Button
                                    className="banner-btn text-white border-white"
                                    onClick={() => history.push(`/collections/${bag.collection_related.slug}/details`)}
                                >
                                    <div className="size-14 d-flex justify-content-center align-items-center h-100 text-uppercase">
                                        discover more
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className="container-fluid">
                    <h4 className="size-16 color-dim-gray text-center text-uppercase mt-120px">RELATED PRODUCTS</h4>
                    <h3 className="size-48 font-title-bold text-center mt-30px">
                        There’s more that is waiting for you
                    </h3>
                    <div className="mt-80px position-relative pb-5">
                        <Slider {...SLIDER_SETTINGS} className="bag-slide">
                            {related.map((item, index) => (
                                <div className="item" key={index}>
                                    <NavLink to={`/bags/${item.id}/details`}>
                                        <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                            <img src={_.get(item, 'product_image[0]')} alt={item.product_name} />
                                        </div>
                                        <div className="content">
                                            <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                {item.product_name}
                                            </h3>
                                            <p className="size-14 color-dim-gray">
                                                {currencyFormatter(item.actual_price)}
                                            </p>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </Slider>

                        {/* {related.length >= 4 ? (
                            <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                {related.map((item, index) => (
                                    <div className="item" key={index}>
                                        <NavLink to={`/bags/${item.id}/details`}>
                                            <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                <img src={_.get(item, 'product_image[0]')} alt={item.product_name} />
                                            </div>
                                            <div className="content">
                                                <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                    {item.product_name}
                                                </h3>
                                                <p className="size-14 color-dim-gray">
                                                    {currencyFormatter(item.actual_price)}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        ) : related.length === 3 ? (
                            window.innerWidth <= 1024 ? (
                                <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                    {related.map((item, index) => (
                                        <div className="item" key={index}>
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img
                                                        src={_.get(item, 'product_image[0]')}
                                                        alt={item.product_name}
                                                    />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.actual_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <div className="row bag-slide">
                                    {related.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="item">
                                                <NavLink to={`/bags/${item.id}/details`}>
                                                    <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                        <img
                                                            src={_.get(item, 'product_image[0]')}
                                                            alt={item.product_name}
                                                            width="100%"
                                                        />
                                                    </div>
                                                    <div className="content">
                                                        <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                            {item.product_name}
                                                        </h3>
                                                        <p className="size-14 color-dim-gray">
                                                            {currencyFormatter(item.actual_price)}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : related.length === 2 ? (
                            window.innerWidth <= 991 ? (
                                <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                    {related.map((item, index) => (
                                        <div className="item" key={index}>
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img
                                                        src={_.get(item, 'product_image[0]')}
                                                        alt={item.product_name}
                                                    />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.actual_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <div className="row bag-slide">
                                    {related.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="item">
                                                <NavLink to={`/bags/${item.id}/details`}>
                                                    <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                        <img
                                                            src={_.get(item, 'product_image[0]')}
                                                            alt={item.product_name}
                                                            width="100%"
                                                        />
                                                    </div>
                                                    <div className="content">
                                                        <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                            {item.product_name}
                                                        </h3>
                                                        <p className="size-14 color-dim-gray">
                                                            {currencyFormatter(item.actual_price)}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : related.length === 1 ? (
                            window.innerWidth <= 576 ? (
                                <Slider {...SLIDER_SETTINGS} className="bag-slide">
                                    {related.map((item, index) => (
                                        <div className="item" key={index}>
                                            <NavLink to={`/bags/${item.id}/details`}>
                                                <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                    <img
                                                        src={_.get(item, 'product_image[0]')}
                                                        alt={item.product_name}
                                                    />
                                                </div>
                                                <div className="content">
                                                    <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                        {item.product_name}
                                                    </h3>
                                                    <p className="size-14 color-dim-gray">
                                                        {currencyFormatter(item.actual_price)}
                                                    </p>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <div className="row bag-slide">
                                    {related.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="item">
                                                <NavLink to={`/bags/${item.id}/details`}>
                                                    <div className="bag-slide-item d-flex justify-content-center align-items-end">
                                                        <img
                                                            src={_.get(item, 'product_image[0]')}
                                                            alt={item.product_name}
                                                            width="100%"
                                                        />
                                                    </div>
                                                    <div className="content">
                                                        <h3 className="size-18 font-title mt-3 font-weight-normal">
                                                            {item.product_name}
                                                        </h3>
                                                        <p className="size-14 color-dim-gray">
                                                            {currencyFormatter(item.actual_price)}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : (
                            <div></div>
                        )} */}
                    </div>
                </div>
                {/* <Drawer
                placement="left"
                closable={true}
                visible={visible}
                onClose={closeReviews}
                closeIcon={<img src={closeIcon} alt="" />}
                className="side-bar"
                width="50%"
            >
                <Reviews listReViews={reviews} />
            </Drawer> */}
            </div>
        </>
    ) : (
        <p></p>
    );
};

export default BagDetails;
