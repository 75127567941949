import { callApi } from './axiosClient';

const contactApi = {
    post: (data) => {
        const url = '/api/contact';
        return callApi(url, 'POST', data);
    },
};

export default contactApi;
