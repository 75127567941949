import React, { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

const Controls = ({ pos_camera }) => {
    const controls = useRef();
    const { camera } = useThree();

    useEffect(() => {
        camera.position.z = Number(pos_camera?.z);
        camera.position.x = Number(pos_camera?.x);
        camera.position.y = Number(pos_camera?.y);
    }, [pos_camera]);

    return <OrbitControls ref={controls} args={[camera]} enableZoom={true} enablePan={true} />;
};

export default Controls;
