import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import AOS from 'aos';
import moment from 'moment';
import { FacebookProvider, Comments, ShareButton } from 'react-facebook';

import calendarImg from '../../assets/img/icon/Calendar.png';
// import heart from '../../assets/img/icon/heart.svg';
import share from '../../assets/img/icon/share.svg';
import './NewsDetail.scss';

import newsApi from '../../apis/newsApi';
import PageLoading from '../PageLoading';
import COMMON_CONST from '../../constants/common.constant';

// eslint-disable-next-line no-undef
const appId = process.env.REACT_APP_CLIENT_ID_FB;

const generateContent = (content) => ({ __html: content });

const NewsDetail = () => {
    const history = useHistory();
    const { categoryId, slug } = useParams();
    const [newsDetail, setNewsDetail] = useState(null);
    const [relatedNewsList, setRelatedNewsList] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AOS.init({
            disable: 'mobile',
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlIngredients = { categoryId, slug };

                const {
                    data: {
                        data: { news_detail: newsDetail, related_news: relatedNews },
                    },
                } = await newsApi.getDetail(urlIngredients);

                setNewsDetail(newsDetail);
                setRelatedNewsList(relatedNews);
                setLoading(false);
            } catch (error) {
                // Handle UI when there is error
                console.log(error);
            }
        };

        fetchData();
    }, [categoryId, slug]);

    const current_url = window.location.href;

    console.log(newsDetail);

    if (loading) return <PageLoading />;
    return (
        <FacebookProvider appId={appId}>
            <div className="news-detail pt-170px">
                <div className="container-fluid">
                    <div className="news-detail_nav size-14 mb-50px">
                        <span className="color-nero cursor-pointer mr-2" onClick={() => history.push('/')}>
                            HOME
                        </span>
                        -
                        <span className="color-nero cursor-pointer mr-2 ml-2" onClick={() => history.push('/news')}>
                            NEWS
                        </span>
                        - <span className="color-dim-gray ml-2">{newsDetail && newsDetail.title}</span>
                    </div>
                </div>
                <div>
                    <div className="news-detail-header w-50 mx-auto mb-50px">
                        <div className="news-detail-header_title mb-20px color-black size-31 font-title-bold">
                            {newsDetail && newsDetail.title}
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-start">
                                <img src={calendarImg} alt="calendar image" />
                                <div className="size-14 color-dark-gray-1 ml-2">
                                    {newsDetail && (
                                        <>
                                            <span className="pr-3">
                                                {`${moment(newsDetail.created_at)
                                                    .format('MMM DD, yyyy')
                                                    .toUpperCase()}`}
                                            </span>
                                            <span>
                                                {newsDetail.contents
                                                    .filter((item) => item.content_type === 'tag')
                                                    .map((ele, index) => (
                                                        <span key={index}>{ele.content}</span>
                                                    ))}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div>
                                {/* <Like href={window.location.href} colorScheme="dark" /> */}
                                {/* <img src={heart} alt="heart icon" />
                                <span className="size-12 color-dim-gray ml-1 mr-26px">123 Likes</span> */}
                                <ShareButton href={current_url} className="share-fb">
                                    <img src={share} alt="share icon" />
                                </ShareButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="news-detail_intro">
                        <img
                            src={
                                newsDetail && newsDetail.cover.includes('http')
                                    ? newsDetail.cover
                                    : COMMON_CONST.DOMAIN_IMG + newsDetail.cover
                            }
                            alt="intro image"
                            className="cover"
                        />
                    </div>
                    <div className="container" style={{ marginTop: '50px' }}>
                        {newsDetail &&
                            newsDetail.contents.map((content, index) => {
                                switch (content.content_type) {
                                    case 'text':
                                        return (
                                            <div
                                                key={index}
                                                className="content-paragraph-wrapper"
                                                dangerouslySetInnerHTML={generateContent(content.content)}
                                                style={{ textAlign: 'justify' }}
                                            />
                                        );
                                    case 'image':
                                        return (
                                            <div
                                                key={index}
                                                className="content-img-wrapper d-flex flex-column align-items-center"
                                            >
                                                <img
                                                    src={
                                                        content.content.includes('http')
                                                            ? content.content
                                                            : COMMON_CONST.DOMAIN_IMG + content.content
                                                    }
                                                    alt={content.caption}
                                                    className="content-img"
                                                    style={{ minHeight: '400px', maxHeight: '500px', margin: '30px 0' }}
                                                />
                                                <span className="content-caption font-italic">{content.caption}</span>
                                            </div>
                                        );
                                    case 'video':
                                        return (
                                            <div
                                                key={index}
                                                className="content-video-wrapper position-relative mx-auto"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginBottom: '320px',
                                                }}
                                            >
                                                <iframe
                                                    src={
                                                        content.embed_url.includes('http')
                                                            ? content.embed_url
                                                            : COMMON_CONST.DOMAIN_IMG + content.embed_url
                                                    }
                                                    frameBorder="0"
                                                    className="position-absolute"
                                                    width="700"
                                                    height="400"
                                                />
                                            </div>
                                        );
                                    default:
                                        return <div key={index} />;
                                }
                            })}
                    </div>
                    <div className="content-paragraph-wrapper mx-auto">
                        <Comments href={current_url} />
                    </div>
                </div>
                <div className="container">
                    <div className="pt-80px">
                        <div className="size-16 color-dim-gray text-center">RELATED POSTS</div>
                        <div className="bag-in-daily-list row mt-60px">
                            {relatedNewsList &&
                                relatedNewsList.map((item) => (
                                    <NavLink
                                        key={item.id}
                                        className={`bag-in-daily-list_item cursor-pointer mb-5 mb-lg-0 col-12 col-md-4`}
                                        to={`/news/${item.category_id}/${item.slug}`}
                                    >
                                        <img
                                            src={
                                                item.thumbnail.includes('http')
                                                    ? item.thumbnail
                                                    : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                            }
                                            className="w-100"
                                        />
                                        <h3 className="size-18 font-title-bold mt-3">{item.title}</h3>
                                        <div className="d-flex align-items-start mt-10px">
                                            <img src={calendarImg} />
                                            <div className="size-14 color-dim-gray ml-2">
                                                {`${moment(item.created_at).format('MMM DD, yyyy').toUpperCase()}`}
                                            </div>
                                        </div>
                                    </NavLink>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </FacebookProvider>
    );
};

export default NewsDetail;
