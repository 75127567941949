import { createAction } from 'redux-actions';
import FAVORITE_CONST from '../constants/favorite.constant';

const favoriteAction = {
    list: createAction(FAVORITE_CONST.GET_LIST),
    updateFavorite: createAction(FAVORITE_CONST.UPDATE_FAVORITE),
    resetFavorite: createAction(FAVORITE_CONST.RESET),
};

export default favoriteAction;
