import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import AOS from 'aos';
import moment from 'moment';

import PageLoading from '../PageLoading';
import calendarImg from '../../assets/img/icon/Calendar.png';
import 'aos/dist/aos.css';
import './News.scss';

import newsApi from '../../apis/newsApi';
import COMMON_CONST from '../../constants/common.constant';

const News = () => {
    const [params, setParams] = useState({
        page: 1,
        records_per_page: 6,
    });
    const [totalNew, setTotalNew] = useState(0);
    const [newsFeature, setNewsFeature] = useState(null);
    const [newsListItems, setNewsListItems] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleLoadMoreNews = () => {
        const fetchMoreData = async () => {
            const runOutOfPostsStatus = 'run out of posts';
            const runOutOfPostsMessage = 'There is no more news';

            try {
                const {
                    data: {
                        data: { news_list: newsList },
                    },
                } = await newsApi.get(params);

                if (newsList.items.length === 0) {
                    const error = {
                        status: runOutOfPostsStatus,
                        message: runOutOfPostsMessage,
                    };

                    throw error;
                }

                setNewsListItems([...newsListItems, ...newsList.items]);
                setParams({ page: params.page++, ...params });
            } catch (error) {
                if (error.status === runOutOfPostsStatus) {
                    // Handle UI when there is no more news fetched
                    console.log(error.message);
                } else {
                    console.log(error);
                }
            }
        };

        fetchMoreData();
    };

    useEffect(() => {
        AOS.init({ disable: 'mobile' });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {
                    data: {
                        data: { news_featured: newsFeature, news_list: newsList },
                    },
                } = await newsApi.get(params);

                setParams({ page: params.page++, ...params });
                setNewsFeature(newsFeature);
                setNewsListItems(newsList.items);
                setTotalNew(newsList.total);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    if (loading) return <PageLoading />;
    return (
        <div className="news overflow-hidden">
            <div className="container">
                <div className="row pt-200px">
                    <div className="col-12 col-md-6">
                        <img
                            className="w-100"
                            src={
                                newsFeature && newsFeature.thumbnail?.includes('http')
                                    ? newsFeature.thumbnail
                                    : COMMON_CONST.DOMAIN_IMG + newsFeature.thumbnail
                            }
                            alt="news 1"
                        />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                        {newsFeature && (
                            <div className="news-intro-wrapper mt-5 mt-md-0 pl-50px pr-50px">
                                <NavLink
                                    className="news-intro_title size-31 mb-24px font-title-bold color-black"
                                    to={`/news/${newsFeature.category_id}/${newsFeature.slug}`}
                                    title={newsFeature.slug}
                                >
                                    {newsFeature.title}
                                </NavLink>
                                <div className="news-intro_text size-16 color_dim_gray">{newsFeature.excerpt}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-80px">
                <div className="news-discover_sub-title color-dim-gray size-16 text-center mb-30px">
                    MORE NEWS FROM US
                </div>
                <div className="news-discover_title font-title-bold mb-80px size-48 color-nero text-center">
                    Discover More News
                </div>
                <div className="row">
                    {newsListItems &&
                        newsListItems.map((item) => {
                            return (
                                <NavLink
                                    to={`/news/${item.category_id}/${item.slug}`}
                                    className="col-12 col-sm-6 col-lg-4 mb-60px cursor-pointer news-discover-item__card"
                                    key={item.id}
                                    title={item.title}
                                >
                                    {/* <img className="w-100 mb-20px" src={item.thumbnail} alt="item image" /> */}
                                    <div className="news-discover-item__img mb-20px">
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    item.thumbnail.includes('http')
                                                        ? item.thumbnail
                                                        : COMMON_CONST.DOMAIN_IMG + item.thumbnail
                                                })`,
                                            }}
                                        />
                                    </div>
                                    <div className="news-discover-item_title size-18 color-nero font-title-bold mb-12px">
                                        {item.title}
                                    </div>
                                    <div className="d-flex align-items-start news-discover-item__date">
                                        <img src={calendarImg} alt="calendar image" />
                                        <div className="size-14 color-dark-gray-1 ml-2">
                                            {`${moment(item.created_at).format('MMM DD, yyyy').toUpperCase()}`}
                                        </div>
                                    </div>
                                </NavLink>
                            );
                        })}
                    {(params.page - 1) * params.records_per_page < totalNew && (
                        <div className="d-flex justify-content-center w-100">
                            <Button className="news-discover-btn" onClick={handleLoadMoreNews}>
                                <div className="text size-14 text color-white text-uppercase">LOAD MORE</div>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default News;
