import axios from 'axios';
import queryString from 'query-string';

// import * as localStorage from 'local-storage';

import { getToken } from '../helpers';

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common.Accept = 'application/json';

axios.interceptors.response.use(
    // (response) => checkStatus(response),
    // (error) => Promise.reject(checkStatus(error.response)),
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const callApi = (
    endpoint,
    method = 'GET',
    body = {},
    params = {},
    headers = { 'Content-Type': 'application/json' }
) => {
    return axios({
        method,
        url: `${endpoint}`,
        headers: { ...headers, Accept: 'application/json' },
        data: body,
        params,
        paramsSerializer: (params) => queryString.stringify(params),
    });
};

export const callAuthorizationApi = (
    endpoint,
    method = 'GET',
    body = {},
    params = {},
    headers = { 'Content-Type': 'application/json' }
) => {
    const token = getToken();
    return callApi(endpoint, method, body, params, {
        ...headers,
        Authorization: `Bearer ${token}`,
    });
};

// Checks if a network request came back fine, and throws an error if not
// function checkStatus(response) {
//     if (response.status >= 200 && response.status < 300) {
//         return response;
//     } else if (response.status == 401) {
//         localStorage.clear();
//         if (window.location.pathname != '/') {
//             window.location = '/';
//         }
//         return response.data;
//     } else if (response.status == 403) {
//         // window.location = '/booth-limit';
//         return response.data;
//     } else if (response.status == 422 || response.status == 400) {
//         return response.data;
//     } else if (response.status == 404) {
//         window.location = '/404-page';
//     } else {
//         window.location = '/error';
//     }
// }
