import { createAction } from 'redux-actions';
import AUTH_CONST from '../constants/auth.constant';

const authAction = {
    loginRequest: createAction(AUTH_CONST.LOGIN_REQUEST),
    loginSuccess: createAction(AUTH_CONST.LOGIN_SUCCESS),
    loginFailure: createAction(AUTH_CONST.LOGIN_FAILURE, (error) => ({ error })),
    getProfile: createAction(AUTH_CONST.GET_PROFILE, (user) => ({ user })),
    logout: createAction(AUTH_CONST.LOGOUT_SUCCESS),
};

export default authAction;
