import { createAction } from 'redux-actions';
import CHECKOUT_STEP_CONST from '../constants/checkout-step.constant';

const checkoutStepAction = {
    addressStep: createAction(CHECKOUT_STEP_CONST.ADDRESS_STEP),
    loginStep: createAction(CHECKOUT_STEP_CONST.LOGIN_STEP),
    paymentStep: createAction(CHECKOUT_STEP_CONST.PAYMENT_STEP),
};

export default checkoutStepAction;
