import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { get } from 'lodash';
import { Parallax } from 'react-parallax';
import './Bags.scss';
import ListBag from './ListBag/ListBagNew';
import { Button } from 'antd';

import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

import productApi from '../../apis/productApi';
import { animationConfig } from '../../helpers';
import './Bags.scss';
import PageLoading from '../PageLoading';
import COMMON_CONST from '../../constants/common.constant';

const ANIMATION_CONFIG = window.innerWidth < 768 ? { initiallyVisible: true, animateOnce: true } : animationConfig;
const DETAULT_RECORDS_PER_PAGE = 7;

const Bags = () => {
    const [products, setProducts] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(async () => {
        let {
            data: { data },
        } = await productApi.list({ records_per_page: DETAULT_RECORDS_PER_PAGE });
        setProducts(data);
        setLoading(false);
    }, []);

    const handleLoadMore = async (index, page, category_id) => {
        setLoadMore(true);
        let {
            data: { data },
        } = await productApi.list({ records_per_page: DETAULT_RECORDS_PER_PAGE, category_id, page });
        const _data = data[0];
        let _products = products;
        _products[index].products.items = [..._products[index].products.items, ..._data.products.items];
        _products[index].products.page = _data.products.page;
        setProducts(_products);
        setLoadMore(false);
    };

    const renderProduct = (item, key) => {
        const category = get(item, 'category');
        const products = get(item, 'products');
        return (
            <div className={key > 0 ? `container-fluid mt-80px` : ''} key={key}>
                {/* <div
                    className="bag-category-img"
                    style={{
                        backgroundImage: `url(${
                            category.cover.includes('http') ? category.cover : COMMON_CONST.DOMAIN_IMG + category.cover
                        })`,
                    }}
                /> */}
                <Parallax
                    bgImage={
                        category.cover.includes('http') ? category.cover : COMMON_CONST.DOMAIN_IMG + category.cover
                    }
                    bgImageAlt="bg"
                    strength={200}
                    className="bag-category-img"
                />
                <div className="container-fluid">
                    <ScrollAnimation {...ANIMATION_CONFIG}>
                        <h4
                            className="size-16 font-title color-dim-gray text-center text-uppercase mt-60px"
                            // data-aos="fade-up"
                            data-aos-duration="1250"
                        >
                            {category.name}
                        </h4>
                    </ScrollAnimation>
                    <ScrollAnimation {...ANIMATION_CONFIG}>
                        <h3
                            className="size-48 font-title-bold text-center mt-30px"
                            // data-aos="fade-up"
                            data-aos-duration="1250"
                            data-aos-delay="300"
                        >
                            Artisan Hands, Your Iconic
                        </h3>
                    </ScrollAnimation>
                    <div className="mt-120px">
                        <div data-aos-duration="1250" data-aos-delay="300">
                            <ListBag
                                listBag={products.items}
                                highlightIndex={key / 2 ? 4 : 6}
                                path="bags"
                                hover="thumbnail"
                            />
                        </div>
                        <ScrollAnimation {...ANIMATION_CONFIG} className="text-center">
                            {products.items.length < products.total && (
                                <Button
                                    type="primary"
                                    size="large"
                                    className="btn-open-more ml-auto mr-auto"
                                    disabled={loadMore}
                                    onClick={() => handleLoadMore(key, products.page + 1, category.id)}
                                >
                                    <div className="size-14 text-white text-uppercase text">more</div>
                                </Button>
                            )}
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) return <PageLoading />;
    return <div className="page-container overflow-hidden">{products.map(renderProduct)}</div>;
};

export default Bags;
