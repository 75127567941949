import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import model3dAction from '../../../actions/model-3d.action';

const TextureListLeather = ({ textures, setSelectProductIdPattern }) => {
    const dispatch = useDispatch();
    const { color } = useSelector((state) => state.model3d);
    const { leatherType, patternType } = useSelector((state) => state.model3d.selectedType);

    const selectTexture = React.useCallback(
        ({ img, customize_smooth, color, listPattern, id_cus_default }) => {
            setSelectProductIdPattern(id_cus_default);
            dispatch(model3dAction.setLeatherRough(customize_smooth ? null : img));
            dispatch(model3dAction.setColor(color));
            dispatch(model3dAction.pickImg(img));
            dispatch(model3dAction.setListPattern(listPattern));
            dispatch(model3dAction.setIsPattern(false));
            dispatch(
                model3dAction.selectType({
                    leatherType: { id: '', name: '' },
                    patternType: { id: '', name: '' },
                })
            );

            // const indexLeatherTypePicked = listLeatherTypePicked.findIndex((item) => item.name === nameLeather);
            // if (indexLeatherTypePicked === -1) {
            //     dispatch(model3dAction.pickLeatherType({ name: nameLeather, type: '', id: '', namePattern: '' }));
            //     dispatch(model3dAction.pickImg(img));
            //     dispatch(
            //         model3dAction.selectType({
            //             leatherType: { id: idLeather, name: nameLeather },
            //             patternType: { name: '', id: '' },
            //         })
            //     );
            // } else if (listLeatherTypePicked[indexLeatherTypePicked].type) {
            //     dispatch(model3dAction.pickImg(listLeatherTypePicked[indexLeatherTypePicked].type));
            //     dispatch(
            //         model3dAction.selectType({
            //             leatherType: { id: idLeather, name: nameLeather },
            //             patternType: {
            //                 id: listLeatherTypePicked[indexLeatherTypePicked].id,
            //                 name: listLeatherTypePicked[indexLeatherTypePicked].namePattern,
            //             },
            //         })
            //     );
            // } else {
            //     dispatch(model3dAction.pickImg(img));
            // }
        },
        [color]
    );

    return (
        <div className="d-flex flex-wrap texture-list">
            {_.map(textures, (texture, index) => (
                <div
                    key={index}
                    className="texture-item-wrapper cursor-pointer"
                    onClick={() => {
                        if (texture.color !== color)
                            selectTexture({
                                img: texture?.[0]?.image,
                                nameLeather: texture?.[0]?.customize_name,
                                idLeather: texture?.[0]?.color,
                                customize_smooth: texture?.[0]?.smooth,
                                color: texture?.[0]?.color,
                                listPattern: texture?.[0]?.item,
                                id_cus_default: texture?.[0]?.id_cus_default,
                            });
                    }}
                >
                    {console.log(texture)}
                    <div className={`texture-item texture-item-leather ${texture?.[0]?.color === color && 'active'}`}>
                        <div
                            style={{
                                backgroundImage: `url('${texture?.[0]?.image}')`,
                            }}
                        />
                        <div className="desc text-center">{texture?.[0]?.customize_name}</div>
                    </div>
                    {texture?.[0]?.color === color && <p>{texture?.[0]?.customize_name}</p>}
                </div>
            ))}
        </div>
    );
};

export default React.memo(TextureListLeather);
