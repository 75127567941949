import { handleActions } from 'redux-actions';
import addToCartAction from '../actions/add-to-cart.action';

const initialState = {
    items: {},
    subtotal: 0,
    listProduct: [],
    quantityTotal: 0,
    loading: false,
    product_related: [],
};

const addToCartReducer = handleActions(
    {
        [addToCartAction.list]: (state, { payload }) => {
            return { ...state, ...payload, product_related: payload.product_related.items };
        },
        [addToCartAction.resetCart]: (state) => {
            return { ...state, ...initialState };
        },
        [addToCartAction.updateQuantity]: (state) => {
            const initQuantityTotal = state.listProduct.reduce((quantity, bag) => {
                quantity += bag.quantity;
                return quantity;
            }, 0);

            return { ...state, quantityTotal: initQuantityTotal };
        },
        [addToCartAction.init]: (state, { payload }) => ({
            ...state,
            listProduct: payload['initData'],
        }),
        [addToCartAction.addToCart]: (state, { payload }) => {
            const list = [...state['listProduct']];
            const item = list.find((i) => i.bagId === payload.bagId);

            if (item) {
                item.quantity += payload.quantity;
            } else {
                list.push(payload);
            }

            return {
                ...state,
                listProduct: [...list],
            };
        },
        [addToCartAction.removeCartItem]: (state, { payload }) => ({
            ...state,
            listProduct: [...state['listProduct'].filter((i) => i.bagId !== payload.bagId)],
        }),
    },
    initialState
);

export default addToCartReducer;
