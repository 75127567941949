import { callApi, callAuthorizationApi } from './axiosClient';

const authApi = {
    register: (data) => {
        const url = 'api/user/register';
        return callApi(url, 'POST', data);
    },

    login: (data) => {
        const url = 'api/user/login';
        return callApi(url, 'POST', data);
    },

    forgotPassword: (data) => {
        const url = 'api/user/forgot-password';
        return callApi(url, 'POST', data);
    },

    me: () => {
        const url = 'api/user/me';
        return callAuthorizationApi(url);
    },

    logout: () => {
        const url = 'api/user/logout';
        return callAuthorizationApi(url);
    },

    loginFb: (data) => {
        const url = 'api/user/login/fb';
        return callApi(url, 'POST', data);
    },
    loginGg: (data) => {
        const url = 'api/user/login/gg';
        return callApi(url, 'POST', data);
    },
    updateInfo: (data) => {
        const url = 'api/user/me';
        return callAuthorizationApi(url, 'POST', data);
    },
    resetPassword: (data) => {
        const url = 'api/user/reset-password';
        return callApi(url, 'POST', data);
    },
    favorite: (params = {}) => {
        const url = 'api/user/favorites';
        return callAuthorizationApi(url, 'GET', {}, params);
    },
    draftDesign: (params = {}) => {
        const url = `api/draft-design`;
        return callAuthorizationApi(url, 'GET', {}, params);
    },
};

export default authApi;
